import React from "react";
import ReactDOM from "react-dom";
import "index.css";
import { App } from "App";
import Store from "store/store.js";
import { StoreProvider } from "store/storeUtils.js";
import { create } from "mobx-persist";
import { ApolloProvider } from "@apollo/client";
import Api from "gqlclient/GraphqlClient";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "styles/theme";
import { StylesProvider } from "@material-ui/core/styles";

const hydrate = create({});
let store = new Store();

//TODO: CHANGE STORE NAME!
hydrate("store_floorplanning3d", store)
  .catch((e) => {
    console.log(e);
    delete localStorage["store_floorplanning3d"];
    store = new Store();
    hydrate("store_floorplanning3d", store);
  })
  .finally(() => {
    ReactDOM.render(
      <React.StrictMode>
        <StoreProvider value={store}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <ApolloProvider client={Api(store)}>
              <StylesProvider injectFirst>
                <App />
              </StylesProvider>
            </ApolloProvider>
          </ThemeProvider>
        </StoreProvider>
      </React.StrictMode>,
      document.getElementById("root")
    );
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
