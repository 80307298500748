import React from 'react';

import { ButtonBase } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import 'styles/keyplan.scss';

const Keyplan = () => {
  const matches = useMediaQuery('(min-width:959px)', { noSsr: true });
  const [openKeyplan, setOpenKeyplan] = React.useState(matches);

  return (
    <div className='keyplan position'>
      {openKeyplan ? (
        <div className='keyplan-container'>
          <span className='keyplan-container-close' onClick={() => setOpenKeyplan(false)}>
            <img src={require('assets/images/icons/close-keyplan.svg')} alt='close-keyplan' />
          </span>
          <img
            src={require('assets/images/keyplan.png')}
            alt='keyplan'
            className='keyplan-container-image'
          />
        </div>
      ) : (
        <ButtonBase className='keyplan-button position' onClick={() => setOpenKeyplan(true)}>
          Apri mappa {matches}
        </ButtonBase>
      )}
    </div>
  );
};

export default Keyplan;
