import React from 'react';
import { useFullScreen } from 'react-browser-hooks';
import { Button, CircularProgress } from '@material-ui/core';

import { useStore } from 'store/storeUtils';
import { observer } from 'mobx-react';

import 'styles/starting-screen.scss';

const StartingScreen = observer(() => {
  const fs = useFullScreen();
  const store = useStore();

  const startExperience = () => {
    if (!fs.fullScreen) {
      fs.toggle();
    }
    const startingScreen = document.getElementById('starting-screen-container');
    if (startingScreen) {
      startingScreen.style.display = 'none';
    }
  };

  const retry = () => {
    window.location.reload();
  };

  const backHome = () => {
    if (store.isDesk === 1) {
      window.ws && window.ws.close();
      store.removeVendorInfo();
      window.location.href = '/login';
      return null;
    } else {
      window.location.href = 'https://lambratetwinpalace.com/';
      return null;
    }
  };

  return (
    <>
      <div id='starting-screen-container'>
        <img src={require('assets/images/twin-towers_logo.svg')} alt='logo' />
        <Button variant='contained' id='starting-screen-container-button' onClick={startExperience}>
          Inizia la tua esperienza
        </Button>
      </div>

      <div id='oops-screen-container'>
        <img src={require('assets/images/twin-towers_logo.svg')} alt='logo' />
        <div id='oops-screen-container-content'>
          <img src={require('assets/images/oops.svg')} alt='oops' />
          <p>Siamo spiacenti ma tutti i nostri server attualmente sono occupati.</p>
          <div id='oops-screen-container-content-buttons'>
            <Button variant='contained' onClick={retry}>
              Riprova
            </Button>
            <Button variant='contained' onClick={backHome}>
              Torna alla home
            </Button>
          </div>
        </div>
      </div>

      <div id='waiting-screen-container'>
        <img src={require('assets/images/twin-towers_logo.svg')} alt='logo' />
        <div id='waiting-screen-container-loading'>
          <CircularProgress id='waiting-screen-container-loading-circular' />
          <p id='waiting-screen-container-loading-description'>
            Connessione in corso... <br /> Ti ringraziamo per l&apos;attesa
          </p>
          <p id='waiting-screen-container-loading-reconnection-description'>
            Impossibile stabilire una connessione con il server locale <br /> Connessione in corso
            al server remoto... <br /> Ti ringraziamo per l&apos;attesa
          </p>
        </div>
      </div>
    </>
  );
});

export default StartingScreen;
