import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Countdown from 'react-countdown';

import { informazioniFineCampagna } from 'axios/REST_api';
import { getPromoName } from 'utils/PromoUtils';

import 'styles/promo-banner.scss';

const PromoBanner = ({ onlyTime, getPromoDate, backTo }) => {
  const [infoEndCampaign, setInfoEndCampaign] = React.useState(null);

  const outOfTime = (days_left, threshold) => {
    if (+days_left <= +threshold) return true;
    return false;
  };

  const calculateDateParts = (date) => {
    if (isNaN(date)) date = 0;
    const days = Math.floor(date); // days
    const tempH = (date - days) * 24; // parte decimale (ore) * 24 = ore con virgola
    const hours = Math.floor(tempH); // hours
    const tempM = (tempH - hours) * 60; //  parte decimale (minuti) * 60 = minuti con virgola
    const minutes = Math.floor(tempM); // minutes
    const tempS = (tempM - minutes) * 60; //  parte decimale (secondi) * 60 = secondi con virgola
    const seconds = Math.floor(tempS); // seconds

    return { days, hours, minutes, seconds };
  };

  const calculatePromoEndDate = (days_left) => {
    const { days, hours, minutes, seconds } = calculateDateParts(days_left);
    const endDate = moment()
      .add(days, 'days')
      .add(hours + 1, 'hours') // compensa il ritardo nella ricezione del days_left da BE
      .add(minutes, 'minutes')
      .add(seconds, 'seconds')
      .format('DD/MM/YYYY');

    return endDate;
  };

  const calculateTimerDate = (days_left) => {
    const { days, hours, minutes, seconds } = calculateDateParts(days_left);
    const timerDate = moment()
      .add(days, 'days')
      .add(hours, 'hours')
      .add(minutes, 'minutes')
      .add(seconds, 'seconds')
      .valueOf();

    return timerDate;
  };

  const [isPromoEnded, setIsPromoEnded] = React.useState(false);
  const counterRenderer = ({ days, hours, minutes, completed }) => {
    if (completed) {
      // Render a completed state
      setIsPromoEnded(true);
      return <span>La promo é terminata</span>;
    } else {
      const pad = (n) => String('0' + n).slice(-2);
      return (
        <>
          <span>{pad(days)}</span> gg | <span>{pad(hours)}</span> h | <span>{pad(minutes)}</span> m
        </>
      );
    }
  };

  React.useEffect(() => {
    if (!backTo) {
      informazioniFineCampagna(process.env.REACT_APP_PROJECT)
        .then((res) => {
          if (res.data) {
            const { data } = res.data;
            setInfoEndCampaign(data[0]);
            if (getPromoDate) {
              getPromoDate(calculatePromoEndDate(data[0].last_days));
            }
            console.log(
              '🚀 ~ file: PromoBanner.jsx ~ line 15 ~ informazioniFineCampagna ~ data',
              data,
            );
          }
        })
        .catch((err) => {
          console.log('🚀 ~ file: PromoBanner.jsx ~ line 19 ~ informazioniFineCampagna ~ err', err);
        });
    }
  }, [backTo]);

  const isSeptember = () => {
    const now = moment();
    return now.month() >= 8;
  };

  return (
    <>
      {!isPromoEnded ? (
        <>
          {onlyTime ? (
            <>
              {infoEndCampaign ? (
                <div className='only-timer'>
                  {!outOfTime(infoEndCampaign.last_days, infoEndCampaign.days_left_trigger) ? (
                    <span className='only-timer-date'>
                      La Promo {getPromoName()} é valida fino al{' '}
                      {calculatePromoEndDate(infoEndCampaign.last_days)}
                    </span>
                  ) : (
                    <>
                      <img
                        className='only-timer-icon'
                        src={require('assets/images/icons/clock-black.svg')}
                        alt='clock-black'
                      />
                      <span className='only-timer-countdown'>
                        <Countdown
                          date={calculateTimerDate(infoEndCampaign.last_days)}
                          renderer={counterRenderer}
                        />
                      </span>
                    </>
                  )}
                </div>
              ) : (
                ''
              )}
            </>
          ) : (
            <>
              {infoEndCampaign ? (
                <div className='promo-banner'>
                  <span className='promo-banner-title'>
                    {isSeptember() ? 'Affrettati!' : 'Cogli l’opportunità!'}
                  </span>
                  {/* <span className='promo-banner-description'>La Promo {getPromoName()} sta per terminare</span> */}
                  {infoEndCampaign.type === 'TIME' ? (
                    <>
                      {infoEndCampaign.alert_type === '0' ? (
                        <>
                          {!outOfTime(
                            infoEndCampaign.last_days,
                            infoEndCampaign.days_left_trigger,
                          ) ? (
                            <>
                              <span className='promo-banner-description'>
                                {isSeptember()
                                  ? `La Promo ${getPromoName()} é valida fino al ${calculatePromoEndDate(
                                      infoEndCampaign.last_days,
                                    )}`
                                  : 'Fissa già oggi il tuo appuntamento a partire dal 1 settembre!'}
                              </span>
                            </>
                          ) : (
                            <>
                              {isSeptember() ? (
                                <>
                                  <span className='promo-banner-description'>
                                    La Promo {getPromoName()} sta per terminare
                                  </span>
                                  <span className='promo-banner-timer'>
                                    <img
                                      className='promo-banner-timer-icon'
                                      src={require('assets/images/icons/clock.svg')}
                                      alt='clock'
                                    />
                                    <span className='promo-banner-timer-countdown'>
                                      <Countdown
                                        date={calculateTimerDate(infoEndCampaign.last_days)}
                                        renderer={counterRenderer}
                                      />
                                    </span>
                                  </span>
                                </>
                              ) : (
                                'Fissa già oggi il tuo appuntamento a partire dal 1 settembre!'
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <span className='promo-banner-description'>
                            La Promo {getPromoName()} é valida fino al{' '}
                            {calculatePromoEndDate(infoEndCampaign.last_days)}
                          </span>
                          {+infoEndCampaign.last_apartments >
                          +infoEndCampaign.trigger_apartments_sold ? (
                            <span className='promo-banner-number'>
                              Appartamenti venduti{' '}
                              {`${infoEndCampaign.sold_all_apartments}/${infoEndCampaign.max_apartments_sold}`}
                            </span>
                          ) : (
                            <span className='promo-banner-number'>
                              Mancano solo {infoEndCampaign.last_apartments} appartamenti
                            </span>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <span className='promo-banner-description'>
                        La Promo {getPromoName()} é valida fino al{' '}
                        {calculatePromoEndDate(infoEndCampaign.last_days)}
                      </span>
                      {+infoEndCampaign.last_apartments >
                      +infoEndCampaign.trigger_apartments_sold ? (
                        <span className='promo-banner-number'>
                          Appartamenti venduti{' '}
                          {`${infoEndCampaign.sold_all_apartments}/${infoEndCampaign.max_apartments_sold}`}
                        </span>
                      ) : (
                        <span className='promo-banner-number'>
                          Mancano solo {infoEndCampaign.last_apartments} appartamenti
                        </span>
                      )}
                    </>
                  )}
                </div>
              ) : (
                ''
              )}
            </>
          )}
        </>
      ) : (
        ''
      )}
    </>
  );
};

PromoBanner.propTypes = {
  onlyTime: PropTypes.bool,
  getPromoDate: PropTypes.func,
  backTo: PropTypes.bool,
};

PromoBanner.defaultProps = {
  onlyTime: false,
  backTo: false,
};

export default PromoBanner;
