let webRtcPlayerObj = null;
let print_stats = false;
let print_inputs = false;

let ws;

let qualityControlOwnershipCheckBox;
let matchViewportResolution;

let responseEventListeners = new Map();

let freezeFrameOverlay = null;
let shouldShowPlayOverlay = true;

// A freeze frame is a still JPEG image shown instead of the video.
let freezeFrame = {
  receiving: false,
  size: 0,
  jpeg: undefined,
  height: 0,
  width: 0,
  valid: false,
};

let styleWidth;
let styleHeight;
let styleTop;
let styleLeft;
let styleCursor = "default";
let styleAdditional;

let playerElementClientRect = undefined;
let normalizeAndQuantizeUnsigned = undefined;
let normalizeAndQuantizeSigned = undefined;
let unquantizeAndDenormalizeUnsigned = undefined;

const ControlSchemeType = {
  // A mouse can lock inside the WebRTC player so the user can simply move the
  // mouse to control the orientation of the camera. The user presses the
  // Escape key to unlock the mouse.
  LockedMouse: 0,

  // A mouse can hover over the WebRTC player so the user needs to click and
  // drag to control the orientation of the camera.
  HoveringMouse: 1,
};

const inputOptions = {
  // The control scheme controls the behaviour of the mouse when it interacts
  // with the WebRTC player.
  controlScheme: ControlSchemeType.LockedMouse,

  // Browser keys are those which are typically used by the browser UI. We
  // usually want to suppress these to allow, for example, UE4 to show shader
  // complexity with the F5 key without the web page refreshing.
  suppressBrowserKeys: true,

  // UE4 has a faketouches option which fakes a single finger touch when the
  // user drags with their mouse. We may perform the reverse; a single finger
  // touch may be converted into a mouse drag UE4 side. This allows a
  // non-touch application to be controlled partially via a touch device.
  fakeMouseWithTouches: false,
};

const MessageType = {
  /**********************************************************************/

  /*
   * Control Messages. Range = 0..49.
   */
  IFrameRequest: 0,
  RequestQualityControl: 1,
  MaxFpsRequest: 2,
  AverageBitrateRequest: 3,
  StartStreaming: 4,
  StopStreaming: 5,

  /**********************************************************************/

  /*
   * Input Messages. Range = 50..89.
   */

  // Generic Input Messages. Range = 50..59.
  UIInteraction: 50,
  Command: 51,

  // Keyboard Input Message. Range = 60..69.
  KeyDown: 60,
  KeyUp: 61,
  KeyPress: 62,

  // Mouse Input Messages. Range = 70..79.
  MouseEnter: 70,
  MouseLeave: 71,
  MouseDown: 72,
  MouseUp: 73,
  MouseMove: 74,
  MouseWheel: 75,

  // Touch Input Messages. Range = 80..89.
  TouchStart: 80,
  TouchEnd: 81,
  TouchMove: 82,

  /**************************************************************************/
};

// If the user focuses on a UE4 input widget then we show them a button to open
// the on-screen keyboard. JavaScript security means we can only show the
// on-screen keyboard in response to a user interaction.
var editTextButton = undefined;

// A hidden input text box which is used only for focusing and opening the
// on-screen keyboard.
var hiddenInput = undefined;

export function setWebRtcPlayerObj(value) {
  webRtcPlayerObj = value;
}
export function getWebRtcPlayerObj() {
  return webRtcPlayerObj;
}

export function setPrintStats(value) {
  print_stats = value;
}
export function getPrintStats() {
  return print_stats;
}

export function setPrintInputs(value) {
  print_inputs = value;
}
export function getPrintInputs() {
  return print_inputs;
}

export function setWs(value) {
  ws = value;
}
export function getWs() {
  return ws;
}

export function setQualityControlOwnershipCheckBox(value, property = null) {
  if (property) {
    qualityControlOwnershipCheckBox[property] = value;
  } else {
    qualityControlOwnershipCheckBox = value;
  }
}
export function getQualityControlOwnershipCheckBox() {
  return qualityControlOwnershipCheckBox;
}

export function setMatchViewportResolution(value) {
  matchViewportResolution = value;
}
export function getMatchViewportResolution() {
  return matchViewportResolution;
}

export function setResponseEventListeners(value) {
  responseEventListeners = value;
}
export function getResponseEventListeners() {
  return responseEventListeners;
}

export function setFreezeFrameOverlay(value, property = null) {
  if (property) {
    if (property.includes(".")) {
      const props = property.split(".");
      freezeFrameOverlay[props[0]][props[1]] = value;
    } else {
      freezeFrameOverlay[property] = value;
    }
  } else {
    freezeFrameOverlay = value;
  }
}
export function getFreezeFrameOverlay() {
  return freezeFrameOverlay;
}

export function setShouldShowPlayOverlay(value) {
  shouldShowPlayOverlay = value;
}
export function getShouldShowPlayOverlay() {
  return shouldShowPlayOverlay;
}

export function setFreezeFrame(value, property = null) {
  if (property) {
    freezeFrame[property] = value;
  } else {
    freezeFrame = value;
  }
}
export function getFreezeFrame() {
  return freezeFrame;
}

export function getControlSchemeType() {
  return ControlSchemeType;
}

export function getInputOptions() {
  return inputOptions;
}

export function getMessageType() {
  return MessageType;
}

export function setPlayerElementClientRect(value) {
  playerElementClientRect = value;
}
export function getPlayerElementClientRect() {
  return playerElementClientRect;
}

export function setNormalizeAndQuantizeUnsigned(value) {
  normalizeAndQuantizeUnsigned = value;
}
export function getNormalizeAndQuantizeUnsigned() {
  return normalizeAndQuantizeUnsigned;
}

export function setNormalizeAndQuantizeSigned(value) {
  normalizeAndQuantizeSigned = value;
}
export function getNormalizeAndQuantizeSigned() {
  return normalizeAndQuantizeSigned;
}

export function setUnquantizeAndDenormalizeUnsigned(value) {
  unquantizeAndDenormalizeUnsigned = value;
}

export function getUnquantizeAndDenormalizeUnsigned() {
  return unquantizeAndDenormalizeUnsigned;
}

export function setStyleWidth(value) {
  styleWidth = value;
}
export function getStyleWidth() {
  return styleWidth;
}

export function setStyleHeight(value) {
  styleHeight = value;
}
export function getStyleHeight() {
  return styleHeight;
}

export function setStyleTop(value) {
  styleTop = value;
}
export function getStyleTop() {
  return styleTop;
}

export function setStyleLeft(value) {
  styleLeft = value;
}
export function getStyleLeft() {
  return styleLeft;
}

export function setStyleCursor(value) {
  styleCursor = value;
}
export function getStyleCursor() {
  return styleCursor;
}

export function setStyleAdditional(value) {
  styleAdditional = value;
}
export function getStyleAdditional() {
  return styleAdditional;
}

export function setEditTextButton(value, property = null) {
  if (property) {
    if (property.includes(".")) {
      const props = property.split(".");
      editTextButton[props[0]][props[1]] = value;
    } else {
      editTextButton[property] = value;
    }
  } else {
    editTextButton = value;
  }
}
export function getEditTextButton() {
  return editTextButton;
}

export function setHiddenInput(value, property = null) {
  if (property) {
    if (property.includes(".")) {
      const props = property.split(".");
      hiddenInput[props[0]][props[1]] = value;
    } else {
      hiddenInput[property] = value;
    }
  } else {
    hiddenInput = value;
  }
}
export function getHiddenInput() {
  return hiddenInput;
}
