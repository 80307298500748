import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useStore } from 'store/storeUtils';

import { Button, Dialog, DialogContent, Grid } from '@material-ui/core';

import 'styles/appointment-dialog.scss';

const AppointmentDialog = observer(({ openDialog, setOpenDialog, dialogType, dataToSend }) => {
  const store = useStore();

  const getUrl = () => {
    let url = 'https://lambratetwinpalace.com/salta-la-coda/';
    if (dialogType === 'videocall') url += 'Online';
    if (dialogType === 'showroom') url += 'Showroom';
    return url;
  };

  const redirectTo = () => {
    const form = document.createElement('form');
    document.body.appendChild(form);
    form.target = '_blank';
    form.method = 'post';
    form.action = getUrl();
    for (const name in dataToSend) {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = name;
      input.value = dataToSend[name];
      form.appendChild(input);
    }
    form.submit();
    document.body.removeChild(form);
  };

  return (
    <Dialog
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      style={store.isRotate ? { transform: 'rotate(180deg)' } : {}}
    >
      <DialogContent>
        <Grid container className='dialog-promo'>
          <Grid item xs={6} className={`dialog-image dialog-image-${dialogType}`}>
            <img
              src={require('assets/images/twin-towers_logo_black.svg')}
              alt='logo'
              className='dialog-image-logo'
            />
          </Grid>
          <Grid item xs={6}>
            <div className='dialog-content'>
              <div className='dialog-content-title'>
                {dialogType === 'videocall' ? (
                  <>
                    <span>
                      Il tuo{' '}
                      <span className='dialog-content-title-hightlight'>
                        appuntamento in videocall
                      </span>{' '}
                      in pochi{' '}
                    </span>
                    <span>
                      Semplici passi, anche di{' '}
                      <span className='dialog-content-title-hightlight'>domenica</span>!
                    </span>
                  </>
                ) : dialogType === 'showroom' ? (
                  <>
                    <span>Vivi l’esperienza unica di una </span>
                    <span className='dialog-content-title-hightlight'>
                      Visita nel nostro showroom!
                    </span>
                  </>
                ) : (
                  ''
                )}
              </div>
              <div className='dialog-content-description'>
                {dialogType === 'videocall' ? (
                  <>
                    <div className='dialog-content-description-message'>
                      <img
                        className='dialog-content-description-message-icon'
                        src={require('assets/images/icons/email.svg')}
                        alt='email'
                      />
                      <div className='dialog-content-description-message-me'>
                        <span>
                          Riceverai una email di conferma con tutte le informazioni necessarie per
                          fare un video appuntamento con noi, senza uscire di casa.
                        </span>
                        <span>
                          Con il nostro personale dedicato potrai scoprire tutto su{' '}
                          <span className='highlight'>Lambrate Twin Palace</span>.
                        </span>
                      </div>
                    </div>
                    <div className='dialog-content-description-message'>
                      <img
                        className='dialog-content-description-message-icon'
                        src={require('assets/images/icons/platform-1.svg')}
                        alt='platform'
                      />
                      <div className='dialog-content-description-message-me'>
                        <span>
                          Grazie alla nostra innovativa piattaforma tecnologica avrai accesso a
                          tutti i contenuti.
                        </span>
                        <span>
                          Per scoprire come sarà la{' '}
                          <span className='highlight'>tua nuova casa</span>.
                        </span>
                      </div>
                    </div>
                  </>
                ) : dialogType === 'showroom' ? (
                  <>
                    <div className='dialog-content-description-message'>
                      <img
                        className='dialog-content-description-message-icon'
                        src={require('assets/images/icons/vendor.svg')}
                        alt='vendor'
                      />
                      <div className='dialog-content-description-message-me'>
                        <span>Un appuntamento riservato con il nostro personale dedicato.</span>
                        <span>
                          Per toccare con mano materiali e finiture e scoprire tutto sullo
                          straordinario progetto LAMBRATE TWIN PALACE.
                        </span>
                      </div>
                    </div>
                    <div className='dialog-content-description-message'>
                      <img
                        className='dialog-content-description-message-icon'
                        src={require('assets/images/icons/platform.svg')}
                        alt='platform'
                      />
                      <div className='dialog-content-description-message-me'>
                        <span>
                          Potrai immergerti nel progetto e vivere in antepirma gli spazi della{' '}
                          <span className='highlight'>tua nuova casa</span>.
                        </span>
                        <span>Grazie ad una tecnologia in virtual reality mai vista prima.</span>
                      </div>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
              <div className='dialog-content-footer'>
                <span className='dialog-content-footer-description'>
                  Scegli la data e l&apos;ora che preferisci!
                </span>
                <Button
                  className='dialog-content-footer-button'
                  variant='contained'
                  onClick={redirectTo}
                >
                  Registrati
                </Button>
              </div>
            </div>
          </Grid>
          <img
            src={require('assets/images/icons/close.svg')}
            alt='close'
            className='close-icon'
            onClick={() => setOpenDialog(false)}
          />
        </Grid>
        <Grid container className='dialog-promo-xs'>
          <Grid item xs={6} className='dialog-content'>
            <div className='dialog-content-description'>
              {dialogType === 'videocall' ? (
                <>
                  <div className='dialog-content-description-message'>
                    <img
                      className='dialog-content-description-message-icon'
                      src={require('assets/images/icons/email.svg')}
                      alt='email'
                    />
                    <div className='dialog-content-description-message-me'>
                      <span>
                        Riceverai una email di conferma con tutte le informazioni necessarie per
                        fare un video appuntamento con noi, senza uscire di casa.
                      </span>
                      <span className='dialog-content-description-message-me-2'>
                        Con il nostro personale dedicato potrai scoprire tutto su{' '}
                        <span className='highlight'>Lambrate Twin Palace</span>.
                      </span>
                    </div>
                  </div>
                  <div className='dialog-content-description-message'>
                    <img
                      className='dialog-content-description-message-icon'
                      src={require('assets/images/icons/platform-1.svg')}
                      alt='platform'
                    />
                    <div className='dialog-content-description-message-me'>
                      <span>
                        Grazie alla nostra innovativa piattaforma tecnologica avrai accesso a tutti
                        i contenuti.
                      </span>
                      <span className='dialog-content-description-message-me-2'>
                        Per scoprire come sarà la <span className='highlight'>tua nuova casa</span>.
                      </span>
                    </div>
                  </div>
                </>
              ) : dialogType === 'showroom' ? (
                <>
                  <div className='dialog-content-description-message'>
                    <img
                      className='dialog-content-description-message-icon'
                      src={require('assets/images/icons/vendor.svg')}
                      alt='vendor'
                    />
                    <div className='dialog-content-description-message-me'>
                      <span>Un appuntamento riservato con il nostro personale dedicato.</span>
                      <span className='dialog-content-description-message-me-2'>
                        Per toccare con mano materiali e finiture e scoprire tutto sullo
                        straordinario progetto{' '}
                        <span className='highlight'>lambrate twin palace</span>.
                      </span>
                    </div>
                  </div>
                  <div className='dialog-content-description-message'>
                    <img
                      className='dialog-content-description-message-icon'
                      src={require('assets/images/icons/platform.svg')}
                      alt='platform'
                    />
                    <div className='dialog-content-description-message-me'>
                      <span>
                        Potrai immergerti nel progetto e vivere in antepirma gli spazi della{' '}
                        <span className='highlight'>tua nuova casa</span>.
                      </span>
                      <span>Grazie ad una tecnologia in virtual reality mai vista prima.</span>
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
          </Grid>
          <Grid item xs={6} className='dialog-title'>
            <img
              src={require('assets/images/twin-towers_logo_black.svg')}
              alt='logo'
              className='dialog-title-logo'
            />
            <div className='dialog-title-content'>
              {dialogType === 'videocall' ? (
                <>
                  <span>
                    Il tuo{' '}
                    <span className='dialog-title-content-hightlight'>
                      appuntamento in videocall
                    </span>{' '}
                    in pochi{' '}
                  </span>
                  <span>
                    Semplici passi, anche di{' '}
                    <span className='dialog-title-content-hightlight'>domenica</span>!
                  </span>
                </>
              ) : dialogType === 'showroom' ? (
                <>
                  <span>Vivi l’esperienza unica di una </span>
                  <span className='dialog-title-content-hightlight'>
                    Visita nel nostro showroom!
                  </span>
                </>
              ) : (
                ''
              )}
            </div>
            <div className='dialog-title-footer'>
              <span className='dialog-title-footer-description'>
                Scegli la data e l&apos;ora che preferisci!
              </span>
              <Button
                className='dialog-title-footer-button'
                variant='contained'
                onClick={redirectTo}
              >
                Registrati
              </Button>
            </div>
          </Grid>

          <img
            src={require('assets/images/icons/close.svg')}
            alt='close'
            className='close-icon'
            onClick={() => setOpenDialog(false)}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
});

AppointmentDialog.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  dialogType: PropTypes.string,
  dataToSend: PropTypes.object,
};

AppointmentDialog.defaultProps = {
  openDialog: false,
  dialogType: '',
  dataToSend: {},
};

export default AppointmentDialog;
