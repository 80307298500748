import Home from 'pages/Home';
import Login from 'pages/Login';
//esempio di import con "lazy"
//const ToBeDefined = React.lazy(() => import("pages/TBD"));

export const HomeRoute = {
  id: 'home',
  order: 0,
  exact: true,
  to: '/',
  title: 'Dashboard',
  navbarTitle: 'Dashboard',
  icon: '',
  component: Home,
};

export const LoginRoute = {
  id: 'login',
  order: 1,
  exact: true,
  to: '/login',
  title: 'Login',
  navbarTitle: 'Login',
  icon: '',
  component: Login,
};

const RouteConsts = [HomeRoute, LoginRoute];
export default RouteConsts;
