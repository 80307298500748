import CurrencyFormatter from 'utils/CurrencyFormatter';

const defaultPrice = {
  Bilocale: {
    City: 297000,
  },
  Trilocale: {
    City: 424000,
    Comfort: 512000,
    Space: 571000,
  },
  Quadrilocale: {
    City: 585000,
    Comfort: 673000,
    Space: 795000,
  },
  Pentalocale: {
    Comfort: 799000,
  },
};

const convertPrice = (price, filters, firstFilter = false) => {
  if (firstFilter) {
    const arr = Object.values(defaultPrice[filters.tipologia]);
    const min = Math.min(...arr);
    return price ? CurrencyFormatter(price) : CurrencyFormatter(min);
  }
  return price
    ? CurrencyFormatter(price)
    : CurrencyFormatter(defaultPrice[filters.tipologia][filters.dimensione]);
};

export default convertPrice;
