/* eslint-disable no-unused-vars */
import * as vars from "./Variables";
import {
  resizePlayerStyle,
  showConnectOverlay,
  onOrientationChange,
  invalidateFreezeFrameOverlay,
  setupFreezeFrameOverlay,
} from "./ResizePlayer";
import {
  sendInputData,
  setupWebRtcPlayer,
  onWebRtcAnswer,
  onWebRtcIce,
} from "./PlayerStarter";
import {
  registerHoveringMouseEvents,
  registerLockedMouseEvents,
} from "./MouseEvents";
import { registerKeyboardEvents } from "./KeyboardEvents";

let connect_on_load = false;
let is_reconnection = false;

var qualityControlOwnershipCheckBox;

var t0 = Date.now();
function log(str) {
  console.log(`${Math.floor(Date.now() - t0)}: ` + str);
}

export function setupHtmlEvents() {
  //Window events
  window.addEventListener("resize", resizePlayerStyle, true);
  window.addEventListener("orientationchange", onOrientationChange);

  //HTML elements controls
  let resizeCheckBox = document.getElementById(
    "enlarge-display-to-fill-window-tgl"
  );
  if (resizeCheckBox !== null) {
    resizeCheckBox.onchange = function () {
      resizePlayerStyle();
    };
  }

  qualityControlOwnershipCheckBox = document.getElementById(
    "quality-control-ownership-tgl"
  );
  if (qualityControlOwnershipCheckBox !== null) {
    qualityControlOwnershipCheckBox.onchange = function () {
      requestQualityControl();
    };
  }

  let prioritiseQualityCheckbox = document.getElementById(
    "prioritise-quality-tgl"
  );
  let qualityParamsSubmit = document.getElementById("quality-params-submit");

  if (prioritiseQualityCheckbox !== null) {
    prioritiseQualityCheckbox.onchange = function () {
      if (prioritiseQualityCheckbox.checked) {
        // TODO: This state should be read from the UE Application rather than from the initial values in the HTML
        let lowBitrate = document.getElementById("low-bitrate-text").value;
        let highBitrate = document.getElementById("high-bitrate-text").value;
        let minFPS = document.getElementById("min-fps-text").value;

        let initialDescriptor = {
          PrioritiseQuality: 1,
          LowBitrate: lowBitrate,
          HighBitrate: highBitrate,
          MinFPS: minFPS,
        };
        // TODO: The descriptor should be sent as is to a generic handler on the UE side
        // but for now we're just sending it as separate console commands
        //emitUIInteraction(initialDescriptor);
        sendQualityConsoleCommands(initialDescriptor);
        console.log(initialDescriptor);

        qualityParamsSubmit.onclick = function () {
          let lowBitrate = document.getElementById("low-bitrate-text").value;
          let highBitrate = document.getElementById("high-bitrate-text").value;
          let minFPS = document.getElementById("min-fps-text").value;
          let descriptor = {
            PrioritiseQuality: 1,
            LowBitrate: lowBitrate,
            HighBitrate: highBitrate,
            MinFPS: minFPS,
          };
          //emitUIInteraction(descriptor);
          sendQualityConsoleCommands(descriptor);
          console.log(descriptor);
        };
      } else {
        // Prioritise Quality unchecked
        let initialDescriptor = {
          PrioritiseQuality: 0,
        };
        //emitUIInteraction(initialDescriptor);
        sendQualityConsoleCommands(initialDescriptor);
        console.log(initialDescriptor);

        qualityParamsSubmit.onclick = null;
      }
    };
  }

  let showFPSButton = document.getElementById("show-fps-button");
  if (showFPSButton !== null) {
    showFPSButton.onclick = function () {
      let consoleDescriptor = {
        Console: "stat fps",
      };
      emitUIInteraction(consoleDescriptor);
    };
  }

  let matchViewportResolutionCheckBox = document.getElementById(
    "match-viewport-res-tgl"
  );
  if (matchViewportResolutionCheckBox !== null) {
    matchViewportResolutionCheckBox.onchange = function () {
      vars.setMatchViewportResolution(matchViewportResolutionCheckBox.checked);
    };
  }

  let statsCheckBox = document.getElementById("show-stats-tgl");
  if (statsCheckBox !== null) {
    statsCheckBox.onchange = function (event) {
      let stats = document.getElementById("statsContainer");
      stats.style.display = event.target.checked ? "block" : "none";
    };
  }

  var kickButton = document.getElementById("kick-other-players-button");
  if (kickButton) {
    kickButton.onclick = function () {
      console.log(`-> SS: kick`);
      vars.getWs().send(JSON.stringify({ type: "kick" }));
    };
  }
}

function updateKickButton(playersCount) {
  var kickButton = document.getElementById("kick-other-players-button");
  if (kickButton) kickButton.value = `Kick (${playersCount})`;
}

function sendQualityConsoleCommands(descriptor) {
  if (descriptor.PrioritiseQuality !== null) {
    let command = "Streamer.PrioritiseQuality " + descriptor.PrioritiseQuality;
    let consoleDescriptor = {
      Console: command,
    };
    emitUIInteraction(consoleDescriptor);
  }

  if (descriptor.LowBitrate !== null) {
    let command = "Streamer.LowBitrate " + descriptor.LowBitrate;
    let consoleDescriptor = {
      Console: command,
    };
    emitUIInteraction(consoleDescriptor);
  }

  if (descriptor.HighBitrate !== null) {
    let command = "Streamer.HighBitrate " + descriptor.HighBitrate;
    let consoleDescriptor = {
      Console: command,
    };
    emitUIInteraction(consoleDescriptor);
  }

  if (descriptor.MinFPS !== null) {
    var command = "Streamer.MinFPS " + descriptor.MinFPS;
    let consoleDescriptor = {
      Console: command,
    };
    emitUIInteraction(consoleDescriptor);
  }
}

// A generic message has a type and a descriptor.
function emitDescriptor(messageType, descriptor) {
  // Convert the dscriptor object into a JSON string.
  let descriptorAsString = JSON.stringify(descriptor);

  // Add the UTF-16 JSON string to the array byte buffer, going two bytes at
  // a time.
  let data = new DataView(
    new ArrayBuffer(1 + 2 + 2 * descriptorAsString.length)
  );
  let byteIdx = 0;
  data.setUint8(byteIdx, messageType);
  byteIdx++;
  data.setUint16(byteIdx, descriptorAsString.length, true);
  byteIdx += 2;
  for (let i = 0; i < descriptorAsString.length; i++) {
    data.setUint16(byteIdx, descriptorAsString.charCodeAt(i), true);
    byteIdx += 2;
  }
  sendInputData(data.buffer);
}

// A UI interation will occur when the user presses a button powered by
// JavaScript as opposed to pressing a button which is part of the pixel
// streamed UI from the UE4 client.
export function emitUIInteraction(descriptor) {
  emitDescriptor(vars.getMessageType().UIInteraction, descriptor);
}

// A build-in command can be sent to UE4 client. The commands are defined by a
// JSON descriptor and will be executed automatically.
// The currently supported commands are:
//
// 1. A command to run any console command:
//    "{ ConsoleCommand: <string> }"
//
// 2. A command to change the resolution to the given width and height.
//    "{ Resolution: { Width: <value>, Height: <value> } }"
//
// 3. A command to change the encoder settings by reducing the bitrate by the
//    given percentage.
//    "{ Encoder: { BitrateReduction: <value> } }"
function emitCommand(descriptor) {
  emitDescriptor(vars.getMessageType().Command, descriptor);
}

export function requestQualityControl() {
  sendInputData(
    new Uint8Array([vars.getMessageType().RequestQualityControl]).buffer
  );
}

export function setupNormalizeAndQuantize() {
  let playerElement = document.getElementById("player");
  let videoElement = playerElement.getElementsByTagName("video");

  if (playerElement && videoElement.length > 0) {
    let playerAspectRatio =
      playerElement.clientHeight / playerElement.clientWidth;
    let videoAspectRatio =
      videoElement[0].videoHeight / videoElement[0].videoWidth;

    // Unsigned XY positions are the ratio (0.0..1.0) along a viewport axis,
    // quantized into an uint16 (0..65536).
    // Signed XY deltas are the ratio (-1.0..1.0) along a viewport axis,
    // quantized into an int16 (-32767..32767).
    // This allows the browser viewport and client viewport to have a different
    // size.
    // Hack: Currently we set an out-of-range position to an extreme (65535)
    // as we can't yet accurately detect mouse enter and leave events
    // precisely inside a video with an aspect ratio which causes mattes.
    if (playerAspectRatio > videoAspectRatio) {
      if (vars.getPrintInputs()) {
        console.log(
          "Setup Normalize and Quantize for playerAspectRatio > videoAspectRatio"
        );
      }
      let ratio = playerAspectRatio / videoAspectRatio;
      // Unsigned.
      vars.setNormalizeAndQuantizeUnsigned((x, y) => {
        let normalizedX = x / playerElement.clientWidth;
        let normalizedY = ratio * (y / playerElement.clientHeight - 0.5) + 0.5;
        if (
          normalizedX < 0.0 ||
          normalizedX > 1.0 ||
          normalizedY < 0.0 ||
          normalizedY > 1.0
        ) {
          return {
            inRange: false,
            x: 65535,
            y: 65535,
          };
        } else {
          return {
            inRange: true,
            x: normalizedX * 65536,
            y: normalizedY * 65536,
          };
        }
      });
      vars.setUnquantizeAndDenormalizeUnsigned((x, y) => {
        let normalizedX = x / 65536;
        let normalizedY = (y / 65536 - 0.5) / ratio + 0.5;
        return {
          x: normalizedX * playerElement.clientWidth,
          y: normalizedY * playerElement.clientHeight,
        };
      });
      // Signed.
      vars.setNormalizeAndQuantizeSigned((x, y) => {
        let normalizedX = x / (0.5 * playerElement.clientWidth);
        let normalizedY = (ratio * y) / (0.5 * playerElement.clientHeight);
        return {
          x: normalizedX * 32767,
          y: normalizedY * 32767,
        };
      });
    } else {
      if (vars.getPrintInputs()) {
        console.log(
          "Setup Normalize and Quantize for playerAspectRatio <= videoAspectRatio"
        );
      }
      let ratio = videoAspectRatio / playerAspectRatio;
      // Unsigned.
      vars.setNormalizeAndQuantizeUnsigned = (x, y) => {
        let normalizedX = ratio * (x / playerElement.clientWidth - 0.5) + 0.5;
        let normalizedY = y / playerElement.clientHeight;
        if (
          normalizedX < 0.0 ||
          normalizedX > 1.0 ||
          normalizedY < 0.0 ||
          normalizedY > 1.0
        ) {
          return {
            inRange: false,
            x: 65535,
            y: 65535,
          };
        } else {
          return {
            inRange: true,
            x: normalizedX * 65536,
            y: normalizedY * 65536,
          };
        }
      };
      vars.setUnquantizeAndDenormalizeUnsigned = (x, y) => {
        let normalizedX = (x / 65536 - 0.5) / ratio + 0.5;
        let normalizedY = y / 65536;
        return {
          x: normalizedX * playerElement.clientWidth,
          y: normalizedY * playerElement.clientHeight,
        };
      };
      // Signed.
      vars.setNormalizeAndQuantizeSigned = (x, y) => {
        let normalizedX = (ratio * x) / (0.5 * playerElement.clientWidth);
        let normalizedY = y / (0.5 * playerElement.clientHeight);
        return {
          x: normalizedX * 32767,
          y: normalizedY * 32767,
        };
      };
    }
  }
}

function start() {
  let statsDiv = document.getElementById("stats");
  if (statsDiv) {
    statsDiv.innerHTML = "Not connected";
  }

  if (!connect_on_load || is_reconnection) {
    showConnectOverlay();
    invalidateFreezeFrameOverlay();
    vars.setShouldShowPlayOverlay(true);
    resizePlayerStyle();
  } else {
    connect();
  }

  updateKickButton(0);
}

export function connect() {
  "use strict";

  window.WebSocket = window.WebSocket || window.MozWebSocket;

  if (!window.WebSocket) {
    alert("Your browser doesn't support WebSocket");
    return;
  }

  vars.setWs(
    new WebSocket(
      window.location.href
        .replace("http://", "ws://")
        .replace("https://", "wss://")
    )
  );

  vars.getWs().onmessage = function (event) {
    console.log(`<- SS: ${event.data}`);
    var msg = JSON.parse(event.data);
    if (msg.type === "config") {
      onConfig(msg);
    } else if (msg.type === "playerCount") {
      updateKickButton(msg.count - 1);
    } else if (msg.type === "answer") {
      onWebRtcAnswer(msg);
    } else if (msg.type === "iceCandidate") {
      onWebRtcIce(msg.candidate);
    } else {
      console.log(`invalid SS message type: ${msg.type}`);
    }
  };

  vars.getWs().onerror = function (event) {
    console.log(`WS error: ${JSON.stringify(event)}`);
  };

  vars.getWs().onclose = function (event) {
    console.log(`WS closed: ${JSON.stringify(event.code)} - ${event.reason}`);
    vars.setWs(undefined);
    is_reconnection = true;

    // destroy `webRtcPlayerObj` if any
    let playerDiv = document.getElementById("player");
    if (vars.getWebRtcPlayerObj()) {
      playerDiv.removeChild(vars.getWebRtcPlayerObj().video);
      vars.getWebRtcPlayerObj().close();
      vars.setWebRtcPlayerObj(undefined);
    }

    start();
  };
}

// Config data received from WebRTC sender via the Cirrus web server
function onConfig(config) {
  let playerDiv = document.getElementById("player");
  let playerElement = setupWebRtcPlayer(playerDiv, config);
  resizePlayerStyle();

  switch (vars.getInputOptions().controlScheme) {
    case vars.getControlSchemeType().HoveringMouse:
      registerHoveringMouseEvents(playerElement);
      break;
    case vars.getControlSchemeType().LockedMouse:
      registerLockedMouseEvents(playerElement);
      break;
    default:
      console.log(
        `ERROR: Unknown control scheme ${vars.getInputOptions.controlScheme}`
      );
      registerLockedMouseEvents(playerElement);
      break;
  }
}

export function load() {
  setupHtmlEvents();
  setupFreezeFrameOverlay();
  registerKeyboardEvents();
  start();
}
