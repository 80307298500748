/**
 * gets actual host
 * @todo remove this utility
 */
const DetectUrl = () => {
  let hostname =
    window.location.hostname.indexOf("www.") >= 0
      ? window.location.hostname
      : "www." + window.location.hostname;

  const urlParams = new URLSearchParams(window.location.search);
  const hostnameParam = urlParams.get("hostname");

  if (
    hostname.indexOf("localhost") >= 0 ||
    hostname.indexOf(".ddns.net") >= 0 ||
    hostname.indexOf("127.0.0.1") >= 0 ||
    hostname.indexOf("192.168") >= 0 ||
    hostname.indexOf("-demo") >= 0 ||
    hostname.indexOf("biz-tecma") >= 0
  ) {
    if (hostnameParam && hostnameParam.length > 0 && hostnameParam !== "") {
      localStorage.clear();
      delete localStorage["fp3d_dev_hostname"];
      localStorage.setItem("fp3d_dev_hostname", hostnameParam);
    }

    let fp3d_dev_hostname = localStorage.getItem("fp3d_dev_hostname");

    if (
      fp3d_dev_hostname &&
      fp3d_dev_hostname.length > 0 &&
      fp3d_dev_hostname !== ""
    ) {
      return fp3d_dev_hostname;
    }

    hostname = process.env.REACT_APP_HOST;
  }

  return hostname;
};

export default DetectUrl;
