import axios from 'axios';
import Cookies from 'js-cookie';

const API = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  headers: {
    //Authorization: `Bearer ${Cookies.get('jwt')}`,
    'Content-Type': 'multipart/form-data',
  },
});

API.interceptors.request.use(
  (request) => {
    const jwt = Cookies.get('jwt');
    request.headers.authorization = `Bearer ${jwt}`;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export const servizioAutoLogin = axios.create({
  baseURL: process.env.REACT_APP_AUTOLOGIN_URL,
});

export default API;

// axios.interceptors.request.use(
//   (request) => {
//     console.log(request);
//     // Edit request config
//     return request;
//   },
//   (error) => {
//     console.log(error);
//     return Promise.reject(error);
//   },
// );

// axios.interceptors.response.use(
//   (response) => {
//     console.log(response);
//     // Edit response config
//     return response;
//   },
//   (error) => {
//     console.log(error);
//     return Promise.reject(error);
//   },
// );
