import Cookies from 'js-cookie';
import psl from 'psl';

const getSessionId = () => {
  let sessionId = '';

  if (Cookies.get('sessionId')) {
    sessionId = Cookies.get('sessionId');
  } else {
    const length = 32;
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      sessionId += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    let domain = 'localhost';
    if (window.location.hostname !== 'localhost') {
      const pslUrl = psl.parse(window.location.hostname);
      domain = pslUrl.domain;
    }
    const cookieOptions = {
      domain: domain,
    };
    Cookies.set('sessionId', sessionId, cookieOptions);
  }

  return sessionId;
};

export default getSessionId;
