const loadScript = (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.addEventListener('load', () => {
      resolve();
    });
    script.addEventListener('error', e => {
      reject(e);
    });
    const head = document.getElementsByTagName('head')[0];
    return (head || document.body).appendChild(script);
  });
}

const LoadPardot = (piAId, piCId) => {
    window.piAId = piAId;
    window.piCId = piCId;
    loadScript(`${
      document.location.protocol === 'https:' ? 'https://pi' : 'http://cdn'
    }.pardot.com/pd.js`)
};

export default LoadPardot;
