import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useStore } from 'store/storeUtils';

import InfoBooking from 'components/common/InfoBooking';
import FilterSelection from 'components/specific/FilterSelection';
import ViewSelection from 'components/specific/ViewSelection';

import { Grid, Drawer, Button } from '@material-ui/core';

import convertPrice from 'utils/ApartmentsDefaultPrice';
import QUADRANT_STATES from 'constants/quadrantStates';

const FiltersDrawer = observer(
  ({ close, toggleMenu, backToLogin, resetFilters, dataList, filters, setFilter, views }) => {
    const store = useStore();
    const [resetFiltersVisible, setResetFiltersVisible] = React.useState(false);

    useEffect(() => {
      console.log(filters);
      setResetFiltersVisible(!!filters.dimensione);
    }, [filters]);

    const capitalizeTitle = (title) => {
      return title.charAt(0).toUpperCase() + title.slice(1);
    };

    const exitApp = () => {
      if (store.isDesk === 1) {
        window.ws.close();
        // store.removeVendorInfo();
        const ws = window.sessionStorage.getItem('customWsUri');
        window.location.href = `${process.env.REACT_APP_NEUROSALES_URL}${
          process.env.REACT_APP_NEUROSALES_URL_WS_PARAMS
        }${ws ? ws : process.env.REACT_APP_WS_BASEURL}`;
      } else {
        window.location.href = 'https://lambratetwinpalace.com/';
      }
    };

    const customViews = () => {
      let finalIndex = 0;
      const array = [...views];
      const filteredElements = array.filter((item) => item.toLowerCase().includes('vista01'));
      for (let fe of filteredElements) {
        const elemIndex = array.findIndex((el) => el === fe);
        array.splice(finalIndex, 0, array.splice(elemIndex, 1)[0]);
        finalIndex++;
      }
      const tour = array.filter((_, i) => i < 2);
      const apartment = array.filter((_, i) => i >= 2);
      return { tour, apartment };
    };

    const [focusViewTour, setFocusViewTour] = React.useState(true);
    const toggleFocus = () => setFocusViewTour(!focusViewTour);

    return (
      <>
        <div className={`button-menu ${!close ? 'open' : ''}`} onClick={() => toggleMenu()}>
          <img
            src={require('assets/images/icons/close-menu.svg')}
            alt={!close ? 'closed' : 'open'}
          />
        </div>
        <div className='fake-menu' style={close ? {} : { display: 'none' }}></div>
        <Drawer
          variant='persistent'
          anchor={'left'}
          open={!close}
          onClose={() => toggleMenu()}
          BackdropProps={{ invisible: true }}
          className='filter-container'
        >
          <Grid
            container
            justify='center'
            alignItems='center'
            direction='row'
            className='filter-container-header'
          >
            <Grid item xs={12} className='logo-container'>
              <img
                src={require('assets/images/twin-towers_logo.svg')}
                alt='logo'
                onClick={exitApp}
              />
            </Grid>
            <Grid item xs={12} className='filters-header'>
              <p className='filters-header-title'>
                Scegli <span className='filters-header-highlight'>la tua nuova casa</span>
              </p>
              <p className='filters-header-body'>
                Scegli e visualizza gli appartamenti in base alla tue preferenze
              </p>
              <img
                src={require('assets/images/icons/triple-down-arrow.svg')}
                alt='triple-down-arrow'
                className='filters-header-icon'
              />

              {store.isDesk === 1 ? (
                <div className='back-to-login-buttons'>
                  <Button
                    variant='contained'
                    className='vendor-button'
                    onClick={() => backToLogin('vendor')}
                  >
                    <img
                      src={require('assets/images/icons/new-vendor.svg')}
                      alt='new-vendor'
                      className='vendor-button-icon'
                    />
                    <div className='vendor-button-message'>
                      <span>Cambia</span>
                      <span className='highlight'>venditore</span>
                    </div>
                  </Button>
                  <Button
                    variant='contained'
                    className='client-button'
                    onClick={() => backToLogin('client')}
                  >
                    <img
                      src={require('assets/images/icons/client.svg')}
                      alt='client'
                      className='client-button-icon'
                    />
                    <div className='client-button-message'>
                      <span>Cambia</span>
                      <span className='highlight'>cliente</span>
                    </div>
                  </Button>
                </div>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
          <Grid container alignItems='center' direction='row'>
            <Grid item xs={12}>
              <Button
                variant='contained'
                className={`filter-container-reset-button ${!resetFiltersVisible ? 'hidden' : ''}`}
                onClick={resetFilters}
              >
                Azzera filtri
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            justify='center'
            alignItems='center'
            direction='row'
            className='filter-container-content'
          >
            {['tipologia', 'dimensione'].map((key, i) => (
              <Grid key={i} item xs={12} style={{ position: 'relative' }}>
                <FilterSelection
                  title={capitalizeTitle(key)}
                  data={dataList[key]}
                  value={filters[key]}
                  filter={key}
                  setFilter={setFilter}
                  filterNumber={i + 1}
                  disabled={dataList[key]?.length > 0 ? false : true}
                />
              </Grid>
            ))}
          </Grid>
          {filters['dimensione'] ? (
            <Grid container alignItems='center' direction='row'>
              <Grid item xs={12} className='filter-container-min-price'>
                <span className='filter-container-min-price-value'>
                  A partire da{' '}
                  {convertPrice(filters['dimensione'].min_price, {
                    tipologia: filters['tipologia'].Name,
                    dimensione: filters['dimensione'].Name,
                  })}
                </span>
              </Grid>
            </Grid>
          ) : (
            ''
          )}
          <Grid container alignItems='center' direction='row'>
            <Grid item xs={12} className='booking-alert'>
              <InfoBooking />
            </Grid>
          </Grid>
          {store.aptLegend.isVisible ? (
            <Grid
              container
              justify='center'
              alignItems='center'
              direction='row'
              className='legend-container'
            >
              {QUADRANT_STATES.map((aptType, i) => (
                <Grid
                  className={`legend ${
                    aptType === 'disponibili' &&
                    (store.aptLegend.isVisibleElem.opzionati ||
                      store.aptLegend.isVisibleElem.altriOpzionati ||
                      store.aptLegend.isVisibleElem.venduti)
                      ? 'separator'
                      : aptType === 'opzionati' &&
                        (store.aptLegend.isVisibleElem.altriOpzionati ||
                          store.aptLegend.isVisibleElem.venduti)
                      ? 'separator'
                      : aptType === 'altriOpzionati' && store.aptLegend.isVisibleElem.venduti
                      ? 'separator'
                      : ''
                  }`}
                  key={i}
                  item
                  xs={12}
                  style={store.aptLegend.isVisibleElem[aptType] ? {} : { display: 'none' }}
                >
                  <img
                    src={require(`assets/images/icons/${
                      aptType === 'disponibili'
                        ? 'available-building'
                        : aptType === 'opzionati'
                        ? 'optioned-building'
                        : aptType === 'altriOpzionati'
                        ? 'other-optioned-building'
                        : 'unavailable-building'
                    }.svg`)}
                    alt={`${
                      aptType === 'disponibili'
                        ? 'available-building'
                        : aptType === 'opzionati'
                        ? 'optioned-building'
                        : aptType === 'altriOpzionati'
                        ? 'other-optioned-building'
                        : 'unavailable-building'
                    }`}
                    className='legend-icon'
                  />
                  <span className='legend-description'>
                    <span>
                      {aptType === 'disponibili'
                        ? store.filtersInfo?.tipologia && store.filtersInfo?.dimensione ? store.filtersInfo?.tipologia?.Name+" "+store.filtersInfo?.dimensione?.Name : '-'
                        : aptType === 'opzionati'
                        ? store.filtersInfo?.tipologia && store.filtersInfo?.dimensione ? store.filtersInfo?.tipologia?.Name+" "+store.filtersInfo?.dimensione?.Name : '-'
                        : aptType === 'altriOpzionati'
                        ? 'Altre Soluzioni'
                        : store.filtersInfo?.tipologia && store.filtersInfo?.dimensione ? store.filtersInfo?.tipologia?.Name+" "+store.filtersInfo?.dimensione?.Name : '-'}
                    </span>
                    <span>
                      {aptType === 'disponibili'
                        ? 'Disponibili'
                        : aptType === 'opzionati'
                        ? 'Con Proposta'
                        : aptType === 'altriOpzionati'
                        ? 'Con Proposta'
                        : 'Non selezionabili'}
                    </span>
                  </span>
                </Grid>
              ))}
            </Grid>
          ) : (
            ''
          )}
        </Drawer>
        <div className={`view-wrapper ${close ? 'shiftFabLeft' : 'shiftFabRight'}`}>
          <ViewSelection
            views={customViews().tour}
            isTour={true}
            active={focusViewTour}
            onChangeFocus={toggleFocus}
          />
          <div className='view-wrapper-separator'></div>
          <ViewSelection
            views={customViews().apartment}
            active={!focusViewTour}
            onChangeFocus={toggleFocus}
          />
        </div>
      </>
    );
  },
);

FiltersDrawer.propTypes = {
  close: PropTypes.bool,
  toggleMenu: PropTypes.func,
  backToLogin: PropTypes.func,
  resetFilters: PropTypes.func,
  dataList: PropTypes.object,
  filters: PropTypes.object,
  setFilter: PropTypes.func,
  views: PropTypes.array,
};

FiltersDrawer.defaultProps = {
  close: false,
  dataList: {},
  filters: {},
  views: [],
};

export default FiltersDrawer;
