import React from 'react';

import { useStore } from 'store/storeUtils';
import { observer } from 'mobx-react';

import { ButtonBase } from '@material-ui/core';

import ViewSelection from 'components/specific/ViewSelection';
// import DayNightToggle from 'components/specific/DayNightToggle';
import ExitPopper from 'components/common/ExitPopper';

import 'styles/upper-lower-bands.scss';

const DeskUpperBand = observer(({ views }) => {
  const store = useStore();

  const [openExitApp, setOpenExitApp] = React.useState(false);
  const popperRef = React.useRef(null);
  const handleClick = () => setOpenExitApp(!openExitApp);

  const customViews = () => {
    let finalIndex = 0;
    const array = [...views];
    const filteredElements = array.filter((item) => item.toLowerCase().includes('vista01'));
    for (let fe of filteredElements) {
      const elemIndex = array.findIndex((el) => el === fe);
      array.splice(finalIndex, 0, array.splice(elemIndex, 1)[0]);
      finalIndex++;
    }
    const tour = array.filter((_, i) => i < 2);
    const apartment = array.filter((_, i) => i >= 2);
    return { tour, apartment };
  };

  const [focusViewTour, setFocusViewTour] = React.useState(true);
  const toggleFocus = () => setFocusViewTour(!focusViewTour);

  return (
    <div className={`upper-band ${store.isDesk === 1 ? 'less-space' : ''}`}>
      <div className='upper-band-title'>
        <span>Configura la tua casa in</span>
        <span>Lambrate Twin Palace</span>
      </div>
      <div className='upper-band-commands'>
        <div className='view-wrapper'>
          <ViewSelection
            views={customViews().tour}
            isTour={true}
            active={focusViewTour}
            onChangeFocus={toggleFocus}
          />
          <div className='view-wrapper-separator'></div>
          <ViewSelection
            views={customViews().apartment}
            active={!focusViewTour}
            onChangeFocus={toggleFocus}
          />
        </div>
        {/* <DayNightToggle /> */}
        <div className='upper-band-commands-separator'>&nbsp;</div>
        {store.isDesk === 1 ? (
          <ButtonBase
            className={`home-content-rotate-button ${store.isDesk === 1 ? 'less-space' : ''}`}
            onClick={() => store.setRotation()}
          >
            <img src={require('assets/images/icons/rotate-color.svg')} alt='rotate' />
            <span>Ruota visualizzazione</span>
          </ButtonBase>
        ) : (
          ''
        )}

        <ButtonBase
          className={`home-content-exit-button ${store.isDesk === 1 ? 'less-space' : ''}`}
          onClick={handleClick}
          ref={popperRef}
        >
          <img src={require('assets/images/icons/exit-left-arrow.svg')} alt='exit-left-arrow' />
          <span>Torna alla home</span>
        </ButtonBase>
        <ExitPopper
          popperRef={popperRef}
          openExitApp={openExitApp}
          setOpenExitApp={setOpenExitApp}
        />
      </div>
    </div>
  );
});

export default DeskUpperBand;
