import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useStore } from 'store/storeUtils';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import convertPrice from 'utils/ApartmentsDefaultPrice';

import 'styles/dropdown.scss';

const Dropdown = observer(({ title, items, value, setIndex, multiSelect }) => {
  const store = useStore();

  const [open, setOpen] = useState(false);
  const [selection, setSelection] = useState([]);
  const toggle = () => setOpen(!open);

  const onClickAway = () => setOpen(false);

  function handleOnClick(item, index) {
    if (!selection.some((current) => current.Name === item.Name)) {
      if (!multiSelect) {
        setSelection([item]);
      } else if (multiSelect) {
        setSelection([...selection, item]);
      }
    }
    // else {
    //   let selectionAfterRemoval = selection;
    //   selectionAfterRemoval = selectionAfterRemoval.filter((current) => current.Name !== item.Name);
    //   setSelection([...selectionAfterRemoval]);
    // }
    setIndex(index);
    toggle();
  }

  // function isItemInSelection(item) {
  //   if (selection.some((current) => current.Name === item.Name)) {
  //     return true;
  //   }
  //   return false;
  // }

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div className='dd-wrapper'>
        <div
          className={`dd-header ${open ? 'open' : ''} ${value ? 'selected' : ''}`}
          role='button'
          onKeyPress={() => toggle(!open)}
          onClick={() => toggle(!open)}
        >
          <div className='dd-header__title'>
            <p className={`dd-header__title--bold ${!value ? 'placeholder' : ''}`}>
              {value ? value.Name : title}
            </p>
          </div>
          <div className={`dd-header__action ${open ? 'open' : ''}`}>
            <p>{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</p>
          </div>
        </div>
        {open && (
          <ul className='dd-list'>
            {items.map(
              (item, i) =>
                item.Count > 0 && (
                  <li className='dd-list-item' key={i}>
                    <button type='button' onClick={() => handleOnClick(item, i)}>
                      <span>{item.Name}</span>
                      {/* <span>{isItemInSelection(item) && <CheckIcon />}</span> */}
                      <span className='subtitle'>
                        a partire da{' '}
                        {convertPrice(
                          item.min_price,
                          {
                            tipologia: title.includes('tipologia')
                              ? item.Name
                              : store.filtersInfo['tipologia']?.Name,
                            dimensione: item.Name,
                          },
                          title.includes('tipologia'),
                        )}
                      </span>
                    </button>
                  </li>
                ),
            )}
          </ul>
        )}
      </div>
    </ClickAwayListener>
  );
});

Dropdown.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  value: PropTypes.object,
  setIndex: PropTypes.func,
  multiSelect: PropTypes.bool,
};

Dropdown.defaultProps = {
  title: '',
  items: [],
  value: null,
  multiSelect: false,
};

export default Dropdown;
