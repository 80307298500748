import API, { servizioAutoLogin } from './api';

const convertParams = (params) => {
  const bodyFormData = new FormData();
  Object.keys(params).map((key) => {
    bodyFormData.append(key, params[key]);
  });
  return bodyFormData;
};

export const autoLogin = async () => {
  return await servizioAutoLogin.get(`/getToken`);
};

export const login = async (username, psw) => {
  const bodyFormData = convertParams({ username, psw });
  return await API.post(`/v1/home_conf_login.php`, bodyFormData);
};

export const getModello = async (ain_progetto, ain_isdesk) => {
  const bodyFormData = convertParams({ ain_progetto, ain_isdesk });
  return await API.post(`/v1/get_family.php`, bodyFormData);
};

export const getTipologia = async (ain_progetto, ain_family, ain_isdesk) => {
  const bodyFormData = convertParams({ ain_progetto, ain_family, ain_isdesk });
  return await API.post(`/v1/get_dimension.php`, bodyFormData);
};

export const getDimensione = async (ain_progetto, ain_family, ain_vano, ain_isdesk) => {
  const bodyFormData = convertParams({
    ain_progetto,
    ain_family,
    ain_vano,
    ain_isdesk,
  });
  return await API.post(`/v1/get_tipo.php`, bodyFormData);
};

export const soluzione = async (ain_progetto, ain_family, ain_vano, ain_tipo) => {
  const bodyFormData = convertParams({
    ain_progetto,
    ain_family,
    ain_vano,
    ain_tipo,
  });
  return await API.post(`/v1/get_planimetria.php`, bodyFormData);
};

export const pianoEdifici = async (ain_progetto) => {
  const bodyFormData = convertParams({ ain_progetto });
  return await API.post(`/v1/get_buildings.php`, bodyFormData);
};

export const pianoAppartamenti = async (
  ain_progetto,
  ain_family,
  ain_vano,
  ain_tipo,
  ain_planimetria,
  ain_versione,
) => {
  const bodyFormData = convertParams({
    ain_progetto,
    ain_family,
    ain_vano,
    ain_tipo,
    ain_planimetria,
    ain_versione,
  });
  return await API.post(`/v1/get_selectable_apartments.php`, bodyFormData);
};

export const getSoluzionePianoAppartamenti = async (
  ain_progetto,
  ain_family,
  ain_vano,
  ain_tipo,
) => {
  const bodyFormData = convertParams({
    ain_progetto,
    ain_family,
    ain_vano,
    ain_tipo,
  });
  return await API.post(`/v1/get_apartments.php`, bodyFormData);
};

export const getSelezioneAppartamenti = async (
  ain_progetto,
  ain_family,
  ain_vano,
  ain_tipo,
  ain_isdesk,
) => {
  const bodyFormData = convertParams({
    ain_progetto,
    ain_isdesk,
    ain_family,
    ain_vano,
    ain_tipo,
  });
  return await API.post(`/v2/get_apartments_selection.php`, bodyFormData);
};

export const quadrantiAppartamentiVenduti = async (ain_progetto) => {
  const bodyFormData = convertParams({ ain_progetto });
  return await API.post(`/v2/get_sold_apartments.php`, bodyFormData);
};

export const getQuadrantiOccupati = async (ain_progetto) => {
  const bodyFormData = convertParams({ ain_progetto });
  return await API.post(`/v2/get_quadranti_proposte.php`, bodyFormData);
};

export const proposteAppartamenti = async (ain_progetto) => {
  const bodyFormData = convertParams({ ain_progetto });
  return await API.post(`/v1/get_proposte.php`, bodyFormData);
};

export const riepilogo = async (
  ain_progetto,
  ain_family,
  ain_vano,
  ain_tipo,
  ain_planimetria,
  ain_edificio,
  ain_piano,
  ain_quadrante,
  ain_versione,
  ain_isdesk,
  ain_session_id = null,
  ain_vendor_id = null,
  ain_client_id = null,
) => {
  const params = {
    ain_edificio,
    ain_family,
    ain_isdesk,
    ain_piano,
    ain_planimetria,
    ain_progetto,
    ain_quadrante,
    ain_tipo,
    ain_vano,
    ain_versione,
  };
  if (ain_session_id) {
    params.ain_session_id = ain_session_id;
  }
  if (ain_vendor_id && ain_client_id) {
    params.ain_vendor_id = ain_vendor_id;
    params.ain_client_id = ain_client_id;
  }
  const bodyFormData = convertParams(params);
  return await API.post(`/v2/get_riepilogo.php`, bodyFormData);
};

export const updatePreventivo = async (ain_progetto, preventivo_id, action, ain_desk, name) => {
  const bodyFormData = name
    ? convertParams({ ain_progetto, preventivo_id, action, ain_desk, name })
    : convertParams({ ain_progetto, preventivo_id, action, ain_desk });
  return await API.post(`/v2/update_preventivo.php`, bodyFormData);
};

export const vendorLogin = async (ain_vendor_email) => {
  const bodyFormData = convertParams({ ain_vendor_email });
  return await API.post(`/v2/vendor_login.php`, bodyFormData);
};

export const clientLogin = async (ain_client_email) => {
  const bodyFormData = convertParams({ ain_client_email });
  return await API.post(`/v2/client_login.php`, bodyFormData);
};

export const utentiConnessiHomeConfigurator = async (ain_progetto) => {
  const bodyFormData = convertParams({ ain_progetto });
  return await API.post(`/v2/get_current_total_users.php`, bodyFormData);
};

export const informazioniFineCampagna = async (ain_progetto) => {
  const bodyFormData = convertParams({ ain_progetto });
  return await API.post(`/v2/get_end_campaign.php`, bodyFormData);
};

export const getRankingPlanimetrie = async (ain_progetto) => {
  const bodyFormData = convertParams({ ain_progetto });
  return await API.post(`/v2/get_planimetrie_ranking.php`, bodyFormData);
};

export const getValutazioniAppartamento = async (ain_progetto, ain_external_id, ain_isdesk) => {
  const bodyFormData = convertParams({
    ain_progetto,
    ain_external_id,
    ain_isdesk,
  });
  return await API.post(`/v2/get_analytics_apartment_in_cart.php`, bodyFormData);
};
