import React from 'react';
import PropTypes from 'prop-types';

import { Grid /*Popper, ClickAwayListener*/ } from '@material-ui/core';
// import CloseIcon from '@material-ui/icons/Close';

import Dropdown from './Dropdown';

import 'styles/button.scss';
import 'styles/arrow-selection.scss';

const FilterSelection = (props) => {
  const { title, data, value, filter, setFilter, filterNumber, disabled } = props;

  const [index, setIndex] = React.useState(-1);

  React.useEffect(() => {
    setFilter(filter, data[index]);
  }, [index]);

  React.useEffect(() => {
    setIndex(-1);
  }, [data]);

  // const [open, setOpen] = React.useState(false);
  // const popperRef = React.useRef(null);
  // const handleOpenInfo = () => setOpen(!open);
  // const onClickAway = () => setOpen(false);
  // const id = open ? 'simple-popper' : undefined;

  return (
    <>
      <Grid className={`selection-box ${disabled ? 'disabled' : ''}`} container>
        <Grid item xs={12} className='header-container'>
          <span className='header-container-filter-number'>{filterNumber}</span>
          <span className='filter-header'>{title}</span>
          {/* {value ? (
            <>
              <span
                className='filter-header info'
                onClick={handleOpenInfo}
                aria-describedby={id}
                ref={popperRef}
              >
                Info
              </span>
              <Popper
                id={id}
                open={open}
                anchorEl={popperRef.current}
                placement='bottom-end'
                transition
                className='popper'
              >
                <ClickAwayListener onClickAway={onClickAway}>
                  <div className='popper-info'>
                    <div className='popper-info-header'>
                      <div className='popper-info-header-container'>
                        <p className='popper-info-header-container-title'>{title}</p>
                        <p className='popper-info-header-container-subtitle'>{data[index]?.Name}</p>
                      </div>
                      <span className='popper-info-header-close' onClick={() => setOpen(false)}>
                        <CloseIcon />
                      </span>
                    </div>
                    <div className='popper-info-body'>
                      <p className='popper-info-body-price'>
                        in promo a partire da{' '}
                        <span className='popper-info-body-price-highlight'>
                          {data[index]?.min_price
                            ? data[index]?.min_price
                            : 'Prezzo non disponibile'}
                        </span>
                      </p>
                      <p className='popper-info-body-description'>
                        {data[index]?.description
                          ? data[index]?.description
                          : 'Descrizione non disponibile'}
                      </p>
                    </div>
                  </div>
                </ClickAwayListener>
              </Popper>
            </>
          ) : (
            ''
          )} */}
        </Grid>
        <Grid item xs={12}>
          <Dropdown
            title={`Seleziona ${title.toLowerCase()}`}
            items={data}
            value={value}
            index={index}
            setIndex={setIndex}
          />
        </Grid>
      </Grid>
    </>
  );
};

FilterSelection.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  value: PropTypes.object,
  filter: PropTypes.string,
  setFilter: PropTypes.func,
  filterNumber: PropTypes.number,
  disabled: PropTypes.bool,
};

FilterSelection.defaultProps = {
  title: '',
  data: [],
  value: null,
  filter: '',
  filterNumber: 0,
  disabled: true,
};

export default FilterSelection;
