const isMyHome = () => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  return params.has('vendorId') && params.has('clientId');
};

const credentialsInParams = () => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  return { vendorId: params.get('vendorId'), clientId: params.get('clientId') };
};

export { isMyHome, credentialsInParams };
