import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useStore } from 'store/storeUtils';

import DrawerCommands from 'components/common/DrawerCommands';
import DrawerHeader from 'components/common/DrawerHeader';
import Summary from 'components/specific/Summary';
import PlanimetryDialog from 'components/common/PlanimetryDialog';
import PromoBanner from 'components/common/PromoBanner';

import { Button, Drawer, Grid, useMediaQuery } from '@material-ui/core';

import { getRankingPlanimetrie, getValutazioniAppartamento, riepilogo } from 'axios/REST_api';

import CurrencyFormatter from 'utils/CurrencyFormatter';
import getSessionId from 'utils/getSessionId';
import { isMyHome, credentialsInParams } from 'utils/isMyHome';

import 'styles/planimetry-selection.scss';
import CrmLoader from 'components/generic/CrmLoader';

const PlanimetrySelection = observer(({ slot, openDrawer, setOpenDrawer, reservedApts }) => {
  const store = useStore();

  const matches = useMediaQuery('(max-width:959px)');

  const [dataRetrieved, setDataRetrieved] = React.useState(null);
  const [selectedProposal, setSelectedProposal] = React.useState(null);

  const [rankingPlanimetrie, setRankingPlanimetrie] = React.useState([]);
  const [valutazioniApt, setValutazioniApt] = React.useState([]);

  const [selectedAptBuilding, setSelectedAptBuilding] = React.useState(null);

  const [loading, setLoading] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);

  const [openExitApp, setOpenExitApp] = React.useState(false);
  const popperRef = React.useRef(null);
  const handleClickExit = () => setOpenExitApp(!openExitApp);

  React.useEffect(() => {
    getRankingPlanimetrie(process.env.REACT_APP_PROJECT)
      .then((res) => {
        const { data } = res.data;
        setRankingPlanimetrie(data);
      })
      .catch((err) => {
        console.error(
          '❌ ~ file: PlanimetrySelection.jsx ~ line 34 ~ rankingPlanimetrie ~ err',
          err,
        );
      });
  }, [slot]);

  const recuperoNumeroProposte = async () => {
    const visualizzazioniApt = [...valutazioniApt];
    for (let index = 0; index < slot.length; index++) {
      try {
        const res = await getValutazioniAppartamento(
          process.env.REACT_APP_PROJECT,
          slot[index].AIn_external_id,
          store.isDesk,
        );
        const { count_in_cart: numVisualizzazioni } = res.data;
        visualizzazioniApt[index] = String(numVisualizzazioni);
      } catch (error) {
        console.error(error);
      }
    }
    setValutazioniApt(visualizzazioniApt);
  };

  React.useEffect(() => {
    recuperoNumeroProposte();
  }, [slot]);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
    window.emitUIInteraction({ focus: store.currentView });
    window.emitUIInteraction('apartment_Deselect');
  };

  // const findPlanimetryRanking = (apartment) => {
  //   const position = rankingPlanimetrie.findIndex((rp) => rp.planimetria === apartment.Name);
  //   if (position === -1) return '\xa0';
  //   if (position + 1 <= 3) return 'Top 3! Tra i più scelti del progetto';
  //   if (position + 1 <= 5) return 'Top 5! Tra i più scelti del progetto';
  //   return 'Top 10! Tra i più scelti del progetto';
  // };

  const findPlanimetryRanking = (apartment) => {
    const position = rankingPlanimetrie.findIndex((rp) => rp.planimetria === apartment.Name);
    if (position === -1) return 0;
    if (position + 1 <= 3) return 3;
    if (position + 1 <= 5) return 5;
    return 10;
  };

  const getFormattedSurface = (surface) => (+surface).toFixed(0);

  const getApartmentPrice = (apartment) => {
    const preventivo = JSON.parse(apartment.valori_preventivo);
    return preventivo ? CurrencyFormatter(preventivo[0].vui) : '';
  };

  const [openSummary, setOpenSummary] = React.useState(false);
  const viewSummary = async (apartment, index) => {
    if (store.isDesk || isMyHome()) {
      setLoading(true);
    }
    setSelectedAptBuilding(apartment.AIn_Edificio);
    const { modello, tipologia, dimensione } = store.filtersInfo;
    const {
      AIn_Edificio: edificio,
      AIn_Piano: piano,
      Name: planimetria,
      AIn_Quadrante: quadrante,
      AIn_Versione: versione,
    } = apartment;

    try {
      const res = await riepilogo(
        process.env.REACT_APP_PROJECT,
        modello.Code,
        tipologia.Code,
        dimensione.Code,
        planimetria,
        edificio,
        piano,
        quadrante,
        versione,
        store.isDesk === 0 ? (isMyHome() ? 1 : 0) : 1,
        store.isDesk === 0 ? (isMyHome() ? null : getSessionId()) : null,
        store.isDesk === 1
          ? store.vendorInfo.vendor_id
          : isMyHome()
          ? credentialsInParams().vendorId
          : null,
        store.isDesk === 1
          ? store.clientInfo.client_id
          : isMyHome()
          ? credentialsInParams().clientId
          : null,
      );

      const { data } = res.data;
      if (data) {
        setDataRetrieved(res.data);
        setSelectedProposal(valutazioniApt[index]);
        setOpenSummary(true);
      } else {
        setOpenSummary(false);
        console.log('ERRORE RIEPILOGO');
      }
    } catch (error) {
      console.error(
        '❌ ~ file: PlanimetrySelection.jsx ~ line 148 ~ riepilogo try-catch ~ err',
        error,
      );
    } finally {
      setLoading(false);
    }
  };

  const [currentPlanimetry, setCurrentPlanimetry] = React.useState(null);
  const openPlanimetry = (apt) => {
    setOpenModal(true);
    setCurrentPlanimetry(apt);
  };

  React.useEffect(() => {
    if (!openModal) setCurrentPlanimetry(null);
  }, [openModal]);

  const checkReservation = (apt) => {
    const index = reservedApts.findIndex(
      (ra) =>
        ra.Name === apt.Name &&
        ra.AIn_Edificio === apt.AIn_Edificio &&
        ra.AIn_Piano === apt.AIn_Piano &&
        ra.AIn_Quadrante === apt.AIn_Quadrante,
    );
    return index >= 0;
  };

  return (
    <>
      <Drawer
        className='planimetry-selection'
        anchor={'right'}
        transitionDuration={{ enter: 1000, exit: 700 }}
        open={openDrawer}
        onClose={toggleDrawer}
        style={store.isRotate ? { transform: 'rotate(180deg)' } : {}}
      >
        <DrawerCommands
          toggleDrawer={toggleDrawer}
          handleClickExit={handleClickExit}
          popperRef={popperRef}
          openExitApp={openExitApp}
          setOpenExitApp={setOpenExitApp}
        />
        <CrmLoader z loading={loading} hasBackdrop transparency />
        <div className='promo'>
          <PromoBanner backTo={openSummary} />
        </div>
        <div className={`fixed-spacer ${slot.length <= 1 ? 'only-one' : ''}`}>
          <DrawerHeader />
          <div className={`content-container ${slot.length <= 1 ? 'only-one' : ''}`}>
            <div className='title-promo-container'>
              <div className='title-promo-container-ce'>
                <span className='title-promo-container-ce-apartment'>
                  {store.filtersInfo?.tipologia?.Name} {store.filtersInfo?.dimensione?.Name}
                </span>
                <span className='title-promo-container-spacing'>
                  <br />
                </span>{' '}
                <span className='title-promo-container-ce-building'>
                  Edificio&nbsp;
                  <strong>
                    {slot[0]?.AIn_Edificio === '01'
                      ? 'Ambra'
                      : slot[0]?.AIn_Edificio === '02'
                      ? 'Oro'
                      : slot[0]?.AIn_Edificio}
                  </strong>
                  &nbsp;-&nbsp;
                  <strong>{slot[0]?.AIn_Piano}°</strong>&nbsp;piano
                </span>
              </div>
            </div>
            <div className='info-container'>
              {slot.map((apartment, i) => (
                <>
                  <span className='apartment-numbering'>
                    <strong>Soluzione {i + 1}</strong> di {slot.length}
                  </span>
                  <Grid
                    container
                    key={i}
                    className={`apartment-container ${slot.length > 1 ? 'more-apartments' : ''}`}
                  >
                    <Grid container xs={8} className='apartment-wrapper'>
                      <div className='apartment-wrapper-title'>
                        <span className='apartment-wrapper-title-se'>
                          <img
                            className='apartment-wrapper-title-icon'
                            src={require('assets/images/icons/double-right-arrow.svg')}
                            alt='double right arrow'
                          />
                          {apartment.Name}
                        </span>
                        {findPlanimetryRanking(apartment) > 0 ? (
                          <span className='apartment-wrapper-title-ribbon-ranking'>
                            <img
                              className='apartment-wrapper-title-ribbon-ranking-icon'
                              src={require('assets/images/icons/ribbon.svg')}
                              alt='ribbon'
                            />
                            <span className='apartment-wrapper-title-ribbon-ranking-message'>
                              {matches ? (
                                <strong>Top {findPlanimetryRanking(apartment)}!</strong>
                              ) : (
                                <>
                                  Questo appartamento è nella{' '}
                                  <strong>Top {findPlanimetryRanking(apartment)}</strong> dei più
                                  richiesti!
                                </>
                              )}
                            </span>
                            {/* <span className='apartment-wrapper-title-ribbon-ranking-top'>
                              <strong>Top {findPlanimetryRanking(apartment)}!</strong>
                            </span>
                            <span className='apartment-wrapper-title-ribbon-ranking-separator'>
                              |
                            </span>
                            <span className='apartment-wrapper-title-ribbon-ranking-choice'>
                              <strong>Tra i più scelti</strong> del progetto
                            </span> */}
                          </span>
                        ) : (
                          ''
                        )}
                      </div>
                      <Grid item xs={4} className='apartment'>
                        <div className='apartment-planimetry'>
                          <img
                            className='apartment-planimetry-icon'
                            src={require('assets/images/icons/enlarge.svg')}
                            alt='enlarge'
                            onClick={() => openPlanimetry(apartment)}
                          />
                          <img
                            src={`https://planimetrieprogetti.abitareinspa.com/${process.env.REACT_APP_PROJECT.toLowerCase()}/th/${apartment.Name.toLowerCase().replaceAll(
                              ' ',
                              '_',
                            )}.jpg`}
                            alt='apartment-planimetry'
                            className='apartment-planimetry-image'
                            onClick={() => openPlanimetry(apartment)}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={8} className='apartment-info'>
                        <div className='apartment-info-wrapper'>
                          <span className='apartment-info-wrapper-title'>
                            I dettagli della planimetria.
                          </span>
                          <div className='apartment-info-wrapper-filters'>
                            {/* <span className='apartment-info-wrapper-filters-modello'>
                            Modello
                            <span>{store.filtersInfo?.modello?.Name}</span>
                          </span> */}
                            <span className='apartment-info-wrapper-filters-tipologia'>
                              Tipologia
                              <span>{store.filtersInfo?.tipologia?.Name}</span>
                            </span>
                            <span className='apartment-info-wrapper-filters-dimensione'>
                              Dimensione
                              <span>{store.filtersInfo?.dimensione?.Name}</span>
                            </span>
                          </div>
                        </div>
                        <div className='apartment-info-surfaces'>
                          <div className='apartment-info-surfaces-total'>
                            <img
                              src={require('assets/images/icons/planimetry.svg')}
                              alt='superficie netta'
                            />
                            <span className='apartment-info-surfaces-total-description'>
                              Superficie totale di
                              <span>
                                {getFormattedSurface(
                                  +apartment.AIn_SuperficieLorda +
                                    +apartment.AIn_SuperficieTerrazzo,
                                )}{' '}
                                mq
                              </span>
                            </span>
                          </div>
                          <div className='apartment-info-surfaces-separator'></div>
                          <div className='apartment-info-surfaces-lodge'>
                            <img src={require('assets/images/icons/lodge.svg')} alt='loggia' />
                            <span className='apartment-info-surfaces-lodge-description'>
                              di cui Loggia
                              <span>
                                {getFormattedSurface(apartment.AIn_SuperficieTerrazzo)} mq
                              </span>
                            </span>
                          </div>
                        </div>

                        <div className='apartment-info-separator'></div>
                        <div className='apartment-info-warnings'>
                          {valutazioniApt[i] > 0 ? (
                            <div className='apartment-info-warnings-proposal'>
                              <img src={require('assets/images/icons/alert.svg')} alt='alert' />
                              <span>
                                <strong>
                                  {+valutazioniApt[i] === 1
                                    ? '1 altra persona sta'
                                    : `Altre ${valutazioniApt[i]} persone stanno`}{' '}
                                  valutando
                                </strong>{' '}
                                l’acquisto di questo appartamento
                              </span>
                            </div>
                          ) : (
                            ''
                          )}
                          {checkReservation(apartment) ? (
                            <div className='apartment-info-warnings-proposal'>
                              <img src={require('assets/images/icons/alert.svg')} alt='alert' />
                              <span>
                                <strong>ATTENZIONE</strong>: la posizione selezionata ha già una
                                proposta d’acquisto!
                              </span>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </Grid>
                    </Grid>

                    <Grid item xs={4} className='apartment-promo'>
                      <span className='apartment-promo-title'>Prezzo appartamento</span>
                      <div className='apartment-promo-price'>
                        <span className='apartment-promo-price-value'>
                          {getApartmentPrice(apartment)}
                        </span>
                        <span className='apartment-promo-price-description'>
                          L&apos;appartamento è in promozione!
                        </span>
                        <img
                          className='apartment-promo-price-icon'
                          src={require('assets/images/icons/triple-down-arrow.svg')}
                          alt='triple down arrow'
                        />
                        <Button
                          className='apartment-promo-price-button'
                          variant='contained'
                          onClick={() => viewSummary(apartment, i)}
                        >
                          Scopri la promo
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </>
              ))}
            </div>
            <PlanimetryDialog
              openModal={openModal}
              setOpenModal={setOpenModal}
              apartmentData={currentPlanimetry}
            />
            {slot.length <= 1 && <div className='only-one'>Non sono presenti altre soluzioni.</div>}
          </div>
        </div>
      </Drawer>
      <Summary
        summary={dataRetrieved}
        proposals={selectedProposal}
        openSummary={openSummary}
        setOpenSummary={setOpenSummary}
        correctBuilding={selectedAptBuilding}
        planimetryRanking={findPlanimetryRanking}
      />
    </>
  );
});

PlanimetrySelection.propTypes = {
  slot: PropTypes.array,
  openDrawer: PropTypes.bool,
  setOpenDrawer: PropTypes.func,
  reservedApts: PropTypes.array,
};

PlanimetrySelection.defaultProps = {
  slot: [],
  openDrawer: false,
  reservedApts: [],
};

export default PlanimetrySelection;
