/**
 * a number formatter for currency
 */

const CurrencyFormatter = (value, currency = "EUR", locale = "it-IT") => {
  value = value ? value : 0;
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: value % 1 === 0 ? 0 : 2,
    maximumFractionDigits: value % 1 === 0 ? 0 : 2,
  }).format(value);
};

export default CurrencyFormatter;
