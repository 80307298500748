/* eslint-disable no-unused-vars */
/* eslint-disable no-prototype-builtins */
import * as vars from "./Variables";
import {
  showTextOverlay,
  showPlayOverlay,
  resizePlayerStyle,
} from "./ResizePlayer";
import {
  resetAfkWarningTimer,
  showFreezeFrame,
  invalidateFreezeFrameOverlay,
} from "./ResizePlayer";
import {
  showOnScreenKeyboard,
  createOnScreenKeyboardHelpers,
} from "./KeyboardEvents";
import { registerInputs } from "./MouseEvents";
import WebRtcPlayer from "./WebRtcPlayer";

const responseEventListeners = new Map();

const WS_OPEN_STATE = 1;

function createWebRtcOffer() {
  if (vars.getWebRtcPlayerObj()) {
    console.log("Creating offer");
    showTextOverlay("Starting connection to server, please wait");
    vars.getWebRtcPlayerObj().createOffer();
  } else {
    console.log("WebRTC player not setup, cannot create offer");
    showTextOverlay("Unable to setup video");
  }
}

export function sendInputData(data) {
  if (vars.getWebRtcPlayerObj()) {
    resetAfkWarningTimer();
    vars.getWebRtcPlayerObj().send(data);
  }
}

function addResponseEventListener(name, listener) {
  responseEventListeners.set(name, listener);
}

function removeResponseEventListener(name) {
  responseEventListeners.remove(name);
}

// Must be kept in sync with PixelStreamingProtocol::EToClientMsg C++ enum.
const ToClientMessageType = {
  QualityControlOwnership: 0,
  Response: 1,
  Command: 2,
  FreezeFrame: 3,
  UnfreezeFrame: 4,
};

export function setupWebRtcPlayer(htmlElement, config) {
  vars.setWebRtcPlayerObj(
    new WebRtcPlayer({
      peerConnectionOptions: config.peerConnectionOptions,
    })
  );
  htmlElement.appendChild(vars.getWebRtcPlayerObj().video);
  htmlElement.appendChild(vars.getFreezeFrameOverlay());

  vars.getWebRtcPlayerObj().onWebRtcOffer = function (offer) {
    if (vars.getWs() && vars.getWs().readyState === WS_OPEN_STATE) {
      let offerStr = JSON.stringify(offer);
      console.log(`-> SS: offer:\n${offerStr}`);
      vars.getWs().send(offerStr);
    }
  };

  vars.getWebRtcPlayerObj().onWebRtcCandidate = function (candidate) {
    if (vars.getWs() && vars.getWs().readyState === WS_OPEN_STATE) {
      console.log(
        `-> SS: iceCandidate\n${JSON.stringify(candidate, undefined, 4)}`
      );
      vars
        .getWs()
        .send(JSON.stringify({ type: "iceCandidate", candidate: candidate }));
    }
  };

  vars.getWebRtcPlayerObj().onVideoInitialised = function () {
    if (vars.getWs() && vars.getWs().readyState === WS_OPEN_STATE) {
      if (vars.getShouldShowPlayOverlay()) {
        showPlayOverlay();
        resizePlayerStyle();
      }
    }
  };

  vars.getWebRtcPlayerObj().onDataChannelConnected = function () {
    if (vars.getWs() && vars.getWs().readyState === WS_OPEN_STATE) {
      showTextOverlay("WebRTC connected, waiting for video");
    }
  };

  vars.getWebRtcPlayerObj().onDataChannelMessage = function (data) {
    var view = new Uint8Array(data);
    if (vars.getFreezeFrame().receiving) {
      let jpeg = new Uint8Array(
        vars.getFreezeFrame().jpeg.length + view.length
      );
      jpeg.set(vars.getFreezeFrame().jpeg, 0);
      jpeg.set(view, vars.getFreezeFrame().jpeg.length);
      vars.setFreezeFrame(jpeg, "jpeg");
      if (vars.getFreezeFrame().jpeg.length === vars.getFreezeFrame().size) {
        vars.setFreezeFrame(false, "receiving");
        vars.setFreezeFrame(true, "valid");
        console.log(
          `received complete freeze frame ${vars.getFreezeFrame().size}`
        );
        showFreezeFrame();
      } else if (
        vars.getFreezeFrame().jpeg.length > vars.getFreezeFrame().size
      ) {
        console.error(
          `received bigger freeze frame than advertised: ${
            vars.getFreezeFrame().jpeg.length
          }/${vars.getFreezeFrame().size}`
        );
        vars.setFreezeFrame(undefined, "jpeg");
        vars.setFreezeFrame(false, "receiving");
      } else {
        console.log(
          `received next chunk (${view.length} bytes) of freeze frame: ${
            vars.getFreezeFrame().jpeg.length
          }/${vars.getFreezeFrame().size}`
        );
      }
    } else if (view[0] === ToClientMessageType.QualityControlOwnership) {
      let ownership = view[1] === 0 ? false : true;
      // If we own the quality control, we can't relenquish it. We only loose
      // quality control when another peer asks for it
      if (vars.getQualityControlOwnershipCheckBox !== null) {
        vars.setQualityControlOwnershipCheckBox(ownership, "disabled");
        vars.setQualityControlOwnershipCheckBox(ownership, "checked");
      }
    } else if (view[0] === ToClientMessageType.Response) {
      let response = new TextDecoder("utf-16").decode(data.slice(1));
      for (let listener of responseEventListeners.values()) {
        listener(response);
      }
    } else if (view[0] === ToClientMessageType.Command) {
      let commandAsString = new TextDecoder("utf-16").decode(data.slice(1));
      console.log(commandAsString);
      let command = JSON.parse(commandAsString);
      if (command.command === "onScreenKeyboard") {
        showOnScreenKeyboard(command);
      }
    } else if (view[0] === ToClientMessageType.FreezeFrame) {
      vars.setFreezeFrame(
        new DataView(view.slice(1, 5).buffer).getInt32(0, true),
        "size"
      );
      vars.setFreezeFrame(view.slice(1 + 4), "jpeg");
      if (vars.getFreezeFrame().jpeg.length < vars.getFreezeFrame().size) {
        console.log(
          `received first chunk of freeze frame: ${
            vars.getFreezeFrame().jpeg.length
          }/${vars.getFreezeFrame().size}`
        );
        vars.setFreezeFrame(true, "receiving");
      } else {
        console.log(
          `received complete freeze frame: ${
            vars.getFreezeFrame().jpeg.length
          }/${vars.getFreezeFrame().size}`
        );
        showFreezeFrame();
      }
    } else if (view[0] === ToClientMessageType.UnfreezeFrame) {
      invalidateFreezeFrameOverlay();
    } else {
      console.error(`unrecognised data received, packet ID ${view[0]}`);
    }
  };

  registerInputs(vars.getWebRtcPlayerObj().video);

  // On a touch device we will need special ways to show the on-screen keyboard.
  if ("ontouchstart" in document.documentElement) {
    createOnScreenKeyboardHelpers(htmlElement);
  }

  createWebRtcOffer();

  return vars.getWebRtcPlayerObj().video;
}

export function onWebRtcAnswer(webRTCData) {
  vars.getWebRtcPlayerObj().receiveAnswer(webRTCData);

  let printInterval = 5 * 60 * 1000; /*Print every 5 minutes*/
  let nextPrintDuration = printInterval;

  vars.getWebRtcPlayerObj().onAggregatedStats = (aggregatedStats) => {
    let numberFormat = new Intl.NumberFormat(window.navigator.language, {
      maximumFractionDigits: 0,
    });
    let timeFormat = new Intl.NumberFormat(window.navigator.language, {
      maximumFractionDigits: 0,
      minimumIntegerDigits: 2,
    });
    let statsText = "";

    // Calculate duration of run
    let runTime =
      (aggregatedStats.timestamp - aggregatedStats.timestampStart) / 1000;
    let timeValues = [];
    let timeDurations = [60, 60];
    for (let timeIndex = 0; timeIndex < timeDurations.length; timeIndex++) {
      timeValues.push(runTime % timeDurations[timeIndex]);
      runTime = runTime / timeDurations[timeIndex];
    }
    timeValues.push(runTime);

    let runTimeSeconds = timeValues[0];
    let runTimeMinutes = Math.floor(timeValues[1]);
    let runTimeHours = Math.floor([timeValues[2]]);

    let receivedBytesMeasurement = "B";
    let receivedBytes = aggregatedStats.hasOwnProperty("bytesReceived")
      ? aggregatedStats.bytesReceived
      : 0;
    let dataMeasurements = ["kB", "MB", "GB"];
    for (let index = 0; index < dataMeasurements.length; index++) {
      if (receivedBytes < 100 * 1000) break;
      receivedBytes = receivedBytes / 1000;
      receivedBytesMeasurement = dataMeasurements[index];
    }

    statsText += `Duration: ${timeFormat.format(
      runTimeHours
    )}:${timeFormat.format(runTimeMinutes)}:${timeFormat.format(
      runTimeSeconds
    )}</br>`;
    statsText += `Video Resolution: ${
      aggregatedStats.hasOwnProperty("frameWidth") &&
      aggregatedStats.frameWidth &&
      aggregatedStats.hasOwnProperty("frameHeight") &&
      aggregatedStats.frameHeight
        ? aggregatedStats.frameWidth + "x" + aggregatedStats.frameHeight
        : "N/A"
    }</br>`;
    statsText += `Received (${receivedBytesMeasurement}): ${numberFormat.format(
      receivedBytes
    )}</br>`;
    statsText += `Frames Decoded: ${
      aggregatedStats.hasOwnProperty("framesDecoded")
        ? numberFormat.format(aggregatedStats.framesDecoded)
        : "N/A"
    }</br>`;
    statsText += `Packets Lost: ${
      aggregatedStats.hasOwnProperty("packetsLost")
        ? numberFormat.format(aggregatedStats.packetsLost)
        : "N/A"
    }</br>`;
    statsText += `Bitrate (kbps): ${
      aggregatedStats.hasOwnProperty("bitrate")
        ? numberFormat.format(aggregatedStats.bitrate)
        : "N/A"
    }</br>`;
    statsText += `Framerate: ${
      aggregatedStats.hasOwnProperty("framerate")
        ? numberFormat.format(aggregatedStats.framerate)
        : "N/A"
    }</br>`;
    statsText += `Frames dropped: ${
      aggregatedStats.hasOwnProperty("framesDropped")
        ? numberFormat.format(aggregatedStats.framesDropped)
        : "N/A"
    }</br>`;
    statsText += `Latency (ms): ${
      aggregatedStats.hasOwnProperty("currentRoundTripTime")
        ? numberFormat.format(aggregatedStats.currentRoundTripTime * 1000)
        : "N/A"
    }</br>`;

    let statsDiv = document.getElementById("stats");
    if (statsDiv) {
      statsDiv.innerHTML = statsText;
    }

    if (vars.getPrintInputs()) {
      if (aggregatedStats.timestampStart) {
        if (
          aggregatedStats.timestamp - aggregatedStats.timestampStart >
          nextPrintDuration
        ) {
          if (vars.getWs() && vars.getWs().readyState === WS_OPEN_STATE) {
            console.log(`-> SS: stats\n${JSON.stringify(aggregatedStats)}`);
            vars
              .getWs()
              .send(JSON.stringify({ type: "stats", data: aggregatedStats }));
          }
          nextPrintDuration += printInterval;
        }
      }
    }
  };

  vars.getWebRtcPlayerObj().aggregateStats(1 * 1000 /*Check every 1 second*/);

  //let displayStats = () => { webRtcPlayerObj.getStats( (s) => { s.forEach(stat => { console.log(JSON.stringify(stat)); }); } ); }
  //var displayStatsIntervalId = setInterval(displayStats, 30 * 1000);
}

export function onWebRtcIce(iceCandidate) {
  if (vars.getWebRtcPlayerObj())
    vars.getWebRtcPlayerObj().handleCandidateFromServer(iceCandidate);
}
