import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const API = (store) => {
  const getToken = () => {
    const token = store.loggedToken && store.loggedToken.accessToken;
    return token ? `Bearer ${token}` : "";
  };

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_APOLLO_URI,
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: getToken(),
      },
    };
  });

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
};

export default API;
