import { observable, action, decorate, computed } from 'mobx';
import { persist } from 'mobx-persist';
import QUADRANT_STATES from 'constants/quadrantStates';

/** Class representing global state of the app.
 * Store is decorated with mobx.
 * It uses the following mobx and mobx-persist decorators:
 * - observable: variable is set as observable (observers will be notified if the variable is updated)
 * - persist: variable will be persisted in a storage
 * - action(.bound): the function is enabled to update the store. Its "this" is bound to the store.
 * - computed: after an observable update, this function automatically updates a complex variable
 * @see {@link https://mobx.js.org/|mobx}
 * @see {@link https://www.npmjs.com/package/mobx-persist|mobx-persist}
 */
class Store {
  /**
   * initializes store.
   * @constructor
   */
  constructor() {
    this.feKey = '';
    this.feVendorKey = '';
    this.loginProjectName = '';
    this.projectName = null;
    this.projectDisplayName = '';
    this.projectId = null;
    this.logo = [];
    this.assetLogin = [];
    this.assetsUrl = null;
    this.currentNavTab = 0;
    this.snackbarOpen = false;
    this.snackbarMessage = undefined;
    this.snackbarError = false;
    this.realLocation = undefined;
    this.vendorEmail = '';
    this.vendorPassword = '';
    this.avatarMenu = false;
    this.errorModal = false;
    this.externalPdf = null;
    this.loggedUser = null;
    this.loggedToken = null;
    this.project_id = undefined;
    this.vendorLogo = [];
    this.pageTitles = undefined;
    this.floorPlanningConfig = {};
    this.appartments = undefined;
    this.availableApartments = undefined;
    this.filtersInfo = undefined;
    this.selectedSlot = undefined;
    this.selectedApartment = undefined;
    this.vendorInfo = undefined;
    this.clientInfo = undefined;
    this.isDesk = 0;
    this.currentView = undefined;
    this.selectedAptFloors = undefined;
    this.isRotate = false;
    this.aptLegend = {};

    this.resetAptLegend();
    window.sessionStorage.setItem('isRotate', JSON.stringify(this.isRotate));
  }

  /* ACTIONS */

  /**
   * updates tab.
   * @param {number} newTab the new selected tab (id)
   * @param {string} location
   */
  updateCurrentNavTab(newTab, location) {
    if (newTab !== this.currentNavTab) {
      this.currentNavTab = newTab;
    }
    this.setRealLocation(location);
  }

  /**
   * sets new location
   * @param {string} val
   */
  setRealLocation(val) {
    this.realLocation = val;
  }

  /**
   * @param {string} assetKey the key to choose fe assets (i.e. images)
   * @param {string} projectName the projectName to use in BE fetches
   * @param {string} displayName the name to display at login
   * @param {string} assetUrl the URL to retrieve images
   */
  setAssets(assetKey, projectName, projectId, displayName, assetsUrl) {
    this.feKey = assetKey;
    this.projectName = projectName;
    this.projectId = projectId;
    this.loginProjectName = displayName;
    this.assetsUrl = assetsUrl;
  }

  setAssetsByObject(obj) {
    if (obj) {
      this.feKey = obj.assetKey;
      this.feVendorKey = obj.feVendorKey;
      this.loginProjectName = obj.displayName;
      this.projectName = obj.name;
      this.projectDisplayName = obj.displayName;
      this.projectId = obj.id;
      this.project_id = obj.id;
      this.assetsUrl = obj.assetsUrl;
      this.assetLogin = obj.assetLogin;
      this.logo = obj.logo;
      this.vendorLogo = obj.vendorLogo;
      this.externalPdf = obj.pdf;
      this.pageTitles = obj.pageTitles;
      this.floorPlanningConfig = obj.floorPlanningConfig;
    }
  }

  setAppartments(value) {
    this.appartments = value;
  }

  setAvailableApartments(value) {
    this.availableApartments = value;
  }

  setVendorInfo(value) {
    this.vendorInfo = value;
  }

  setClientInfo(value) {
    this.clientInfo = value;
    if (this.vendorInfo && this.clientInfo) {
      this.setIsDesk(1);
    }
  }

  removeVendorInfo() {
    this.vendorInfo = null;
    this.removeClientInfo();
  }

  removeClientInfo() {
    this.clientInfo = null;
    this.setIsDesk(0);
  }

  setIsDesk(value) {
    this.isDesk = value;
    window.sessionStorage.setItem('isDesk', JSON.stringify(value));
  }

  setCurrentView(view) {
    this.currentView = view;
  }

  setSelectedAptFloors(floors) {
    this.selectedAptFloors = floors;
  }

  /**
   * sets the logged user
   * @param {Object} userData the logged user object
   */
  setLoggedUser(userData) {
    if (!this.loggedUser) {
      this.loggedUser = userData;
    }
  }

  /**
   * sets the logged token
   * @param {Object} tokenData the logged token object
   */
  setLoggedToken(tokenData) {
    if (!this.loggedToken) {
      this.loggedToken = tokenData;
    }
  }

  /**
   * sets the loging in vendor email
   * @param {string} val the email
   */
  updateVendorEmail(val) {
    if (this.vendorEmail !== val) {
      this.vendorEmail = val;
    }
  }

  /**
   * sets the loging in vendor password
   * @param {string} val the password
   */
  updateVendorPassword(val) {
    if (this.vendorPassword !== val) {
      this.vendorPassword = val;
    }
  }

  /**
   * open/close error modal
   * @param {boolean} val
   */
  setErrorModal(val) {
    this.errorModal = val;
  }

  /**
   * sets avatar's menu state (open/closed).
   * @param {boolean} val state of the menu
   */
  setAvatarMenu(val) {
    this.avatarMenu = val;
  }

  /**
   * resets all persisted data
   */
  logOut() {
    // this.feKey = "";
    // this.feVendorKey = "";
    // this.loginProjectName = "";
    // this.projectName = null;
    // this.projectId = null;
    // this.project_id = null;
    // this.logo = [];
    // this.assetLogin = [];
    // this.assetsUrl = null;
    // this.vendorLogo = [];
    // this.pageTitles = undefined;
    this.currentNavTab = 0;
    this.snackbarOpen = false;
    this.snackbarMessage = undefined;
    this.snackbarError = false;
    this.realLocation = undefined;
    this.vendorEmail = '';
    this.vendorPassword = '';
    this.avatarMenu = false;
    this.errorModal = false;
    this.externalPdf = null;
    this.loggedUser = null;
    this.loggedToken = null;
    this.appartments = [];
    this.availableApartments = null;
    this.filtersInfo = null;
    this.selectedSlot = null;
    this.selectedApartment = null;
    this.vendorInfo = null;
    this.clientInfo = null;
    this.isDesk = 0;
    this.currentView = null;
    this.selectedAptFloors = null;
  }

  setFiltersInfo(filters) {
    this.filtersInfo = filters;
  }

  setSelectedSlot(slot) {
    this.selectedSlot = slot;
  }

  setSelectedApartment(apartment) {
    this.selectedApartment = apartment;
  }

  setRotation() {
    this.isRotate = !this.isRotate;
    window.sessionStorage.setItem('isRotate', JSON.stringify(this.isRotate));
  }

  setFilteredAptStatuses(newStatuses) {
    this.filteredAptStatuses = newStatuses;
  }

  resetAptLegend() {
    this.aptLegend = {
      isVisible: false,
      isVisibleElem: QUADRANT_STATES.reduce((prev, curr) => {
        prev[`${curr}`] = false;
        return prev;
      }, {}),
    };
  }

  setAptLegend(newStatus) {
    this.aptLegend = newStatus;
  }

  /* COMPUTED */

  /**
   * computes the logged user full name
   */
  get loggedUserFullname() {
    if (this.loggedUser && this.loggedUser.firstName && this.loggedUser.lastName) {
      return `${this.loggedUser.firstName} ${this.loggedUser.lastName}`;
    } else if (this.loggedUser && this.loggedUser.id) {
      return this.loggedUser.id;
    } else {
      return '-';
    }
  }
}

decorate(Store, {
  feKey: [persist, observable],
  feVendorKey: [persist, observable],
  loginProjectName: [persist, observable],
  projectName: [persist, observable],
  projectDisplayName: [persist, observable],
  projectId: [persist, observable],
  project_id: [persist, observable],
  assetsUrl: [persist, observable],
  assetLogin: [persist('list'), observable],
  logo: [persist('list'), observable],
  vendorLogo: [persist('list'), observable],
  externalPdf: [persist, observable],
  pageTitles: [persist('object'), observable],
  floorPlanningConfig: [persist('object'), observable],
  loggedUser: [persist('object'), observable],
  loggedToken: [persist('object'), observable],
  appartments: [persist('list'), observable],
  availableApartments: [persist('list'), observable],
  filtersInfo: [persist('object'), observable],
  selectedSlot: [persist('object'), observable],
  selectedApartment: [persist('object'), observable],
  vendorInfo: [persist('object'), observable],
  clientInfo: [persist('object'), observable],
  isDesk: [persist, observable],
  isRotate: observable,
  currentView: observable,
  selectedAptFloors: observable,
  aptLegend: observable,
  updateCurrentNavTab: action.bound,
  setRealLocation: action.bound,
  vendorEmail: observable,
  vendorPassword: observable,
  setAssets: action.bound,
  setAppartments: action.bound,
  setAvailableApartments: action.bound,
  setSelectedSlot: action.bound,
  setSelectedApartment: action.bound,
  setAssetsByObject: action.bound,
  updateVendorEmail: action.bound,
  updateVendorPassword: action.bound,
  setAvatarMenu: action.bound,
  setLoggedUser: action.bound,
  setLoggedToken: action.bound,
  logOut: action.bound,
  setErrorModal: action.bound,
  setFiltersInfo: action.bound,
  setVendorInfo: action.bound,
  removeVendorInfo: action.bound,
  setClientInfo: action.bound,
  removeClientInfo: action.bound,
  setCurrentView: action.bound,
  setSelectedAptFloors: action.bound,
  setRotatation: action.bound,
  setFilteredAptStatuses: action.bound,
  setAptLegend: action.bound,
  loggedUserFullname: computed,
});

export default Store;
