import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useStore } from 'store/storeUtils';

import { Dialog, DialogContent, InputBase, ButtonBase } from '@material-ui/core';

import 'styles/rename-summary-dialog.scss';

const RenameSummaryDialog = observer(({ openDialog, setOpenDialog, execAction }) => {
  const store = useStore();

  const [name, setName] = React.useState(null);
  const onChangeName = (e) => {
    setName(e.target.value);
  };
  const renameSummary = () => {
    setOpenDialog(!openDialog);
    execAction(name);
    setName(null);
  };

  return (
    <Dialog
      open={openDialog}
      onClose={() => setOpenDialog(!openDialog)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      style={store.isRotate ? { transform: 'rotate(180deg)' } : {}}
    >
      <DialogContent>
        <img
          src={require('assets/images/icons/close.svg')}
          alt='close'
          className='close-icon'
          onClick={() => setOpenDialog(!openDialog)}
        />
        <div className='dialog-summary'>
          <span className='dialog-summary-title'>
            Vuoi personalizzare il nome del tuo preventivo?
          </span>
          <span className='dialog-summary-subtitle'>Potrai modificarlo in qualsiasi momento</span>
          <InputBase className='dialog-summary-input' value={name || ''} onChange={onChangeName} />
          <ButtonBase className='dialog-summary-confirm-button' onClick={renameSummary}>
            Conferma
          </ButtonBase>
        </div>
      </DialogContent>
    </Dialog>
  );
});

RenameSummaryDialog.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  execAction: PropTypes.func,
};

RenameSummaryDialog.defaultProps = {
  openDialog: false,
};

export default RenameSummaryDialog;
