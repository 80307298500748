import React, { useState } from "react";

import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

const VKeyboard = (props) => {
  const [layoutName, setLayoutName] = useState("default");
  const [keyboard, setKeyboard] = useState(null);
  const [shift, setShift] = useState(false);

  const onChange = (input) => {
    /*if (props.clearInput) {
      this.keyboard.setInput('');
      props.setInput('');
      props.setClearInput(false);
    } else {
      props.setInput(props.input + input);
    }*/

    props.setInput(props.input + input);
    props.handleChange(input);
    if (shift) {
      setLayoutName("default");
      setShift(false);
    }
  };

  const onKeyPress = (button) => {
    if (button === "{clear}") {
      props.clearInput();
    } else {
      if (button.length === 1) props.setInput(props.input + button);
    }

    switch (button) {
      case "{tab}":
        handleTab();
        break;
      case "{enter}":
        if (!props.newLineOnEnter) {
          props.handleSubmit();
        }
        break;
      case "{clear}":
        handleClear();
        break;
      case "{shift}":
        handleShift();
        break;
      case "{lock}":
        handleLock();
        break;
      case "{bksp}":
        handleBksp();
        break;
      default:
    }
  };

  const handleTab = () => {
    const actualInput = props.selectInput;
    props.selectInputList &&
      props.selectInputList.forEach((input, index) => {
        if (
          input === actualInput &&
          index === props.selectInputList.length - 1
        ) {
          props.setSelectInput(props.selectInputList[0]);
        } else if (input === actualInput) {
          props.setSelectInput(props.selectInputList[index + 1]);
        }
      });
  };

  const handleLock = () => {
    setLayoutName(layoutName === "default" ? "shift" : "default");
  };

  const handleShift = () => {
    setLayoutName(layoutName === "default" ? "shift" : "default");
    setShift(!shift);
  };

  const handleBksp = () => {
    props.setInput(props.input.slice(0, -1));
  };

  const handleClear = () => {
    if (keyboard) {
      keyboard.clearInput();
      props.setInput("");
    }
  };

  return (
    <>
      <Keyboard
        keyboardRef={(r) => setKeyboard(r)}
        layoutName={layoutName}
        onChange={(input) => onChange(input)}
        onKeyPress={(button) => onKeyPress(button)}
        inputName={props.selectInput}
        newLineOnEnter={props.newLineOnEnter ? props.newLineOnEnter : false}
        tabCharOnTab={false}
        layout={props.layout}
        display={props.display}
      />
    </>
  );
};

export default VKeyboard;
