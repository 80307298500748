import React from 'react';
import PropTypes from 'prop-types';

import { useStore } from 'store/storeUtils';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';

import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { useDeskMediaQuery } from 'utils/DeskMediaQuery';

import 'styles/view-selection.scss';

const ViewSelection = observer((props) => {
  const { views, isTour, active, onChangeFocus } = props;
  const store = useStore();

  const matches = useDeskMediaQuery();

  const [open, setOpen] = React.useState(false);
  const popperRef = React.useRef(null);

  const id = open ? 'transitions-popper' : undefined;

  const onClickAway = () => setOpen(false);

  const [selection, setSelection] = React.useState(process.env.REACT_APP_DEFAULT_VIEW);
  store.setCurrentView(selection);
  const handleClick = () => {
    setOpen(!open);
  };

  function handleOnListClick(item) {
    window.emitUIInteraction({ focus: item });
    setSelection(item);
    store.setCurrentView(item);
    setOpen(!open);
    if (!active) {
      onChangeFocus();
    }
  }

  function isItemInSelection(item) {
    if (selection === item) {
      return true;
    }
    return false;
  }

  const viewsLabels = [
    {
      id: 'E01_Vista01',
      name: 'Edificio Ambra - vista generica',
    },
    {
      id: 'E01_Vista02',
      name: 'Edificio Ambra - dettaglio ingresso',
    },
    {
      id: 'E01_Vista03',
      name: 'Edificio Ambra - dettaglio facciata',
    },
    {
      id: 'E01_Vista04',
      name: 'Edificio Ambra – dettaglio edificio',
    },
    {
      id: 'E02_vista01',
      name: 'Edificio Oro - vista generica',
    },
    {
      id: 'E02_vista02',
      name: 'Edificio Oro - dettaglio percorsi',
    },
    {
      id: 'E02_vista03',
      name: 'Edificio Oro - dettaglio parco 1',
    },
    {
      id: 'E02_vista04',
      name: 'Edificio Oro - dettaglio parco 2',
    },
    {
      id: 'E02_vista05',
      name: 'Edificio Oro - dettaglio facciata',
    },
    {
      id: 'E02_vista06',
      name: 'Edificio Oro - dettaglio verde',
    },
  ];

  const moveElement = (array) => {
    let finalIndex = 0;
    const filteredElements = array.filter((item) => item.toLowerCase().includes('vista01'));
    for (let fe of filteredElements) {
      const elemIndex = array.findIndex((el) => el === fe);
      array.splice(finalIndex, 0, array.splice(elemIndex, 1)[0]);
      finalIndex++;
    }
    return array;
  };

  const getViewLabel = (view) => {
    return viewsLabels.find((vl) => vl.id === view)?.name;
  };

  React.useEffect(() => {
    autorun(() => {
      setSelection(store.currentView);
    });
  }, [store.currentView]);

  return (
    <div className='view-selection' ref={popperRef}>
      <span className='view-selection-title'>
        {isTour ? "Scegli l’edificio..." : 'Vivi l’esperienza, fai un tour del progetto...'}
      </span>
      <div className='view-selection-content'>
        <img
          className='view-selection-content-icon'
          src={require('assets/images/icons/building-view.svg')}
          alt='building-view'
        />
        <div className='view-selection-content-box' onClick={handleClick}>
          <span>{active ? getViewLabel(selection) : 'Seleziona vista'}</span>
          <img
            className={open ? 'rotate-arrow' : ''}
            src={require('assets/images/icons/down-arrow.svg')}
            alt='down-arrow'
          />
        </div>
      </div>
      <Popper
        id={id}
        open={open}
        anchorEl={popperRef.current}
        placement={matches ? 'bottom-end' : 'top-end'}
        transition
        popperOptions={{
          modifiers: {
            offset: {
              enabled: true,
              offset: '0px, 0px',
            },
          },
        }}
        className='popper-container'
      >
        <ClickAwayListener onClickAway={onClickAway}>
          <div className={`popper-view ${store.isRotate ? 'rotate-180' : ''}`}>
            <ul className='popper-list'>
              {views.map((view, i) => (
                <li className='popper-list-item' key={i}>
                  <button
                    className={isItemInSelection(view) ? 'selected' : ''}
                    type='button'
                    onClick={() => handleOnListClick(view)}
                  >
                    <span>{getViewLabel(view)}</span>
                    {/* <span>{isItemInSelection(view) && <CheckIcon />}</span> */}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  );
});

ViewSelection.propTypes = {
  isTour: PropTypes.bool,
  views: PropTypes.array,
  active: PropTypes.bool,
  onChangeFocus: PropTypes.func,
};

ViewSelection.defaultProps = {
  isTour: false,
  views: [],
};

export default ViewSelection;
