import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import CurrencyFormatter from 'utils/CurrencyFormatter';
import moment from 'utils/MyMoment';
import { capitalize } from '@material-ui/core';
import { getPromoName } from 'utils/PromoUtils';

const styles = StyleSheet.create({
  pageStyle: {
    paddingTop: 16,
    paddingHorizontal: 40,
    paddingBottom: 56,
  },
  tableStyle: {
    display: 'table',
    width: 'auto',
  },
  tableRowStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tableCellStyle: {
    textAlign: 'left',
    margin: 5,
    fontSize: 12,
  },
  dividerStyle: {
    borderBottomColor: 'black',
    borderBottomWidth: 1,
    marginVertical: 10,
  },
});

const TableRow = ({ row }) => {
  return (
    <View style={styles.tableRowStyle} fixed>
      {row.map((column, i) => {
        return (
          <View key={i} style={{ width: `${100 / row.length}%` }}>
            <Text
              style={{
                ...(column.style ? column.style : styles.tableCellStyle),
                textAlign:
                  i === 0 ? 'left' : row.length === 2 ? 'right' : i === 1 ? 'center' : 'right',
              }}
            >
              {column.value}
            </Text>
          </View>
        );
      })}
    </View>
  );
};

const Divider = () => {
  return <View style={styles.dividerStyle} />;
};

const Table = ({ rows }) => {
  return (
    <View style={styles.tableStyle}>
      {rows.map((row, i) => (
        <TableRow row={row} key={i} />
      ))}
    </View>
  );
};

const SummaryPdf = ({ data, defaultData }) => {
  const getFormattedSurface = (surface) => (+surface).toFixed(0);
  const getFormattedPrice = (price) => CurrencyFormatter(price);

  return (
    <Document>
      <Page style={styles.pageStyle} size='A4' orientation='portrait'>
        <Table
          rows={[
            [
              {
                value: 'TIPOLOGIA:',
              },
              {
                value: capitalize(data.info_apartment.Name || defaultData.tipologia),
              },
            ],
            [
              {
                value: 'EDIFICIO:',
              },
              {
                value: capitalize(data.info_apartment.AIn_Edificio || defaultData.edificio),
              },
            ],
            [
              {
                value: 'QUADRANTE:',
              },
              {
                value: capitalize(data.info_apartment.AIn_Quadrante || defaultData.quadrante),
              },
            ],
            [
              {
                value: 'PIANO:',
              },
              {
                value: data.info_apartment.AIn_Piano || defaultData.piano,
              },
            ],
          ]}
        />
        <Divider />
        <Table
          rows={[
            [
              {
                value: 'Superficie Totale:',
              },
              {
                value: data.preventivo.Superficie_Lorda_Totale?.value
                  ? 'mq ' + getFormattedSurface(data.preventivo.Superficie_Lorda_Totale.value)
                  : defaultData.superficieTotale,
              },
            ],
            [
              {
                value: 'di cui Loggia:',
              },
              {
                value: data.info_apartment.AIn_SuperficieTerrazzo
                  ? 'mq ' + getFormattedSurface(data.info_apartment.AIn_SuperficieTerrazzo)
                  : defaultData.superficieLoggia,
              },
            ],
          ]}
        />
        <Divider />
        <Table
          rows={[
            [
              {
                value: 'Valore Unità Immobiliare:',
              },
              {
                value: data.preventivo.ValoreUnitaImmobiliare?.value
                  ? getFormattedPrice(data.preventivo.ValoreUnitaImmobiliare.value)
                  : defaultData.valoreUnitaImmobiliare,
              },
            ],
            [
              {
                value: `${getPromoName()}:`,
              },
              {
                value: data.preventivo.specialeevento?.value
                  ? getFormattedPrice(data.preventivo.specialeevento.value)
                  : defaultData.specialPrice,
              },
            ],
            [
              {
                value: 'Prezzo Promo:',
              },
              {
                value: data.preventivo.Prezzo_Promo?.value
                  ? getFormattedPrice(data.preventivo.Prezzo_Promo.value)
                  : defaultData.prezzoPromo,
              },
            ],
            [
              {
                value: 'Provvigione:',
              },
              {
                value: 'nessuna!',
              },
              {
                value: data.preventivo.provvigione?.value
                  ? getFormattedPrice(data.preventivo.provvigione.value)
                  : defaultData.provvigione,
                style: { ...styles.tableCellStyle, textDecoration: 'line-through' },
              },
            ],
            [
              {
                value: `Opera d'Arte: ${defaultData.operaArte.titolo} *`,
              },
              {
                value: 'in omaggio',
              },
              {
                // value: data.preventivo.opera?.value
                //   ? getFormattedPrice(data.preventivo.opera.value)
                //   : getFormattedPrice(defaultData.operaArte.valore),
                value: getFormattedPrice(defaultData.operaArte.valore),
                style: { ...styles.tableCellStyle, textDecoration: 'line-through' },
              },
            ],
            [
              {
                value: 'Valore Cantina:',
              },
              {
                value: 'inclusa!',
              },
              {
                value: data.preventivo.cantina?.value
                  ? getFormattedPrice(data.preventivo.cantina.value)
                  : getFormattedPrice(defaultData.valoreCantina),
                style: { ...styles.tableCellStyle, textDecoration: 'line-through' },
              },
            ],
          ]}
        />
        <Divider />
        <Table
          rows={[
            [
              {
                value: 'Cauzione Infruttifera:',
              },
              {
                value: data.preventivo.cauzione_infruttifera?.value
                  ? getFormattedPrice(data.preventivo.cauzione_infruttifera.value)
                  : defaultData.cauzioneInfruttifera,
              },
            ],
            ...(data.preventivo.listaAnticipi?.value
              ? data.preventivo.listaAnticipi.value.map((anticipo) => [
                  {
                    value: `${capitalize(anticipo.tipologia)}:`,
                  },
                  {
                    value: `entro il ${moment.unix(anticipo.data).format('DD/MM/YYYY')}`,
                  },
                  {
                    value: getFormattedPrice(anticipo.valore),
                  },
                ])
              : defaultData.listaAnticipi.map((anticipo) => [
                  {
                    value: `${capitalize(anticipo.tipologia)}:`,
                  },
                  {
                    value: anticipo.valore,
                  },
                ])),
            [
              {
                value: `Buono: ${defaultData.buono.nome}`,
              },
              {
                value: data.preventivo.Buono_La_tua_Casa_Straordinaria?.value
                  ? getFormattedPrice(data.preventivo.Buono_La_tua_Casa_Straordinaria.value)
                  : defaultData.buono.valore,
              },
            ],
          ]}
        />
        <Divider />
        <Table
          rows={[
            [
              {
                value: 'Spese Allacciamenti accatastamenti:',
              },
              {
                value: data.preventivo.Valore_allacciamenti_accatastamenti?.value
                  ? getFormattedPrice(data.preventivo.Valore_allacciamenti_accatastamenti.value)
                  : defaultData.speseAllacciamentiAccatastamenti,
              },
            ],
          ]}
        />
        <Divider />
        <Table
          rows={[
            [
              {
                value: 'Mutuo:',
              },
              {
                value: data.preventivo.Mutuo?.value
                  ? getFormattedPrice(data.preventivo.Mutuo.value)
                  : defaultData.mutuo,
              },
            ],
            [
              {
                value: 'Rata Tasso Variabile (0.8%):',
              },
              {
                value: data.preventivo.Rata_Variabile?.value
                  ? getFormattedPrice(data.preventivo.Rata_Variabile.value)
                  : defaultData.rataTassoVariabile,
              },
            ],
            [
              {
                value: 'Rata Tasso Fisso (1.2%):',
              },
              {
                value: data.preventivo.Rata_Fissa?.value
                  ? getFormattedPrice(data.preventivo.Rata_Fissa.value)
                  : defaultData.rataTassoFisso,
              },
            ],
          ]}
        />
        <Text
          style={{
            ...styles.tableCellStyle,
            fontSize: 10,
            marginTop: 10,
          }}
        >
          * Edizione inaugurale, autografata e a tiratura limitata, offerta solo per le prime dieci
          proposte di acquisto (ad esclusione dei bilocali) valide e fatte entro il 31.07.2021.
        </Text>
      </Page>
    </Document>
  );
};

export default SummaryPdf;
