export const FULL_KEYBOARD_LAYOUT = {
  default: [
    "\\ 1 2 3 4 5 6 7 8 9 0 ' ì {bksp} {clear}",
    "{tab} q w e r t y u i o p è +",
    "{lock} a s d f g h j k l ò à ù {enter}",
    "{shift} < z x c v b n m , . - {shift}",
    "@ .com .net {space} .it .eu",
  ],
  shift: [
    '| ! " £ $ % & / ( ) = ? ^ {bksp} {clear}',
    "{tab} Q W E R T Y U I O P é *",
    "{lock} A S D F G H J K L ç ° § {enter}",
    "{shift} > Z X C V B N M ; : _ {shift}",
    "@ .com .net {space} .it .eu",
  ],
};
export const FULL_KEYBOARD_DISPLAY = {
  "{clear}": "Clear",
  "{bksp}": "⌫",
  "{tab}": "⇥",
  "{lock}": "⇪",
  "{enter}": "Enter ↵",
  "{shift}": "⇧",
  "{space}": "  ",
};

export const NUMERIC_KEYBOARD_LAYOUT = {
  default: ["1 2 3", "4 5 6", "7 8 9", "0 {clear} {bksp}"],
};
export const NUMERIC_KEYBOARD_DISPLAY = {
  "{clear}": "Clear",
  "{bksp}": "⌫",
  "{tab}": "⇥",
};
