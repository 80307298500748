import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useStore } from 'store/storeUtils';

import Button from '@material-ui/core/Button';

import 'styles/drawer-header.scss';

const DrawerHeader = observer(() => {
  const store = useStore();

  const backToLogin = (step) => {
    if (step === 'vendor') store.removeVendorInfo();
    if (step === 'client') store.removeClientInfo();
    window.ws.close();
    window.location.href = '/login';
  };

  return (
    <div className='logo-promo-container'>
      <img src={require('assets/images/twin-towers_logo_black.svg')} alt='logo' className='logo' />
      <>
        {store.isDesk === 1 ? (
          <div className='back-to-login-buttons'>
            <Button
              variant='contained'
              className='vendor-button'
              onClick={() => backToLogin('vendor')}
            >
              <img
                src={require('assets/images/icons/new-vendor.svg')}
                alt='new-vendor'
                className='vendor-button-icon'
              />
              <div className='vendor-button-message'>
                <span>Cambia</span>
                <span className='highlight'>venditore</span>
              </div>
            </Button>
            <Button
              variant='contained'
              className='client-button'
              onClick={() => backToLogin('client')}
            >
              <img
                src={require('assets/images/icons/client.svg')}
                alt='client'
                className='client-button-icon'
              />
              <div className='client-button-message'>
                <span>Cambia</span>
                <span className='highlight'>cliente</span>
              </div>
            </Button>
          </div>
        ) : (
          ''
        )}
      </>
    </div>
  );
});

DrawerHeader.propTypes = {
  toggleDrawer: PropTypes.bool,
  handleClickExit: PropTypes.func,
  popperRef: PropTypes.any,
  openExitApp: PropTypes.bool,
  setOpenExitApp: PropTypes.func,
};

DrawerHeader.defaultProps = {
  toggleDrawer: false,
  popperRef: null,
  openExitApp: false,
};

export default DrawerHeader;
