import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStore } from 'store/storeUtils';
import { observer } from 'mobx-react';

import { Grid, Button } from '@material-ui/core';

import FilterSelection from 'components/specific/FilterSelection';

import convertPrice from 'utils/ApartmentsDefaultPrice';

const FiltersMenu = observer(({ backToLogin, resetFilters, dataList, filters, setFilter }) => {
  const store = useStore();
  const [resetFiltersVisible, setResetFiltersVisible] = React.useState(false);

  useEffect(() => {
    setResetFiltersVisible(!!filters.dimensione);
  }, [filters]);

  const capitalizeTitle = (title) => {
    return title.charAt(0).toUpperCase() + title.slice(1);
  };

  const exitApp = () => {
    if (store.isDesk === 1) {
      window.ws.close();
      // store.removeVendorInfo();
      const ws = window.sessionStorage.getItem('customWsUri');
      window.location.href = `${process.env.REACT_APP_NEUROSALES_URL}${
        process.env.REACT_APP_NEUROSALES_URL_WS_PARAMS
      }${ws ? ws : process.env.REACT_APP_WS_BASEURL}`;
    } else {
      window.location.href = 'https://lambratetwinpalace.com/';
    }
  };

  return (
    <div className='filters-menu'>
      <div className='filters-menu-content'>
        <div className='filters-menu-content-header'>
          <img
            src={require('assets/images/logo-half-top.svg')}
            alt='logo-half-top'
            onClick={exitApp}
          />
          {store.isDesk ? (
            <div className='back-to-login-buttons'>
              <Button
                variant='contained'
                className='vendor-button'
                onClick={() => backToLogin('vendor')}
              >
                <img
                  src={require('assets/images/icons/new-vendor.svg')}
                  alt='new-vendor'
                  className='vendor-button-icon'
                />
                <div className='vendor-button-message'>
                  <span>Cambia</span>
                  <span className='highlight'>venditore</span>
                </div>
              </Button>
              <Button
                variant='contained'
                className='client-button'
                onClick={() => backToLogin('client')}
              >
                <img
                  src={require('assets/images/icons/client.svg')}
                  alt='client'
                  className='client-button-icon'
                />
                <div className='client-button-message'>
                  <span>Cambia</span>
                  <span className='highlight'>cliente</span>
                </div>
              </Button>
            </div>
          ) : (
            ''
          )}
        </div>

        <div className='filters-menu-content-main'>
          <p className='filters-menu-content-main-title'>
            Scegli{' '}
            <span className='filters-menu-content-main-title-highlight'>la tua nuova casa</span>
          </p>
          <p className='filters-menu-content-main-body'>
            Scegli e visualizza gli appartamenti in base alla tue preferenze
          </p>
          <img
            src={require('assets/images/icons/triple-down-arrow.svg')}
            alt='triple-down-arrow'
            className='filters-menu-content-main-icon'
          />
          <Grid container alignItems='center' direction='row'>
            <Grid item xs={12}>
              <Button
                variant='contained'
                className={`filter-container-reset-button ${!resetFiltersVisible ? 'hidden' : ''}`}
                onClick={resetFilters}
              >
                Azzera filtri
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            justify='center'
            alignItems='center'
            direction='row'
            className='filters-menu-content-main-selection'
          >
            {['tipologia', 'dimensione'].map((key, i) => (
              <Grid key={i} item xs={12} style={{ position: 'relative' }}>
                <FilterSelection
                  title={capitalizeTitle(key)}
                  data={dataList[key]}
                  value={filters[key]}
                  filter={key}
                  setFilter={setFilter}
                  filterNumber={i + 1}
                  disabled={dataList[key]?.length > 0 ? false : true}
                />
              </Grid>
            ))}
          </Grid>
          {filters['dimensione'] ? (
            <Grid container alignItems='center' direction='row'>
              <Grid item xs={12} className='filters-menu-content-main-min-price'>
                <span className='filters-menu-content-main-min-price-value'>
                  A partire da{' '}
                  {convertPrice(filters['dimensione'].min_price, {
                    tipologia: filters['tipologia'].Name,
                    dimensione: filters['dimensione'].Name,
                  })}
                </span>
              </Grid>
            </Grid>
          ) : (
            ''
          )}
        </div>

        <div className='filters-menu-content-footer'>
          <span className='filters-menu-content-footer-welcome'>Welcome on</span>
          <img src={require('assets/images/logo-half-bottom.svg')} alt='logo-half-bottom' />
        </div>
      </div>
    </div>
  );
});

FiltersMenu.propTypes = {
  backToLogin: PropTypes.func,
  resetFilters: PropTypes.func,
  dataList: PropTypes.object,
  filters: PropTypes.object,
  setFilter: PropTypes.func,
};

FiltersMenu.defaultProps = {
  dataList: {},
  filters: {},
};

export default FiltersMenu;
