import React from 'react';
import PropTypes from 'prop-types';
import { useStore } from 'store/storeUtils';
import { observer } from 'mobx-react';

import { Dialog, DialogContent } from '@material-ui/core';

import 'styles/planimetry-dialog.scss';

const PlanimetryDialog = observer(({ openModal, setOpenModal, apartmentData }) => {
  const store = useStore();

  const getApartmentName = () => {
    if (apartmentData?.info_apartment) return apartmentData?.info_apartment.Name;
    if (apartmentData?.Name) return apartmentData?.Name;
  };

  return (
    <>
      {apartmentData ? (
        <Dialog
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          maxWidth='lg'
          className='planimetry-dialog'
        >
          <DialogContent style={store.isRotate ? { transform: 'rotate(180deg)' } : {}}>
            <img
              src={require('assets/images/icons/close.svg')}
              alt='close'
              className='planimetry-dialog-close-icon'
              onClick={() => setOpenModal(false)}
            />
            <img
              src={`https://planimetrieprogetti.abitareinspa.com/${process.env.REACT_APP_PROJECT.toLowerCase()}/hd/${getApartmentName()
                .toLowerCase()
                .replaceAll(' ', '_')}.jpg`}
              alt='apartment-planimetry'
              className='planimetry-dialog-image'
            />
          </DialogContent>
        </Dialog>
      ) : (
        ''
      )}
    </>
  );
});

PlanimetryDialog.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  apartmentData: PropTypes.object,
};

PlanimetryDialog.defaultProps = {
  openModal: false,
  apartmentData: {},
};

export default PlanimetryDialog;
