import React from 'react';

import { useStore } from 'store/storeUtils';
import { observer } from 'mobx-react';

import InfoBooking from 'components/common/InfoBooking';
import QUADRANT_STATES from 'constants/quadrantStates';

import 'styles/upper-lower-bands.scss';

const DeskLowerBand = observer(() => {
  const store = useStore();
  return (
    <div className='lower-band'>
      <div className='lower-band-filters-container'>
        <div className='lower-band-filters-container-title'>
          <span>Stai visualizzando gli appartamenti</span>
          <span>con le seguenti caratteristiche.</span>
        </div>
        <div className='lower-band-filters-container-info'>
          <img src={require('assets/images/icons/building-circled.svg')} alt='building-circled' />
          <div className='lower-band-filters-container-info-names'>
            {/* <span>
              Modello: {store.filtersInfo?.modello ? store.filtersInfo.modello.Name : '-'}
            </span> */}
            <span>
              Tipologia: {store.filtersInfo?.tipologia ? store.filtersInfo.tipologia.Name : '-'}
            </span>
            <span>
              Dimensione: {store.filtersInfo?.dimensione ? store.filtersInfo.dimensione.Name : '-'}
            </span>
          </div>
        </div>
      </div>
      <div
        className='lower-band-apartments-legend'
        style={store.aptLegend.isVisible ? {} : { visibility: 'hidden' }}
      >
        <div className='lower-band-apartments-legend-header'>
          <div className='lower-band-apartments-legend-header-title'>
            <span>Legenda colori appartamenti.</span>
            <span>Guarda quelli disponibili.</span>
          </div>
          <div className='lower-band-apartments-legend-header-alert'>
            <InfoBooking color='blue' />
          </div>
        </div>
        <div className='lower-band-apartments-legend-content'>
          {QUADRANT_STATES.map((aptType, i) => (
            <div
              key={i}
              className='lower-band-apartments-legend-content-item'
              style={store.aptLegend.isVisibleElem[aptType] ? {} : { display: 'none' }}
            >
              <img
                src={require(`assets/images/icons/${
                  aptType === 'disponibili'
                    ? 'available-building'
                    : aptType === 'opzionati'
                    ? 'optioned-building'
                    : aptType === 'altriOpzionati'
                    ? 'other-optioned-building'
                    : 'unavailable-building'
                }-black.svg`)}
                alt={`${
                  aptType === 'disponibili'
                    ? 'available-building'
                    : aptType === 'opzionati'
                    ? 'optioned-building'
                    : aptType === 'altriOpzionati'
                    ? 'other-optioned-building'
                    : 'unavailable-building'
                }-black`}
              />
              <div className='lower-band-apartments-legend-content-item-title'>
                <span>
                  {
                  aptType === 'disponibili'
                    ? store.filtersInfo?.tipologia && store.filtersInfo?.dimensione ? store.filtersInfo?.tipologia?.Name+" "+store.filtersInfo?.dimensione?.Name : '-'
                    : aptType === 'opzionati'
                    ? store.filtersInfo?.tipologia && store.filtersInfo?.dimensione ? store.filtersInfo?.tipologia?.Name+" "+store.filtersInfo?.dimensione?.Name : '-'
                    : aptType === 'altriOpzionati'
                    ? 'Altre Soluzioni'
                    : store.filtersInfo?.tipologia && store.filtersInfo?.dimensione ? store.filtersInfo?.tipologia?.Name+" "+store.filtersInfo?.dimensione?.Name : '-'
                  }
                </span>
                <span>
                  {aptType === 'disponibili'
                    ? 'Disponibili'
                    : aptType === 'opzionati'
                    ? 'Con Proposta'
                    : aptType === 'altriOpzionati'
                    ? 'Con proposta'
                    : 'Non selezionabili'}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default DeskLowerBand;
