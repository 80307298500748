//! Overlay & AFK & Freeze Frame

import * as vars from "./Variables";
import {
  setupNormalizeAndQuantize,
  connect,
  requestQualityControl,
  emitUIInteraction,
} from "./Utils";

var lastTimeResized = new Date().getTime();
var resizeTimeout;

// Optionally detect if the user is not interacting (AFK) and disconnect them.
var afk = {
  enabled: false, // Set to true to enable the AFK system.
  warnTimeout: 120, // The time to elapse before warning the user they are inactive.
  closeTimeout: 10, // The time after the warning when we disconnect the user.

  active: false, // Whether the AFK system is currently looking for inactivity.
  overlay: undefined, // The UI overlay warning the user that they are inactive.
  warnTimer: undefined, // The timer which waits to show the inactivity warning overlay.
  countdown: 0, // The inactivity warning overlay has a countdown to show time until disconnect.
  countdownTimer: undefined, // The timer used to tick the seconds shown on the inactivity warning overlay.
};

function setOverlay(htmlClass, htmlElement, onClickFunction) {
  var videoPlayOverlay = document.getElementById("videoPlayOverlay");
  if (!videoPlayOverlay) {
    var playerDiv = document.getElementById("player");
    videoPlayOverlay = document.createElement("div");
    videoPlayOverlay.id = "videoPlayOverlay";
    playerDiv.appendChild(videoPlayOverlay);
  }

  // Remove existing html child elements so we can add the new one
  while (videoPlayOverlay.lastChild) {
    videoPlayOverlay.removeChild(videoPlayOverlay.lastChild);
  }

  if (htmlElement) videoPlayOverlay.appendChild(htmlElement);

  if (onClickFunction) {
    videoPlayOverlay.addEventListener("click", function onOverlayClick(event) {
      onClickFunction(event);
      videoPlayOverlay.removeEventListener("click", onOverlayClick);
    });
  }

  // Remove existing html classes so we can set the new one
  var cl = videoPlayOverlay.classList;
  for (var i = cl.length - 1; i >= 0; i--) {
    cl.remove(cl[i]);
  }

  videoPlayOverlay.classList.add(htmlClass);
}

export function showConnectOverlay() {
  var startText = document.createElement("div");
  startText.id = "playButton";
  startText.innerHTML = "Click to start";

  setOverlay("clickableState", startText, () => {
    connect();
    startAfkWarningTimer();
  });
}

export function showTextOverlay(text) {
  var textOverlay = document.createElement("div");
  textOverlay.id = "messageOverlay";
  textOverlay.innerHTML = text ? text : "";
  setOverlay("textDisplayState", textOverlay);
}

export function showPlayOverlay() {
  var img = document.createElement("img");
  img.id = "playButton";
  img.src = "/images/Play.png";
  img.alt = "Start Streaming";
  setOverlay("clickableState", img, () => {
    if (vars.getWebRtcPlayerObj()) vars.getWebRtcPlayerObj().video.play();

    requestQualityControl();

    showFreezeFrameOverlay();
    hideOverlay();
  });
  vars.setShouldShowPlayOverlay(false);
}

function updateAfkOverlayText() {
  afk.overlay.innerHTML =
    "<center>No activity detected<br>Disconnecting in " +
    afk.countdown +
    " seconds<br>Click to continue<br></center>";
}

function showAfkOverlay() {
  // Pause the timer while the user is looking at the inactivity warning overlay.
  stopAfkWarningTimer();

  // Show the inactivity warning overlay.
  afk.overlay = document.createElement("div");
  afk.overlay.id = "afkOverlay";
  setOverlay("clickableState", afk.overlay, () => {
    // The user clicked so start the timer again and carry on.
    hideOverlay();
    clearInterval(afk.countdownTimer);
    startAfkWarningTimer();
  });

  afk.countdown = afk.closeTimeout;
  updateAfkOverlayText();

  if (
    vars.getInputOptions().controlScheme ===
    vars.getControlSchemeType().LockedMouse
  ) {
    document.exitPointerLock();
  }

  afk.countdownTimer = setInterval(function () {
    afk.countdown--;
    if (afk.countdown === 0) {
      // The user failed to click so disconnect them.
      hideOverlay();
      vars.getWs().close();
    } else {
      // Update the countdown message.
      updateAfkOverlayText();
    }
  }, 1000);
}

function hideOverlay() {
  setOverlay("hiddenState");
}

// Start a timer which when elapsed will warn the user they are inactive.
function startAfkWarningTimer() {
  afk.active = afk.enabled;
  resetAfkWarningTimer();
}

// Stop the timer which when elapsed will warn the user they are inactive.
function stopAfkWarningTimer() {
  afk.active = false;
}

// If the user interacts then reset the warning timer.
export function resetAfkWarningTimer() {
  if (afk.active) {
    clearTimeout(afk.warnTimer);
    afk.warnTimer = setTimeout(function () {
      showAfkOverlay();
    }, afk.warnTimeout * 1000);
  }
}

function resizePlayerStyleToFillWindow(playerElement) {
  let videoElement = playerElement.getElementsByTagName("VIDEO");

  // Fill the player display in window, keeping picture's aspect ratio.
  let windowAspectRatio = window.innerHeight / window.innerWidth;
  let playerAspectRatio =
    playerElement.clientHeight / playerElement.clientWidth;
  // We want to keep the video ratio correct for the video stream
  let videoAspectRatio = videoElement.videoHeight / videoElement.videoWidth;
  if (isNaN(videoAspectRatio)) {
    //Video is not initialised yet so set playerElement to size of window
    vars.setStyleWidth(window.innerWidth);
    vars.setStyleHeight(window.innerHeight);
    vars.setStyleTop(0);
    vars.setStyleLeft(0);
    playerElement.style =
      "top: " +
      vars.getStyleTop() +
      "px; left: " +
      vars.getStyleLeft() +
      "px; width: " +
      vars.getStyleWidth() +
      "px; height: " +
      vars.getStyleHeight() +
      "px; cursor: " +
      vars.getStyleCursor() +
      "; " +
      vars.getStyleAdditional();
  } else if (windowAspectRatio < playerAspectRatio) {
    // Window height is the constraining factor so to keep aspect ratio change width appropriately
    vars.setStyleWidth(Math.floor(window.innerHeight / videoAspectRatio));
    vars.setStyleHeight(window.innerHeight);
    vars.setStyleTop(0);
    vars.setStyleLeft(
      Math.floor((window.innerWidth - vars.getStyleWidth()) * 0.5)
    );
    //Video is now 100% of the playerElement, so set the playerElement style
    playerElement.style =
      "top: " +
      vars.getStyleTop() +
      "px; left: " +
      vars.getStyleLeft() +
      "px; width: " +
      vars.getStyleWidth() +
      "px; height: " +
      vars.getStyleHeight() +
      "px; cursor: " +
      vars.getStyleCursor() +
      "; " +
      vars.getStyleAdditional();
  } else {
    // Window width is the constraining factor so to keep aspect ratio change height appropriately
    vars.setStyleWidth(window.innerWidth);
    vars.setStyleHeight(Math.floor(window.innerWidth * videoAspectRatio));
    vars.setStyleTop(
      Math.floor((window.innerHeight - vars.getStyleHeight()) * 0.5)
    );
    vars.setStyleLeft(0);
    //Video is now 100% of the playerElement, so set the playerElement style
    playerElement.style =
      "top: " +
      vars.getStyleTop() +
      "px; left: " +
      vars.getStyleLeft() +
      "px; width: " +
      vars.getStyleWidth() +
      "px; height: " +
      vars.getStyleHeight() +
      "px; cursor: " +
      vars.getStyleCursor() +
      "; " +
      vars.getStyleAdditional();
  }
}

function resizePlayerStyleToActualSize(playerElement) {
  let videoElement = playerElement.getElementsByTagName("VIDEO");

  if (videoElement.length > 0) {
    // Display image in its actual size
    vars.setStyleWidth(videoElement[0].videoWidth);
    vars.setStyleHeight(videoElement[0].videoHeight);
    vars.setStyleTop(
      Math.floor((window.innerHeight - vars.getStyleHeight()) * 0.5)
    );
    vars.setStyleLeft(
      Math.floor((window.innerWidth - vars.getStyleWidth()) * 0.5)
    );
    //Video is now 100% of the playerElement, so set the playerElement style
    playerElement.style =
      "top: " +
      vars.getStyleTop() +
      "px; left: " +
      vars.getStyleLeft() +
      "px; width: " +
      vars.getStyleWidth() +
      "px; height: " +
      vars.getStyleHeight() +
      "px; cursor: " +
      vars.getStyleCursor() +
      "; " +
      vars.getStyleAdditional();
  }
}

function resizePlayerStyleToArbitrarySize(playerElement) {
  // eslint-disable-next-line no-unused-vars
  let videoElement = playerElement.getElementsByTagName("VIDEO");
  //Video is now 100% of the playerElement, so set the playerElement style
  playerElement.style =
    "top: 0px; left: 0px; width: " +
    vars.getStyleWidth() +
    "px; height: " +
    vars.getStyleHeight() +
    "px; cursor: " +
    vars.getStyleCursor() +
    "; " +
    vars.getStyleAdditional();
}

export function setupFreezeFrameOverlay() {
  vars.setFreezeFrameOverlay(document.createElement("img"));
  vars.setFreezeFrameOverlay("freezeFrameOverlay", "id");
  vars.setFreezeFrameOverlay("none", "style.display");
  vars.setFreezeFrameOverlay("none", "style.pointerEvents");
  vars.setFreezeFrameOverlay("absolute", "style.position");
  vars.setFreezeFrameOverlay("30", "style.zIndex");
}

export function showFreezeFrame() {
  let base64 = btoa(
    vars
      .getFreezeFrame()
      .jpeg.reduce((data, byte) => data + String.fromCharCode(byte), "")
  );
  vars.setFreezeFrameOverlay("data:image/jpeg;base64," + base64, "src");
  vars.getFreezeFrameOverlay().onload = function () {
    vars.setFreezeFrame(vars.getFreezeFrameOverlay.naturalHeight, "height");
    vars.setFreezeFrame(vars.getFreezeFrameOverlay.naturalWidth, "width");
    resizeFreezeFrameOverlay();
    if (vars.getShouldShowPlayOverlay()) {
      showPlayOverlay();
      resizePlayerStyle();
    } else {
      showFreezeFrameOverlay();
    }
  };
}

function showFreezeFrameOverlay() {
  if (vars.getFreezeFrame().valid) {
    vars.setFreezeFrameOverlay("block", "style.display");
  }
}

export function invalidateFreezeFrameOverlay() {
  vars.setFreezeFrameOverlay("none", "style.display");
  vars.setFreezeFrame(false, "valid");
}

function resizeFreezeFrameOverlay() {
  if (vars.getFreezeFrame().width !== 0 && vars.getFreezeFrame().height !== 0) {
    let displayWidth = 0;
    let displayHeight = 0;
    let displayTop = 0;
    let displayLeft = 0;
    let checkBox = document.getElementById(
      "enlarge-display-to-fill-window-tgl"
    );
    if (checkBox !== null && checkBox.checked) {
      let windowAspectRatio = window.innerWidth / window.innerHeight;
      let videoAspectRatio =
        vars.getFreezeFrame().width / vars.getFreezeFrame().height;
      if (windowAspectRatio < videoAspectRatio) {
        displayWidth = window.innerWidth;
        displayHeight = Math.floor(window.innerWidth / videoAspectRatio);
        displayTop = Math.floor((window.innerHeight - displayHeight) * 0.5);
        displayLeft = 0;
      } else {
        displayWidth = Math.floor(window.innerHeight * videoAspectRatio);
        displayHeight = window.innerHeight;
        displayTop = 0;
        displayLeft = Math.floor((window.innerWidth - displayWidth) * 0.5);
      }
    } else {
      displayWidth = vars.getFreezeFrame().width;
      displayHeight = vars.getFreezeFrame().height;
      displayTop = 0;
      displayLeft = 0;
    }
    vars.setFreezeFrameOverlay(displayWidth + "px", "style.width");
    vars.setFreezeFrameOverlay(displayHeight + "px", "style.height");
    vars.setFreezeFrameOverlay(displayLeft + "px", "style.left");
    vars.setFreezeFrameOverlay(displayTop + "px", "style.top");
  }
}

export function resizePlayerStyle() {
  var playerElement = document.getElementById("player");

  if (!playerElement) return;

  updateVideoStreamSize();

  if (playerElement.classList.contains("fixed-size")) return;

  let checkBox = document.getElementById("enlarge-display-to-fill-window-tgl");
  let windowSmallerThanPlayer =
    window.innerWidth < playerElement.videoWidth ||
    window.innerHeight < playerElement.videoHeight;
  if (checkBox !== null) {
    if (checkBox.checked || windowSmallerThanPlayer) {
      resizePlayerStyleToFillWindow(playerElement);
    } else {
      resizePlayerStyleToActualSize(playerElement);
    }
  } else {
    resizePlayerStyleToArbitrarySize(playerElement);
  }

  // Calculating and normalizing positions depends on the width and height of
  // the player.
  vars.setPlayerElementClientRect(playerElement.getBoundingClientRect());
  setupNormalizeAndQuantize();
  resizeFreezeFrameOverlay();
}

function updateVideoStreamSize() {
  if (!vars.getMatchViewportResolution()) {
    return;
  }

  var now = new Date().getTime();
  if (now - lastTimeResized > 1000) {
    var playerElement = document.getElementById("player");
    if (!playerElement) return;

    let descriptor = {
      Console:
        "setres " +
        playerElement.clientWidth +
        "x" +
        playerElement.clientHeight,
    };
    emitUIInteraction(descriptor);
    console.log(descriptor);
    lastTimeResized = new Date().getTime();
  } else {
    console.log("Resizing too often - skipping");
    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(updateVideoStreamSize, 1000);
  }
}

// Fix for bug in iOS where windowsize is not correct at instance or orientation change
// https://github.com/dimsemenov/PhotoSwipe/issues/1315
var _orientationChangeTimeout;
export function onOrientationChange() {
  clearTimeout(_orientationChangeTimeout);
  _orientationChangeTimeout = setTimeout(function () {
    resizePlayerStyle();
  }, 500);
}
