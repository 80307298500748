import React from 'react';
import PropTypes from 'prop-types';

import { utentiConnessiHomeConfigurator } from 'axios/REST_api';

import 'styles/info-booking.scss';

const InfoBooking = ({ color }) => {
  const [activeUsers, setActiveUsers] = React.useState(null);

  React.useEffect(() => {
    utentiConnessiHomeConfigurator(process.env.REACT_APP_PROJECT)
      .then((res) => {
        if (res.data) {
          const { data } = res.data;
          setActiveUsers(data.active_users);
          console.log('🚀 ~ file: InfoBooking.jsx ~ line 17 ~ .then ~ data', data);
        }
      })
      .catch((err) => {
        console.error('❌ ~ file: InfoBooking.jsx ~ line 21 ~ React.useEffect ~ err', err);
      });
  }, []);

  return (
    <>
      {activeUsers > 0 ? (
        <div className={`info-booking ${color ? color : 'white'}`}>
          <img
            src={require(`assets/images/icons/alert${color ? '-' + color : '-white'}.svg`)}
            alt={`alert${color ? '-' + color : '-white'}`}
          />
          <span>
            In questo momento{' '}
            {activeUsers === 1 ? "un'altra persona sta" : `altre ${activeUsers} persone stanno`}{' '}
            configurando la propria casa sul sito
          </span>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

InfoBooking.propTypes = {
  color: PropTypes.string,
};

InfoBooking.defaultProps = {
  color: '',
};

export default InfoBooking;
