export const storeCustomWsUri = (customWsUri) => {
  if (customWsUri !== process.env.REACT_APP_WS_BASEURL) {
    window.wsUri = `${customWsUri}`;
    try {
      window.sessionStorage.setItem('customWsUri', `${customWsUri}`);
    } catch (e) {
      console.warn(`Impossibile salvare customWsUri all'interno del session storage:`, e);
    }
  } else {
    window.sessionStorage.removeItem('customWsUri');
  }
};
