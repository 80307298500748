import React from "react";
// import PropTypes from "prop-types";
import { load } from "utils/rtcPlayer/Utils";

class WebRtcPlayer extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    setTimeout(() => window.load(), 100);
  }

  render() {
    return (
      <React.Fragment>
        <div id="player"></div>
        <div
          id="overlay"
          className="text-light bg-dark"
          style={{ display: "none" }}
        >
          <div>
            <div id="qualityStatus" className="greyStatus">
              ⬤
            </div>
            <div id="overlayButton">+</div>
          </div>
          <div id="overlaySettings">
            <div id="KickOthers">
              <div className="settings-text">Kick all other players</div>
              <label className="btn-overlay">
                <input
                  type="button"
                  id="kick-other-players-button"
                  className="overlay-button btn-flat"
                  value="Kick"
                />
              </label>
            </div>

            <div id="QualityControlOwnership">
              <div className="settings-text">Quality control ownership</div>
              <label className="tgl-switch">
                <input
                  type="checkbox"
                  id="quality-control-ownership-tgl"
                  className="tgl tgl-flat"
                />
                <div className="tgl-slider"></div>
              </label>
            </div>
            <div id="PrioritiseQuality">
              <div className="settings-text">Prioritise Quality</div>
              <label className="tgl-switch">
                <input
                  type="checkbox"
                  id="prioritise-quality-tgl"
                  className="tgl tgl-flat"
                  data-toggle="collapse"
                  href="#qualityParamsContainer"
                />
                <div className="tgl-slider"></div>
              </label>
              <div id="qualityParamsContainer" className="collapse">
                <div className="form-group">
                  <label htmlFor="low-bitrate-text">Low Bitrate (kbps)</label>
                  <input
                    type="number"
                    className="form-control"
                    id="low-bitrate-text"
                    value="2000"
                    min="0"
                    max="100000"
                  />
                  <label htmlFor="high-bitrate-text">High Bitrate (kbps)</label>
                  <input
                    type="number"
                    className="form-control"
                    id="high-bitrate-text"
                    value="10000"
                    min="0"
                    max="100000"
                  />
                  <label htmlFor="min-fps-text">Min FPS</label>
                  <input
                    type="number"
                    className="form-control"
                    id="min-fps-text"
                    value="10"
                    min="1"
                    max="120"
                  />
                  <input
                    id="quality-params-submit"
                    className="btn btn-primary btn-lg mt-3"
                    type="button"
                    value="Apply"
                  />
                </div>
              </div>
            </div>
            <div id="ShowFPS">
              <div className="settings-text">Show FPS</div>
              <label className="btn-overlay">
                <input
                  type="button"
                  id="show-fps-button"
                  className="overlay-button btn-flat"
                  value="Toggle"
                />
              </label>
            </div>
            <div id="MatchViewportResolution">
              <div className="settings-text">Match Viewport Resolution</div>
              <label className="tgl-switch">
                <input
                  type="checkbox"
                  id="match-viewport-res-tgl"
                  className="tgl tgl-flat"
                />
                <div className="tgl-slider"></div>
              </label>
            </div>
            <div id="Stats">
              <div className="settings-text">Show Stats</div>
              <label className="tgl-switch">
                <input
                  type="checkbox"
                  id="show-stats-tgl"
                  className="tgl tgl-flat"
                />
                <div className="tgl-slider"></div>
              </label>
              <div id="statsContainer">
                <div id="stats"></div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

WebRtcPlayer.propTypes = {};
WebRtcPlayer.defaultProps = {};

export default WebRtcPlayer;
