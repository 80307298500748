import React, { useEffect, useState } from 'react';
import Loader from 'components/common/Loader';
import VKeyboard from 'components/specific/Keyboard';
import { useStore } from 'store/storeUtils';
import { observer } from 'mobx-react';
import { FULL_KEYBOARD_LAYOUT, FULL_KEYBOARD_DISPLAY } from 'constants/keyboardLayout';
import { Button, ButtonBase, TextField, Typography } from '@material-ui/core';

import { vendorLogin, clientLogin } from 'axios/REST_api';

import 'styles/login.scss';
import { useDeskMediaQuery } from 'utils/DeskMediaQuery';
import { getQueryParam } from 'utils/Url';
import { storeCustomWsUri } from 'utils/SessionStorage';
import { isMyHome } from 'utils/isMyHome';

const Login = observer((props) => {
  const store = useStore();

  const matches = useDeskMediaQuery();

  const [loading, setLoading] = useState(false);
  const [rotation, setRotation] = useState(false);
  const previousInfo = store.vendorInfo ? false : true;
  const [isVendor, setIsVendor] = useState(previousInfo);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState('');
  const [vendorEmail, setVendorEmail] = useState('');
  const [clientEmail, setClientEmail] = useState('');

  const [selectInput, setSelectInput] = useState('email-vendor');

  useEffect(() => {
    const wsParam = getQueryParam('ws');
    if (wsParam) {
      storeCustomWsUri(wsParam);
    }

    if (store.vendorInfo && store.clientInfo) {
      props.history.push('/');
    }
  }, []);

  const getKeyboardInput = (input) => {
    console.log('🚀 ~ file: LoginVendorClient.jsx ~ line 28 ~ getKeyboardInput ~ input', input);
    validateEmail({ target: { value: input } });
    if (isVendor) {
      setVendorEmail(input.trimLeft());
    } else {
      setClientEmail(input.trimLeft());
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (isVendor) {
      vendorLogin(vendorEmail)
        .then((res) => {
          const { data } = res.data;
          console.log('🚀 ~ file: LoginVendorClient.jsx ~ line 45 ~ .then ~ data', data);
          setLoading(false);
          if (data.vendor_id && data.vendor_email) {
            setIsError(false);
            setIsVendor(false);
            store.setVendorInfo(data);
            setSelectInput('email-client');
          } else {
            setIsError(true);
            setError('Email errata');
          }
        })
        .catch((err) => {
          console.error('❌ ~ file: LoginVendorClient.jsx ~ line 50 ~ handleSubmit ~ err', err);
          setIsError(true);
          setError('Email errata');
          setLoading(false);
        });
    } else {
      clientLogin(clientEmail)
        .then((res) => {
          const { data } = res.data;
          console.log('🚀 ~ file: LoginVendorClient.jsx ~ line 56 ~ .then ~ data', data);
          setLoading(false);
          if (data.client_id && data.client_email) {
            setIsError(false);
            store.setClientInfo(data);
            props.history.push(`/`);
          } else {
            setIsError(true);
            setError('Email errata');
          }
        })
        .catch((err) => {
          console.error('❌ ~ file: LoginVendorClient.jsx ~ line 59 ~ handleSubmit ~ err', err);
          setIsError(true);
          setError('Email errata');
          setLoading(false);
        });
    }
  };

  const exitApp = () => {
    const ws = window.sessionStorage.getItem('customWsUri');
    window.location.href = `${process.env.REACT_APP_NEUROSALES_URL}${
      process.env.REACT_APP_NEUROSALES_URL_WS_PARAMS
    }${ws ? ws : process.env.REACT_APP_WS_BASEURL}`;
  };

  const handleExit = () => {
    if (!isVendor) {
      store.removeVendorInfo();
      setIsVendor(true);
    } else {
      exitApp();
    }
  };

  const validateEmail = (e) => {
    const { value } = e.target;
    const emailRegex = RegExp(
      /^([a-z0-9]+(?:[._-][a-z0-9]+)*)@([a-z0-9]+(?:[.-][a-z0-9]+)*\.[a-z]{2,})$/i,
    );
    if (!emailRegex.test(value) && value.length > 0) {
      setIsError(true);
      setError('Si prega di inserire un indirizzo email valido');
      if (isVendor) {
        setVendorEmail(value.trimLeft());
      } else {
        setClientEmail(value.trimLeft());
      }
    } else {
      setIsError(false);
      setError('');
      if (isVendor) {
        setVendorEmail(value.trimLeft());
      } else {
        setClientEmail(value.trimLeft());
      }
    }
  };

  const goToNeurosales = () => {
    window.ws.close();
    // store.removeVendorInfo();
    const ws = window.sessionStorage.getItem('customWsUri');
    window.location.href = `${process.env.REACT_APP_NEUROSALES_URL}${
      process.env.REACT_APP_NEUROSALES_URL_WS_PARAMS
    }${ws ? ws : process.env.REACT_APP_WS_BASEURL}`;
    return null;
  };

  return (
    <>
      <div className='login'>
        <div className='login-wrapper'>
          <div className={`login-wrapper-container ${rotation ? 'rotate' : ''}`}>
            <ButtonBase className='login-wrapper-exit-button' onClick={handleExit}>
              <img src={require('assets/images/icons/exit-left-arrow.svg')} alt='exit-left-arrow' />
              <span>{isVendor ? 'Torna alla home' : 'Indietro'}</span>
            </ButtonBase>
            <ButtonBase
              className='login-wrapper-rotate-button'
              onClick={() => setRotation(!rotation)}
            >
              <img src={require('assets/images/icons/rotate-color.svg')} alt='rotate' />
              <span>Ruota visualizzazione</span>
            </ButtonBase>

            <div className='logo'>
              <img
                src={require('assets/images/twin-towers_logo.svg')}
                alt='logo'
                {...(matches && { onClick: goToNeurosales })}
                style={matches ? { cursor: 'pointer' } : {}}
              />
            </div>

            <div className='login-wrapper-container-form'>
              {isVendor ? (
                <Typography variant='body1' className='login-wrapper-container-form-title'>
                  <span>Login Venditore</span>
                </Typography>
              ) : (
                <Typography variant='body1' className='login-wrapper-container-form-title'>
                  <span>Login Cliente</span>
                </Typography>
              )}
              {loading ? (
                <Loader type='Oval' />
              ) : (
                <>
                  <form
                    method='post'
                    onSubmit={handleSubmit}
                    noValidate
                    className='login-wrapper-container-form'
                  >
                    {isVendor ? (
                      <TextField
                        id='email-vendor'
                        variant='outlined'
                        placeholder='Email'
                        value={vendorEmail}
                        className='login-wrapper-container-form-input'
                        type='email'
                        name='email-vendor'
                        onChange={validateEmail}
                      />
                    ) : (
                      <TextField
                        id='email-client'
                        variant='outlined'
                        placeholder='Email'
                        value={clientEmail}
                        className='login-wrapper-container-form-input'
                        type='email'
                        name='email-client'
                        onChange={validateEmail}
                        //   onBlur={validateEmail}
                      />
                    )}
                    {isError ? (
                      <Typography variant='body1' className='login-wrapper-container-form-error'>
                        <span>{error}</span>
                      </Typography>
                    ) : (
                      <Typography variant='body1' className='login-wrapper-container-form-error'>
                        <span>&nbsp;</span>
                      </Typography>
                    )}
                    <Button
                      variant='contained'
                      className='login-wrapper-container-form-button'
                      type='submit'
                      disabled={isVendor ? !vendorEmail.length > 0 : !clientEmail.length > 0}
                    >
                      Login
                    </Button>
                  </form>
                </>
              )}
            </div>
            <div className='keyboard-container'>
              <VKeyboard
                handleChange={getKeyboardInput}
                input={isVendor ? vendorEmail : clientEmail}
                setInput={isVendor ? setVendorEmail : setClientEmail}
                selectInput={selectInput}
                setSelectInput={setSelectInput}
                selectInputList={isVendor ? ['email-vendor'] : ['email-client']}
                handleSubmit={handleSubmit}
                layout={FULL_KEYBOARD_LAYOUT}
                display={FULL_KEYBOARD_DISPLAY}
                clearInput={() => (isVendor ? setVendorEmail('') : setClientEmail(''))}
              />
            </div>
          </div>
        </div>
      </div>

      {/* <div
        className='container-main home-configurator login'
        style={{
          backgroundImage: `url(${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${encodeURI(
            store.projectDisplayName,
          )}/global/img/login.jpg)`,
        }}
      >
        <div
          className='round-button round-button--corner-tr round-button--rotate'
          onClick={() => setRotation(!rotation)}
          style={{
            backgroundImage: `url('${process.env.REACT_APP_BUCKET_BASEURL}/FloorPlanning/img/icon/ico-rotate.svg')`,
          }}
        ></div>
        <div
          className={`login__wrapper ${rotation ? 'rotate' : ''}`}
          style={rotation ? { transform: 'rotate(180deg)' } : {}}
        >
          <div
            className='primary-logo'
            style={{
              backgroundImage: `url(${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${encodeURI(
                store.projectDisplayName,
              )}/global/img/primary-logo.svg)`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          />
          <h1>Eseguire il login per accedere</h1>
          <div className='login-form__wrapper'>
            {loading ? (
              <Loader type='Oval' />
            ) : (
              <>
                {isVendor ? (
                  <Typography variant='body1' className='login-form-title'>
                    <span>Login Venditore</span>
                  </Typography>
                ) : (
                  <Typography variant='body1' className='login-form-title'>
                    <span>Login Cliente</span>
                  </Typography>
                )}
                <form method='post' onSubmit={handleSubmit} noValidate className='login-form'>
                  {isVendor ? (
                    <TextField
                      id='email-vendor'
                      placeholder='Email'
                      value={vendorEmail}
                      // className='login-input email'
                      type='email'
                      name='email-vendor'
                      fullWidth
                      onChange={validateEmail}
                      //   onBlur={validateEmail}
                    />
                  ) : (
                    <TextField
                      id='email-client'
                      placeholder='Email'
                      value={clientEmail}
                      // className='login-input email'
                      type='email'
                      name='email-client'
                      fullWidth
                      onChange={validateEmail}
                      //   onBlur={validateEmail}
                    />
                  )}
                  {isError ? (
                    <Typography variant='body1' className='login-form-error'>
                      <span>{error}</span>
                    </Typography>
                  ) : (
                    <Typography variant='body1' className='login-form-error'>
                      <span>&nbsp;</span>
                    </Typography>
                  )}
                  <Button
                    variant='contained'
                    color='primary'
                    className='login-form-button'
                    type='submit'
                    disabled={
                      isError || (isVendor ? !vendorEmail.length > 0 : !clientEmail.length > 0)
                    }
                  >
                    Login
                  </Button>
                </form>
              </>
            )}
          </div>
          <div className='keyboard-container'>
            <VKeyboard
              handleChange={getKeyboardInput}
              input={isVendor ? vendorEmail : clientEmail}
              setInput={isVendor ? setVendorEmail : setClientEmail}
              selectInput={selectInput}
              setSelectInput={setSelectInput}
              selectInputList={['email-vendor', 'email-client']}
              handleSubmit={handleSubmit}
              layout={FULL_KEYBOARD_LAYOUT}
              display={FULL_KEYBOARD_DISPLAY}
              clearInput={() => (isVendor ? setVendorEmail('') : setClientEmail(''))}
            />
          </div>
        </div>
      </div> */}
    </>
  );
});

export default Login;
