import React from 'react';
import { useStore } from 'store/storeUtils';
import { observer } from 'mobx-react';

import moment from 'moment';

import {
  ButtonBase,
  ClickAwayListener,
  Drawer,
  Grid,
  Popper,
  Snackbar,
  useMediaQuery,
} from '@material-ui/core';

import DrawerCommands from 'components/common/DrawerCommands';
import DrawerHeader from 'components/common/DrawerHeader';
import PromoBanner from 'components/common/PromoBanner';
import PlanimetryDialog from 'components/common/PlanimetryDialog';
import AppointmentDialog from 'components/specific/AppointmentDialog';
import RenameSummaryDialog from 'components/specific/RenameSummaryDialog';

import getSessionId from 'utils/getSessionId';
import { getPromoName } from 'utils/PromoUtils';
import { isMyHome, credentialsInParams } from 'utils/isMyHome';

import { riepilogo, updatePreventivo } from 'axios/REST_api';

import CurrencyFormatter from 'utils/CurrencyFormatter';
import { pdf } from '@react-pdf/renderer';
import SummaryPdf from 'components/specific/SummaryPdf.jsx';

import 'styles/summary.scss';
import CrmLoader from 'components/generic/CrmLoader';

/**
 * Riepilogo
 * Contiene il riepilogo dell'appartamento scelto
 * @component
 */
const Summary = observer(
  ({ summary, proposals, openSummary, setOpenSummary, correctBuilding, planimetryRanking }) => {
    const store = useStore();

    const matches = useMediaQuery('(max-width:959px)');

    const [apartmentData, setApartmentData] = React.useState(null);

    React.useEffect(() => {
      if (summary?.data) {
        setApartmentData(summary.data);
      }
    }, [summary]);

    const [openModal, setOpenModal] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const toggleDrawer = () => {
      setTimeout(() => setButtonClicked({ cart: false, wish: false }), 700);
      setOpenSummary(!openSummary);
    };

    const getFormattedSurface = (surface) => (+surface).toFixed(0);
    const getFormattedPrice = (price) => CurrencyFormatter(price);

    const [open, setOpen] = React.useState(false);
    const floorSelect = React.useRef(null);
    const id = open ? 'transitions-popper' : undefined;
    const onClickAway = () => setOpen(false);
    const handleClick = () => {
      setOpen(!open);
    };
    function handleOnListClick(item) {
      changeApartmentFloor(item);
      setOpen(!open);
    }

    const [openExitApp, setOpenExitApp] = React.useState(false);
    const popperRef = React.useRef(null);
    const handleClickExit = () => setOpenExitApp(!openExitApp);

    const AptFloors = () => {
      const floors = store.selectedAptFloors[apartmentData.info_apartment.Name];
      return (
        <>
          {floors
            .slice()
            .sort()
            .map((floor, i) => (
              <li className='popper-list-item' key={i}>
                <button type='button' onClick={() => handleOnListClick(floor)}>
                  Piano &nbsp; <span style={{ fontWeight: 700 }}>{floor}</span>
                </button>
              </li>
            ))}
        </>
      );
    };

    const changeApartmentFloor = (floor) => {
      if (store.isDesk || isMyHome()) {
        setLoading(true);
      }
      const { modello, tipologia, dimensione } = store.filtersInfo;
      const {
        // AIn_Edificio: edificio,
        Name: planimetria,
        AIn_Quadrante: quadrante,
        AIn_Versione: versione,
      } = apartmentData.info_apartment;

      try {
        riepilogo(
          process.env.REACT_APP_PROJECT,
          modello.Code,
          tipologia.Code,
          dimensione.Code,
          planimetria,
          correctBuilding, // edificio,
          floor,
          quadrante,
          versione,
          store.isDesk === 0 ? (isMyHome() ? 1 : 0) : 1,
          store.isDesk === 0 ? (isMyHome() ? null : getSessionId()) : null,
          store.isDesk === 1
            ? store.vendorInfo.vendor_id
            : isMyHome()
            ? credentialsInParams().vendorId
            : null,
          store.isDesk === 1
            ? store.clientInfo.client_id
            : isMyHome()
            ? credentialsInParams().clientId
            : null,
        )
          .then((res) => {
            const { data } = res.data;
            if (data) {
              setApartmentData(data);
            }
          })
          .catch((err) => {
            console.error('❌ ~ file: Summary.jsx ~ line 111 ~ riepilogo ~ err', err);
          })
          .finally(() => setLoading(false));
      } catch (error) {
        console.error('❌ ~ file: Summary.jsx ~ line 114 ~ riepilogo try-catch ~ err', error);
        setLoading(false);
      }
    };

    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogType, setDialogType] = React.useState(null);
    const openAppointmentDialog = (appointmentType) => {
      setOpenDialog(!openDialog);
      setDialogType(appointmentType);
    };

    const savePdf = async (reactPdfComponent, fileName) => {
      const asPdf = pdf([]);
      asPdf.updateContainer(reactPdfComponent);
      const blob = await asPdf.toBlob();
      const fileURL = URL.createObjectURL(blob);
      const popupPdf = window.open(fileURL, 'popup', 'width=600,height=600');
      setTimeout(() => {
        popupPdf.document.title = fileName;
      }, 1000);
    };

    const [buttonClicked, setButtonClicked] = React.useState({ cart: false, wish: false });
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [textSnackbar, setTextSnackbar] = React.useState('');
    const [openRenameSummaryDialog, setOpenRenameSummaryDialog] = React.useState(false);
    const [action, setAction] = React.useState(null);
    const handleCloseSnackbar = () => setOpenSnackbar(!openSnackbar);
    const onButtonClicked = (action) => {
      setAction(action);
      setOpenRenameSummaryDialog(!openRenameSummaryDialog);
    };
    const saveOrBuy = (newName) => {
      setLoading(true);
      updatePreventivo(
        process.env.REACT_APP_PROJECT,
        apartmentData.preventivo.preventivo_id,
        action,
        store.isDesk === 1 ? store.isDesk : isMyHome() ? 1 : 0,
        newName,
      )
        .then((res) => {
          if (res.data) {
            const { message } = res.data;
            if (message.toLowerCase().includes('ok')) {
              setButtonClicked({ ...buttonClicked, [action]: true });
              setTextSnackbar(
                `Appartamento aggiunto ${action === 'cart' ? 'al carrello!' : 'alla wishlist!'}`,
              );
              setOpenSnackbar(!openSnackbar);
            }
            console.log('🚀 ~ file: Summary.jsx ~ line 129 ~ updatePreventivo ~ message', message);
          }
        })
        .catch((err) => {
          console.error('❌ ~ file: Summary.jsx ~ line 133 ~ updatePreventivo ~ err', err);
        })
        .finally(() => setLoading(false));
    };

    const rotateImage = () => {
      const planimetryContainer = document.querySelector('.apartment-planimetry-summary');
      const planimetry = document.querySelector('.apartment-planimetry-summary-image');

      const mq_medium = window.matchMedia('(min-width: 960px) and (max-width: 1024px)');
      const mq_small = window.matchMedia('(max-width: 959px)');

      if (planimetry) {
        planimetry.style.width = 'auto';
        planimetry.style.height = 'auto';
        if (planimetry.clientWidth < planimetry.clientHeight) {
          planimetry.style.transform = 'rotate(90deg)';
          if (mq_medium.matches) {
            planimetry.style.height = `${planimetryContainer.clientHeight}px`;
          }
          if (mq_small.matches) {
            planimetry.style.height = `${planimetryContainer.clientHeight}px`;
          }
        } else {
          planimetry.style.height = `${planimetryContainer.clientHeight}px`;
        }
      }
    };

    const [promoDate, setPromoDate] = React.useState(null);
    const getPromoDate = (date) => {
      if (date) {
        const d = moment(date.split('/').reverse().join('-')).format('DD.MM.YYYY');
        setPromoDate(d);
      }
    };

    const checkPromoDate = () => {
      const now = moment();
      const pd = moment(promoDate?.split('.').reverse().join('-'));
      return now.isBefore(pd) && now.month() >= 8;
    };

    return (
      <Drawer
        className='summary'
        anchor={'right'}
        transitionDuration={{ enter: 700, exit: 500 }}
        open={openSummary}
        onClose={toggleDrawer}
        style={store.isRotate ? { transform: 'rotate(180deg)' } : {}}
      >
        {apartmentData ? (
          <>
            <DrawerCommands
              toggleDrawer={toggleDrawer}
              handleClickExit={handleClickExit}
              popperRef={popperRef}
              openExitApp={openExitApp}
              setOpenExitApp={setOpenExitApp}
            />
            <CrmLoader z loading={loading} hasBackdrop transparency />
            <div className='promo'>
              <PromoBanner getPromoDate={getPromoDate} />
            </div>
            <div className='fixed-spacer'>
              <DrawerHeader />
              <div className='content-container'>
                <div className='title-promo-container'>
                  <div className='title-promo-container-ce'>
                    Stai visualizzando{' '}
                    <span className='title-promo-container-spacing'>
                      <br />
                    </span>{' '}
                    <span className='title-promo-container-apartment'>
                      {apartmentData.info_apartment.Name}
                    </span>
                  </div>
                </div>
                <div className='info-container'>
                  <Grid container className='apartment-container'>
                    <Grid item xs={4} className='apartment'>
                      <div className='apartment-title'>
                        <img
                          className='apartment-title-icon'
                          src={require('assets/images/icons/double-right-arrow.svg')}
                          alt='double right arrow'
                        />
                        <span className='apartment-title-name'>
                          {apartmentData.info_apartment.Name}
                        </span>
                      </div>
                      <div className='apartment-planimetry-summary'>
                        <img
                          className='apartment-planimetry-summary-icon'
                          src={require('assets/images/icons/enlarge.svg')}
                          alt='enlarge'
                          onClick={() => setOpenModal(true)}
                        />
                        <img
                          src={`https://planimetrieprogetti.abitareinspa.com/${process.env.REACT_APP_PROJECT.toLowerCase()}/th/${apartmentData.info_apartment.Name.toLowerCase().replaceAll(
                            ' ',
                            '_',
                          )}.jpg`}
                          alt='apartment-planimetry'
                          className='apartment-planimetry-summary-image'
                          onClick={() => setOpenModal(true)}
                          onLoad={rotateImage}
                        />
                      </div>

                      <PlanimetryDialog
                        openModal={openModal}
                        setOpenModal={setOpenModal}
                        apartmentData={apartmentData}
                      />

                      {planimetryRanking(apartmentData.info_apartment) > 0 ? (
                        <div className='apartment-ranking'>
                          <span className='apartment-ranking-ribbon'>
                            <img
                              className='apartment-ranking-ribbon-icon'
                              src={require('assets/images/icons/ribbon.svg')}
                              alt='ribbon'
                            />
                            <span className='apartment-ranking-ribbon-message'>
                              {matches ? (
                                <strong>
                                  Top {planimetryRanking(apartmentData.info_apartment)}!
                                </strong>
                              ) : (
                                <>
                                  Questo appartamento è nella{' '}
                                  <strong>
                                    Top {planimetryRanking(apartmentData.info_apartment)}
                                  </strong>{' '}
                                  dei più richiesti!
                                </>
                              )}
                            </span>
                            {/* <span className='apartment-ranking-ribbon-top'>
                              <strong>
                                Top {planimetryRanking(apartmentData.info_apartment)}!
                              </strong>
                            </span>
                            <span className='apartment-ranking-ribbon-separator'>|</span>
                            <span className='apartment-ranking-ribbon-choice'>
                              <strong>Tra i più scelti</strong> del progetto
                            </span> */}
                          </span>
                        </div>
                      ) : (
                        ''
                      )}
                      <div className='apartment-specs'>
                        <div className='apartment-specs-building'>
                          <img src={require('assets/images/icons/building.svg')} alt='edificio' />
                          <span>
                            Edificio <strong>{apartmentData.info_apartment.AIn_Edificio}</strong>
                          </span>
                        </div>
                        <div className='apartment-specs-view'>
                          <img src={require('assets/images/icons/view.svg')} alt='vista' />
                          <span>
                            Vista{' '}
                            <strong>
                              {apartmentData.info_apartment.AIn_Edificio === 'Ambra'
                                ? 'Cittá'
                                : 'Parco'}
                            </strong>
                          </span>
                        </div>
                        <div className='apartment-specs-total-surface'>
                          <img
                            src={require('assets/images/icons/planimetry.svg')}
                            alt='superficie netta'
                          />
                          <span>
                            Superficie totale di{' '}
                            <strong>
                              {getFormattedSurface(
                                apartmentData.preventivo.Superficie_Lorda_Totale.value,
                              )}{' '}
                              mq
                            </strong>
                          </span>
                        </div>
                        <div className='apartment-specs-lodge-surface'>
                          <img
                            src={require('assets/images/icons/lodge.svg')}
                            alt='superficie loggia'
                          />
                          <span>
                            di cui Loggia{' '}
                            <strong>
                              {getFormattedSurface(
                                apartmentData.info_apartment.AIn_SuperficieTerrazzo,
                              )}{' '}
                              mq
                            </strong>
                          </span>
                        </div>
                        <div className='apartment-specs-floor'>
                          <img src={require('assets/images/icons/floor.svg')} alt='piano' />
                          <div className='apartment-specs-floor-name'>
                            <span>
                              Piano <strong>{apartmentData.info_apartment.AIn_Piano}</strong>
                            </span>
                            <span
                              className='apartment-specs-floor-name-change'
                              onClick={handleClick}
                              ref={floorSelect}
                            >
                              cambia piano
                              <img
                                src={require('assets/images/icons/down-arrow.svg')}
                                alt='chevron'
                                className={`apartment-specs-floor-name-change-icon ${
                                  open ? 'rotate-arrow' : ''
                                }`}
                              />
                            </span>
                          </div>
                          <Popper
                            id={id}
                            open={open}
                            anchorEl={floorSelect.current}
                            placement='top-end'
                            transition
                            className='change-floor-popper'
                          >
                            <ClickAwayListener onClickAway={onClickAway}>
                              <div
                                className='popper-view'
                                style={store.isRotate ? { transform: 'rotate(180deg)' } : {}}
                              >
                                <ul className='popper-list'>
                                  <AptFloors />
                                  {/* {store.selectedAptFloors.map((floor, i) => (
                                  <li className='popper-list-item' key={i}>
                                    <button type='button' onClick={() => handleOnListClick(floor)}>
                                      Piano &nbsp; <span style={{ fontWeight: 700 }}>{floor}</span>
                                    </button>
                                  </li>
                                ))} */}
                                </ul>
                              </div>
                            </ClickAwayListener>
                          </Popper>
                        </div>
                        <div className='apartment-specs-info-warnings'>
                          <div
                            className={`apartment-specs-info-warnings-proposal ${
                              +proposals < 1 ? 'zero-proposal' : ''
                            }`}
                          >
                            <img src={require('assets/images/icons/alert.svg')} alt='alert' />
                            <span>
                              <strong>
                                {+proposals === 1
                                  ? '1 altra persona sta'
                                  : `Altre ${proposals} persone stanno`}{' '}
                                valutando
                              </strong>{' '}
                              l’acquisto di questo appartamento
                            </span>
                          </div>
                        </div>
                      </div>
                    </Grid>

                    <Grid container xs={8} className='apartment-summary-container'>
                      <Grid item xs={7} className='apartment-summary-container-price-info'>
                        <div className='apartment-summary-container-price-info-container'>
                          <div className='apartment-summary-container-price-info-container-value'>
                            <span>Prezzo appartamento</span>
                            <span className='apartment-summary-container-price-info-container-value-cost'>
                              {getFormattedPrice(
                                apartmentData.preventivo.ValoreUnitaImmobiliare.value,
                              )}
                            </span>
                          </div>
                          <div className='apartment-summary-container-price-info-container-separator'></div>
                          <div className='apartment-summary-container-price-info-container-promo'>
                            <span>{getPromoName()}</span>
                            <span className='apartment-summary-container-price-info-container-promo-value'>
                              -{getFormattedPrice(apartmentData.preventivo.specialeevento.value)}
                            </span>
                          </div>
                          {checkPromoDate() ? (
                            <div className='apartment-summary-container-price-info-container-promo-de'>
                              <img src={require('assets/images/icons/alert.svg')} alt='alert' />
                              <span>
                                Sconto d’eccezione per le{' '}
                                <strong>proposte fatte entro il {promoDate}</strong>
                              </span>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                        <div>
                          <div className='apartment-summary-container-price-info-value'>
                            <span>
                              Il <strong>tuo appartmento</strong> a
                            </span>
                            <span>
                              {getFormattedPrice(apartmentData.preventivo.Prezzo_Promo.value)}
                            </span>
                          </div>
                          <div className='apartment-summary-container-price-info-cantina'>
                            <span>Cantina inclusa</span>
                          </div>
                          <div className='apartment-summary-container-price-info-installment'>
                            <span>La tua rata</span>
                            <span>
                              <strong>
                                {getFormattedPrice(apartmentData.preventivo.Rata_Variabile.value)}
                              </strong>
                              /mese*
                            </span>
                          </div>
                        </div>
                        <div className='apartment-summary-container-price-info-promo'>
                          <img
                            src={require('assets/images/icons/promo-key-heart.svg')}
                            alt='promo-key-heart'
                          />
                          <div className='apartment-summary-container-price-info-promo-description'>
                            <span className='apartment-summary-container-price-info-promo-description-title'>
                              Promozione per te
                            </span>
                            <span>La tua casa straordinaria</span>
                            <span className='apartment-summary-container-price-info-promo-description-price'>
                              {getFormattedPrice(
                                apartmentData.preventivo.Buono_La_tua_Casa_Straordinaria.value,
                              )}
                            </span>
                            <span>di buoni arredi inclusi**</span>
                          </div>
                        </div>
                      </Grid>

                      <Grid item xs={5} className='apartment-summary-container-appointment'>
                        {store.isDesk === 0 ? (
                          <>
                            <div className='apartment-summary-container-appointment-skip-queue'>
                              <span className='apartment-summary-container-appointment-skip-queue-title'>
                                <img
                                  src={require('assets/images/icons/double-right-arrow.svg')}
                                  alt='double-right-arrow'
                                />
                                <span>{isMyHome() ? 'La tua casa!' : 'Salta la coda!'}</span>
                              </span>
                              <span className='apartment-summary-container-appointment-skip-queue-title-spacing'>
                                {isMyHome() ? (
                                  <>
                                    Procedi all&apos;<strong>acquisto</strong> o salva la tua casa
                                    ideale nella Lista dei <strong>Desideri</strong>!
                                  </>
                                ) : (
                                  <>
                                    e <strong>prenota subito</strong> il tuo appuntamento!
                                  </>
                                )}
                              </span>
                            </div>
                            <div className='apartment-summary-container-appointment-booking'>
                              {isMyHome() ? (
                                ''
                              ) : (
                                <span>
                                  Scegli la modalità, il giorno e l’orario che preferisci:
                                </span>
                              )}
                              <div className='apartment-summary-container-appointment-booking-buttons'>
                                {isMyHome() ? (
                                  <>
                                    <ButtonBase
                                      className='apartment-summary-container-appointment-booking-buttons-wishlist'
                                      disabled={buttonClicked['wish']}
                                      onClick={() => onButtonClicked('wish')}
                                    >
                                      <img
                                        src={require(`assets/images/icons/${
                                          buttonClicked['wish'] ? 'heart-disabled' : 'heart'
                                        }.svg`)}
                                        alt='heart'
                                      />
                                      <span>Aggiungi alla Wishlist</span>
                                    </ButtonBase>
                                    <div className='apartment-summary-container-appointment-booking-buttons-separator'></div>
                                    <ButtonBase
                                      className='apartment-summary-container-appointment-booking-buttons-cart'
                                      disabled={buttonClicked['cart']}
                                      onClick={() => onButtonClicked('cart')}
                                    >
                                      <img
                                        src={require(`assets/images/icons/${
                                          buttonClicked['cart'] ? 'cart-disabled' : 'cart'
                                        }.svg`)}
                                        alt='cart'
                                      />
                                      <span>Aggiungi al Carrello</span>
                                    </ButtonBase>
                                  </>
                                ) : (
                                  <>
                                    <ButtonBase
                                      className='apartment-summary-container-appointment-booking-buttons-videocall'
                                      onClick={() => openAppointmentDialog('videocall')}
                                    >
                                      <img
                                        src={require('assets/images/icons/videocall.svg')}
                                        alt='videocall'
                                      />
                                      <span>Videocall</span>
                                    </ButtonBase>
                                    <div className='apartment-summary-container-appointment-booking-buttons-separator'></div>
                                    <ButtonBase
                                      className='apartment-summary-container-appointment-booking-buttons-showroom'
                                      onClick={() => openAppointmentDialog('showroom')}
                                    >
                                      <img
                                        src={require('assets/images/icons/showroom.svg')}
                                        alt='showroom'
                                      />
                                      <span>Showroom</span>
                                    </ButtonBase>
                                  </>
                                )}
                                <Snackbar
                                  anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }}
                                  open={openSnackbar}
                                  autoHideDuration={3000}
                                  onClose={handleCloseSnackbar}
                                  message={textSnackbar}
                                  className='cart-wish-snackbar'
                                />

                                <AppointmentDialog
                                  openDialog={openDialog}
                                  setOpenDialog={setOpenDialog}
                                  dialogType={dialogType}
                                  dataToSend={{
                                    'external-id': apartmentData.info_apartment.ExternalId,
                                    vani: apartmentData.info_apartment.AIn_VaniTipologici,
                                    'session-id': getSessionId(),
                                  }}
                                />

                                <RenameSummaryDialog
                                  openDialog={openRenameSummaryDialog}
                                  setOpenDialog={setOpenRenameSummaryDialog}
                                  execAction={saveOrBuy}
                                />
                              </div>
                              {isMyHome() ? (
                                <ButtonBase
                                  className='apartment-summary-container-appointment-booking-myai-back'
                                  onClick={() =>
                                    (window.location.href = process.env.REACT_APP_MYAI_URL)
                                  }
                                >
                                  <img
                                    src={require(`assets/images/icons/back-arrow-light.svg`)}
                                    alt='back-arrow'
                                  />
                                  <span>Torna a myAi</span>
                                </ButtonBase>
                              ) : (
                                ''
                              )}
                            </div>
                          </>
                        ) : (
                          <div className='apartment-summary-container-appointment-pdf'>
                            <div className='apartment-summary-container-appointment-pdf-title'>
                              <img
                                src={require('assets/images/icons/double-right-arrow.svg')}
                                alt='double-right-arrow'
                              />
                              <span>Salva la tua promo!</span>
                            </div>
                            <ButtonBase
                              className='apartment-summary-container-appointment-pdf-button'
                              variant='contained'
                              onClick={() =>
                                savePdf(
                                  <SummaryPdf
                                    data={apartmentData}
                                    defaultData={store.floorPlanningConfig.defaultSummaryPdf}
                                  />,
                                  `Preventivo ${apartmentData.info_apartment.Name}.pdf`,
                                )
                              }
                            >
                              <img src={require('assets/images/icons/pdf.svg')} alt='pdf' />
                              <span>Scarica PDF</span>
                            </ButtonBase>
                          </div>
                        )}
                        <div className='apartment-summary-container-appointment-image'></div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <div className='notes'>
                    <span>
                      * Ipotesi di calcolo per l’acquisto di una unità immobiliare con anticipo pari
                      al 30% del corrispettivo lordo di compravendita; esempio di rata di rimborso
                      determinato alla data del 31/05/2021 - relativamente ad un mutuo contratto
                      presso qualsivoglia Istituto di Credito a tasso variabile indicizzato della
                      durata di 30 anni, TAN 1,2%, TAEG 1,5% - e potrà variare in ragione
                      dell’andamento del tasso di riferimento nei mesi a venire, il tutto salvo
                      approvazione dell’istituto bancario erogante.
                    </span>
                    <span>
                      ** Gli incentivi consistono in un buono utilizzabile per l’acquisto di
                      prodotti dei nostri partner. Termini e condizioni visionabili presso il nostro
                      showroom.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ''
        )}
      </Drawer>
    );
  },
);

export default Summary;
