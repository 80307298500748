import React from 'react';
import PropTypes from 'prop-types';
import { useStore } from 'store/storeUtils';

import { ButtonBase } from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { isMyHome } from 'utils/isMyHome';

const ExitPopper = ({ popperRef, openExitApp, setOpenExitApp }) => {
  const store = useStore();

  const id = openExitApp ? 'transitions-popper' : undefined;
  const onClickAway = () => setOpenExitApp(false);

  const exitApp = () => {
    if (store.isDesk === 1) {
      window.ws.close();
      // store.removeVendorInfo();
      const ws = window.sessionStorage.getItem('customWsUri');
      window.location.href = `${process.env.REACT_APP_NEUROSALES_URL}${
        process.env.REACT_APP_NEUROSALES_URL_WS_PARAMS
      }${ws ? ws : process.env.REACT_APP_WS_BASEURL}`;
    } else if (isMyHome()) {
      window.location.href = process.env.REACT_APP_MYAI_URL;
    } else {
      window.location.href = 'https://lambratetwinpalace.com/';
    }
  };

  return (
    <Popper
      id={id}
      open={openExitApp}
      anchorEl={popperRef.current}
      placement='bottom-end'
      transition
      popperOptions={{
        modifiers: {
          offset: {
            enabled: true,
            offset: '0px, 16px',
          },
        },
      }}
      className='exit-popper'
    >
      <ClickAwayListener onClickAway={onClickAway}>
        <div className={`exit-popper-container ${store.isRotate ? 'rotate-180' : ''}`}>
          <span className='exit-popper-container-message'>
            Stai per abbandonare la personalizzazione del tuo appartamento ideale
          </span>
          <span className='exit-popper-container-warning'>
            Così facendo perderai quanto selezionato finora.
          </span>
          <div className='exit-popper-container-commands'>
            <ButtonBase
              className='exit-popper-container-commands-undo'
              onClick={() => setOpenExitApp(false)}
            >
              Annulla
            </ButtonBase>
            <ButtonBase className='exit-popper-container-commands-confirm' onClick={exitApp}>
              Sì, voglio uscire
            </ButtonBase>
          </div>
        </div>
      </ClickAwayListener>
    </Popper>
  );
};

ExitPopper.propTypes = {
  popperRef: PropTypes.any,
  openExitApp: PropTypes.bool,
  setOpenExitApp: PropTypes.func,
};

ExitPopper.defaultProps = {
  popperRef: null,
  openExitApp: false,
};

export default ExitPopper;
