import React from 'react';
import CrmLoader from 'components/generic/CrmLoader';

import FiltersContainer from 'components/specific/FiltersContainer';
import PlanimetrySelection from 'components/specific/PlanimetrySelection';
// import DayNightToggle from 'components/specific/DayNightToggle';
import DeskUpperBand from 'components/specific/DeskUpperBand';
import DeskLowerBand from 'components/specific/DeskLowerBand';
import ExitPopper from 'components/common/ExitPopper';
import Keyplan from 'components/specific/Keyplan';

import { useStore } from 'store/storeUtils';
import { observer } from 'mobx-react';
import WebRtcPlayer from 'components/specific/WebRtcPlayer';

import { ButtonBase } from '@material-ui/core';

import 'styles/home.scss';
import { useDeskMediaQuery } from 'utils/DeskMediaQuery';
import { getQueryParam } from 'utils/Url';
import { storeCustomWsUri } from 'utils/SessionStorage';

/**
 * Home page
 * Contiene filtri appartamenti
 * @component
 */
const Home = observer(() => {
  const store = useStore();

  const matches = useDeskMediaQuery();

  const [isConnected, setConnection] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);

  const [openDrawer, setOpenDrawer] = React.useState(false);

  const [views, setViews] = React.useState([]);
  const [slot, setSlot] = React.useState([]);
  const [availableApartments, setAvailableApartments] = React.useState([]);

  const [openExitApp, setOpenExitApp] = React.useState(false);
  const popperRef = React.useRef(null);
  const handleClick = () => setOpenExitApp(!openExitApp);
  let loadingLevels = false;

  const resetLongAfkTimeout = () => {
    window.isSelectedApartment = true;
    window.resetAfkWarningTimer(window.afk.longWarnTimeout);
  };

  const resetNormalAfkTimeout = () => {
    window.isSelectedApartment = false;
    window.resetAfkWarningTimer(window.afk.longWarnTimeout);
  };

  const resetUI = () => {
    try {
      window.emitUIInteraction({
        Console: 'PixelStreaming.Encoder.MinQP 2',
      });
      window.emitUIInteraction({
        Console: 'PixelStreaming.Encoder.PrioritizeQuality 0',
      });
      window.emitUIInteraction({
        Console: 'PixelStreaming.Encoder.RateControl ConstQP',
      });
      // window.emitUIInteraction({ lightingScenario: 'Day' });
      window.emitUIInteraction({ apartment_Create: '' });
      window.emitUIInteraction('focus_Names');
      window.emitUIInteraction({ focus: process.env.REACT_APP_DEFAULT_VIEW });
      store.setCurrentView(process.env.REACT_APP_DEFAULT_VIEW);
    } catch (e) {
      console.log(e);
    }
  };

  const hideWaitingScreen = () => {
    const waitingScreen = document.getElementById('waiting-screen-container');
    if (waitingScreen) waitingScreen.style.display = 'none';
  };

  const showReconnectionScreen = () => {
    const waitingScreen = document.getElementById('waiting-screen-container');
    if (waitingScreen) {
      const loadingDescription = document.getElementById(
        'waiting-screen-container-loading-description',
      );
      const reconnectionDescription = document.getElementById(
        'waiting-screen-container-loading-reconnection-description',
      );
      loadingDescription.style.display = 'none';
      reconnectionDescription.style.display = 'flex';
      waitingScreen.style.display = 'flex';
    }
  };

  const getDefaultWsUri = () =>
    `${process.env.REACT_APP_WS_BASEURL}?clientId=${Math.floor(Math.random() * 1000000 + 1)}`;

  React.useEffect(() => {
    /* INITIALIZE WS & STREAMING */
    document.addEventListener(
      'connection_built',
      () => {
        resetUI();
        setConnection(true);
        setTimeout(() => setLoading(false), 1000);
      },
      false,
    );

    document.addEventListener('connection_creating', () => {
      setLoading(true);
    });

    document.addEventListener('connection_error', () => {
      const customWs = window.sessionStorage.getItem('customWsUri');
      if (customWs && window.wsUri === `${customWs}`) {
        setLoading(true);
        showReconnectionScreen();
        window.wsUri = getDefaultWsUri();
      }
    });

    document.addEventListener(
      'connection_destroyed',
      () => {
        const customWs = window.sessionStorage.getItem('customWsUri');

        setConnection(false);
        setLoading(false);

        if (store.isDesk && customWs && window.wsUri === `${customWs}`) {
          console.log('Impossibile stabilire una connessione con la macchina unreal locale');
          setLoading(true);
          showReconnectionScreen();
          window.wsUri = getDefaultWsUri();
        }
      },
      false,
    );

    window.addResponseEventListener('handle_responses', (response) => {
      const parsedResponse = JSON.parse(response);
      if (!parsedResponse.compass) {
        console.log('received Message: ' + response);
      }

      let startingScreen,
        waitingScreen,
        loadingDescription = null;

      if (parsedResponse['focus_Names']) {
        setViews(parsedResponse.focus_Names.split(','));
        if (!loadingLevels) {
          hideWaitingScreen();
        }
      }

      if (parsedResponse['loadLevels_Start']) {
        loadingLevels = true;
        startingScreen = document.getElementById('starting-screen-container');
        waitingScreen = document.getElementById('waiting-screen-container');
        if (startingScreen) {
          startingScreen.style.display = 'none';
          if (waitingScreen) {
            waitingScreen.style.display = 'flex';
            loadingDescription = document.getElementById(
              'waiting-screen-container-loading-description',
            );
            const reconnectionDescription = document.getElementById(
              'waiting-screen-container-loading-reconnection-description',
            );
            loadingDescription.style.display = 'none';
            reconnectionDescription.style.display = 'none';
          }
        }
      }

      if (parsedResponse['loadLevels_End']) {
        loadingLevels = false;
        waitingScreen = document.getElementById('waiting-screen-container');
        if (waitingScreen) {
          waitingScreen.style.display = 'none';
        }
      }

      if (parsedResponse['apartment']) {
        resetLongAfkTimeout();
        const selectedSlot = parsedResponse.apartment;
        const slot = selectedSlot.split('_');
        const piano = slot[0].replace('P', '');
        const quadrante = slot[1];

        const avApts = [...availableApartments];
        console.log(
          '🚀 ~ file: Home.jsx ~ line 103 ~ window.addResponseEventListener ~ avApts',
          avApts,
        );

        const selectedApt = avApts.filter(
          (apt) =>
            apt.AIn_Piano === piano &&
            apt.AIn_Quadrante === quadrante.replaceAll('+', ' + ') &&
            (store.isDesk == 1 ? apt.enabled_desk : apt.enabled_web) == 1,
        );
        setSlot(selectedApt);
        console.log(
          '🚀 ~ file: Home.jsx ~ line 118 ~ window.addResponseEventListener ~ selectedApt',
          selectedApt,
        );

        const planimetryFloors = {};
        for (const apt of selectedApt) {
          planimetryFloors[apt.Name] = avApts
            .filter(
              (avApt) =>
                avApt.Name === apt.Name && avApt.AIn_Quadrante === quadrante.replaceAll('+', ' + '),
            )
            .map((fApt) => fApt.AIn_Piano);
        }
        store.setSelectedAptFloors(planimetryFloors);

        setTimeout(() => setOpenDrawer(true), 3000);
      } else if (parsedResponse['apartment'] === '') {
        resetNormalAfkTimeout();
      }
    });

    try {
      const wsParam = getQueryParam('ws');

      if (wsParam) {
        storeCustomWsUri(wsParam);
      }

      const customWsUri = window.sessionStorage.getItem('customWsUri');
      if (customWsUri) {
        window.wsUri = customWsUri;
      } else if (window.wsUri || window.wsUri === '') {
        window.wsUri = getDefaultWsUri();
      }
    } catch (e) {
      if (window.wsUri || window.wsUri === '') {
        window.wsUri = getDefaultWsUri();
      }
    }
  }, [availableApartments]);

  const [reservedApts, setReservedApts] = React.useState(null);
  const setReservedApartments = (resApt) => setReservedApts(resApt);

  return (
    <>
      <div className='home'>
        {isLoading && <CrmLoader z loading transparency hasBackdrop />}
        {isConnected ? (
          <div className={!matches ? 'home-content' : 'home-content-desk'}>
            {!matches && (
              <>
                {store.isDesk === 1 ? (
                  <ButtonBase
                    className='home-content-rotate-button'
                    onClick={() => store.setRotation()}
                  >
                    <img src={require('assets/images/icons/rotate-color.svg')} alt='rotate' />
                    <span>Ruota visualizzazione</span>
                  </ButtonBase>
                ) : (
                  ''
                )}
                <ButtonBase
                  className='home-content-exit-button'
                  onClick={handleClick}
                  ref={popperRef}
                >
                  <img
                    src={require('assets/images/icons/exit-left-arrow.svg')}
                    alt='exit-left-arrow'
                  />
                  <span>Torna alla home</span>
                </ButtonBase>
                <ExitPopper
                  popperRef={popperRef}
                  openExitApp={openExitApp}
                  setOpenExitApp={setOpenExitApp}
                />
                <Keyplan />
              </>
            )}
            <FiltersContainer
              views={views}
              setAvailableApartments={setAvailableApartments}
              setReservedApartments={setReservedApartments}
            />
            {/* {store.isDesk === 1 ? <DayNightToggle /> : ''} */}
            {matches && (
              <>
                <DeskUpperBand views={views} />
                <DeskLowerBand />
                <Keyplan />
              </>
            )}
          </div>
        ) : (
          ''
        )}
        {slot.length > 0 ? (
          <PlanimetrySelection
            slot={slot}
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            reservedApts={reservedApts}
          />
        ) : (
          ''
        )}
        <WebRtcPlayer />
      </div>
    </>
  );
});

export default Home;
