import React from 'react';
import PropTypes from 'prop-types';
import GenericSuspense from 'components/generic/GenericSuspense';

import { observer } from 'mobx-react';
import { useStore } from 'store/storeUtils';

/**
 * Template generico della pagina
 * Contiene un Header, il contenuto della pagina circondato da un suspense
 * @component
 */
const PageTemplate = observer((props) => {
  const store = useStore();
  const { BodyComponent, title, ...rest } = props;

  return (
    <div className='main-container' style={store.isRotate ? { transform: 'rotate(180deg)' } : {}}>
      <div className='body-wrapper'>
        <GenericSuspense>
          <BodyComponent title={title} {...rest} />
        </GenericSuspense>
      </div>
    </div>
  );
});

PageTemplate.propTypes = {
  breadcrumb: PropTypes.string,
  /** BodyComponent: required content */
  BodyComponent: PropTypes.elementType.isRequired,
  /** title: string. CrmHeader prop */
  title: PropTypes.string,
  /** goBack: boolean. CrmHeader prop */
  goBack: PropTypes.bool,
};

PageTemplate.defaultProps = {
  title: '',
  goBack: false,
};

export default PageTemplate;
