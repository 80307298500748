import React from "react";
import PropTypes from "prop-types";

/**
 * react suspense wrapper with default fallback
 * @component
 */
const GenericSuspense = ({ children }) => {
  return (
    <React.Suspense fallback={<div>Loading...</div>}>{children}</React.Suspense>
  );
};

/** prop types */
GenericSuspense.propTypes = {
  /** children: required */
  children: PropTypes.node.isRequired,
};

export default GenericSuspense;
