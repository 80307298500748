import React from "react";
import { Route } from "react-router-dom";
import PageTemplate from "pages/PageTemplate";
import PropTypes from "prop-types";
import SnackbarFeedback from "components/generic/SnackbarFeedback";
import SnackbarError from "components/generic/SnackbarError";
import { observer } from "mobx-react";

const PrivateRoute = observer((props) => {
  const { exact, path, ToRender, title, goBack, breadcrumb } = props;
  return (
    <>
      <SnackbarFeedback />
      <SnackbarError />
      <Route
        exact={exact}
        path={path}
        render={(props) => (
          <PageTemplate
            BodyComponent={ToRender}
            title={title}
            goBack={goBack}
            breadcrumb={breadcrumb}
            {...props}
          />
        )}
      />
    </>
  );
});

PrivateRoute.propTypes = {
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
  ToRender: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  goBack: PropTypes.bool,
  breadcrumb: PropTypes.string,
};

PrivateRoute.defaultProps = {
  exact: false,
  goBack: false,
};

export default PrivateRoute;
