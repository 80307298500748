import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";
// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: "#f3f3f3", // '#61dafb',
      light: "#61dafb",
      dark: "#52575D",
    },
    secondary: {
      main: "#b5ecfb",
      light: "#61dafb",
      dark: "#21a1c4",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff", // '#282c34',
    },
    progress: {
      main: "#000",
    },
  },
  overrides: {
    MuiPaper: {
      root: {
        padding: "20px 10px",
        margin: "10px",
        backgroundColor: "#fff", // 5d737e
      },
    },
    MuiButton: {
      root: {
        margin: "5px",
      },
      containedPrimary: {
        color: "#fff",
        backgroundColor: "#000",
        "&:hover": {
          backgroundColor: "#000",
        },
      },
    },
    MuiMenuItem: {
      root: {
        minHeight: "auto",
      },
    },
    MuiSlider: {
      colorPrimary: {
        color: "#123cab",
      },
      colorSecondary: {
        color: "#abc456",
      },
      valueLabel: {
        color: "#123cab",
        backgroundColor: "#123cab",
        padding: "0 12px",
        marginLeft: "-12px",
      },
    },
  },
});
export default theme;
