import React from 'react';
import PropTypes from 'prop-types';

import ExitPopper from 'components/common/ExitPopper';

import ButtonBase from '@material-ui/core/ButtonBase';

import 'styles/drawer-commands.scss';

const DrawerCommands = ({
  toggleDrawer,
  handleClickExit,
  popperRef,
  openExitApp,
  setOpenExitApp,
}) => {
  return (
    <div className='commands-container'>
      <ButtonBase className='commands-container-back-button' onClick={toggleDrawer}>
        <img src={require('assets/images/icons/back-arrow.svg')} alt='back' />
        <span>Indietro</span>
      </ButtonBase>
      <div>
        {/* <ButtonBase className='commands-container-rotate-button'>
            <img src={require('assets/images/icons/rotate.svg')} alt='rotate' />
          </ButtonBase> */}
        <ButtonBase
          className='commands-container-logout-button'
          onClick={handleClickExit}
          ref={popperRef}
        >
          <img src={require('assets/images/icons/exit-left-arrow.svg')} alt='exit-left-arrow' />
          <span>Torna alla home</span>
        </ButtonBase>
        <ExitPopper
          popperRef={popperRef}
          openExitApp={openExitApp}
          setOpenExitApp={setOpenExitApp}
        />
      </div>
    </div>
  );
};

DrawerCommands.propTypes = {
  toggleDrawer: PropTypes.func,
  handleClickExit: PropTypes.func,
  popperRef: PropTypes.any,
  openExitApp: PropTypes.bool,
  setOpenExitApp: PropTypes.func,
};

DrawerCommands.defaultProps = {
  toggleDrawer: false,
  popperRef: null,
  openExitApp: false,
};

export default DrawerCommands;
