import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useStore } from 'store/storeUtils';

import FiltersDrawer from 'components/specific/FiltersDrawer';
import FiltersMenu from 'components/specific/FiltersMenu';

import { useDeskMediaQuery } from 'utils/DeskMediaQuery.jsx';

import {
  getModello,
  getDimensione,
  getTipologia,
  getQuadrantiOccupati,
  getSoluzionePianoAppartamenti,
  quadrantiAppartamentiVenduti,
} from 'axios/REST_api';

import 'styles/filter-container.scss';

const FiltersContainer = observer(({ views, setAvailableApartments, setReservedApartments }) => {
  const store = useStore();

  const matches = useDeskMediaQuery();

  const [close, setClose] = React.useState(false);
  const toggleMenu = () => {
    setClose(!close);
  };

  const [filters, setFilters] = React.useState({});
  const setFilter = (id, filter) => {
    const currentFilters = { ...filters };
    const currentData = { ...dataList };
    if (filter) {
      currentFilters[id] = filter;
    }
    if (id === 'modello') {
      delete currentFilters.tipologia;
      delete currentFilters.dimensione;
      delete currentData.tipologia;
      delete currentData.dimensione;

      store.resetAptLegend();
      window.emitUIInteraction({ apartment_Create: '' });
    }
    if (id === 'tipologia') {
      delete currentFilters.dimensione;
      delete currentData.dimensione;

      store.resetAptLegend();
      window.emitUIInteraction({ apartment_Create: '' });
    }

    setFilters(currentFilters);
    setDataList(currentData);
  };

  const resetFilters = () => {
    const currentFilters = { ...filters };
    if (currentFilters.modello) {
      delete currentFilters.modello;
      delete currentFilters.tipologia;
      delete currentFilters.dimensione;
      setFilters(currentFilters);

      const currentData = { ...dataList };
      delete currentData.modello;
      delete currentData.tipologia;
      delete currentData.dimensione;
      setDataList(currentData);

      store.resetAptLegend();
      window.emitUIInteraction({ apartment_Create: '' });
    }
  };

  const isInitialMountTypology = React.useRef(true);
  const isInitialMountDimension = React.useRef(true);
  const [dataList, setDataList] = React.useState({});

  const [quadrantiOccupati, setQuadrantiOccupati] = React.useState([]);
  const [appartamenti, setAppartamenti] = React.useState([]);
  const [appartamentiVenduti, setAppartamentiVenduti] = React.useState([]);

  React.useEffect(() => {
    store.resetAptLegend();

    getModello(process.env.REACT_APP_PROJECT, store.isDesk)
      .then((res) => {
        const { data } = res.data;
        setDataList({ modello: data });
        setFilters({ modello: data[0] });
      })
      .catch((err) => {
        console.error('❌ ~ file: FiltersContainer.jsx ~ line 78 ~ modello ~ err', err);
      });
  }, []);

  React.useEffect(() => {
    if (isInitialMountTypology.current) {
      isInitialMountTypology.current = false;
    } else {
      if (filters.modello) {
        getTipologia(process.env.REACT_APP_PROJECT, filters.modello.Code, store.isDesk)
          .then((res) => {
            const { data } = res.data;
            setDataList((prevData) => ({ ...prevData, tipologia: data }));
          })
          .catch((err) => {
            console.error('❌ ~ file: FiltersContainer.jsx ~ line 98 ~ tipologia ~ err', err);
          });
      } else {
        getModello(process.env.REACT_APP_PROJECT, store.isDesk)
          .then((res) => {
            const { data } = res.data;
            setDataList({ modello: data });
            setFilters({ modello: data[0] });
          })
          .catch((err) => {
            console.error('❌ ~ file: FiltersContainer.jsx ~ line 78 ~ modello ~ err', err);
          });
      }
    }
  }, [filters.modello]);

  React.useEffect(() => {
    if (isInitialMountDimension.current) {
      isInitialMountDimension.current = false;
    } else {
      if (filters.tipologia) {
        getDimensione(
          process.env.REACT_APP_PROJECT,
          filters.modello.Code,
          filters.tipologia.Code,
          store.isDesk,
        )
          .then((res) => {
            const { data } = res.data;
            setDataList((prevData) => ({ ...prevData, dimensione: data }));
          })
          .catch((err) => {
            console.error('❌ ~ file: FiltersContainer.jsx ~ line 115 ~ dimesione ~ err', err);
          });
      }
    }
  }, [filters.tipologia]);

  React.useEffect(() => {
    if (filters.modello && filters.tipologia && filters.dimensione) {
      getQuadrantiOccupati(process.env.REACT_APP_PROJECT)
        .then((res) => {
          const { data } = res.data;
          setQuadrantiOccupati(data);
        })
        .catch((err) => {
          console.error(
            '❌ ~ file: FiltersContainer.jsx ~ line 134 ~ getQuadrantiOccupati ~ err',
            err,
          );
          setQuadrantiOccupati([]);
        });

      getSoluzionePianoAppartamenti(
        process.env.REACT_APP_PROJECT,
        filters.modello.Code,
        filters.tipologia.Code,
        filters.dimensione.Code,
      )
        .then((res) => {
          const { data } = res.data;
          setAppartamenti(data);
        })
        .catch((err) => {
          console.error(
            '❌ ~ file: FiltersContainer.jsx ~ line 156 ~ getSoluzionePianoAppartamenti ~ err',
            err,
          );
          setAppartamenti([]);
        });

      quadrantiAppartamentiVenduti(process.env.REACT_APP_PROJECT)
        .then((res) => {
          const { data } = res.data;
          setAppartamentiVenduti(data);
        })
        .catch((err) => {
          console.error(
            '❌ ~ file: FiltersContainer.jsx ~ line 194~ quadrantiAppartamentiVenduti ~ err',
            err,
          );
          setAppartamentiVenduti([]);
        });

      setTimeout(() => {
        toggleMenu();
      }, 1500);
    }
    store.setFiltersInfo(filters);
  }, [filters]);

  React.useEffect(() => {
    if (appartamenti.length > 0) {
      const quadrantiVisibili = appartamenti.reduce((acc, curr) => {
        acc[`${curr['AIn_Quadrante']}_${curr['AIn_Piano']}__${curr['AIn_Edificio']}`] =
          acc[`${curr['AIn_Quadrante']}_${curr['AIn_Piano']}__${curr['AIn_Edificio']}`] ||
          (store.isDesk == 1 ? curr.enabled_desk : curr.enabled_web) == 1;
        return acc;
      }, {});

      const result = appartamenti.filter(
        ({
          AIn_Piano: pianoApp,
          AIn_Edificio: edificioApp,
          AIn_Quadrante: quadranteApp,
          quadrante_occupied: quadranteOccupiedApp,
        }) =>
          quadrantiVisibili[`${quadranteApp}_${pianoApp}__${edificioApp}`] &&
          (!quadranteOccupiedApp ||
            quadrantiOccupati.some(
              ({
                AIn_Piano: pianoQuad,
                AIn_Edificio: edificioQuad,
                AIn_Quadrante: quadranteQuad,
              }) =>
                pianoApp === pianoQuad &&
                edificioApp === edificioQuad &&
                quadranteApp === quadranteQuad,
            )),
      );
      setAvailableApartments(result);

      const proposte = result.filter((res) => !!res.quadrante_occupied);
      setReservedApartments(proposte);

      let disponibili = 0,
        opzionati = 0,
        venduti = 0,
        altriOpzionati = 0;
      let stringResult = result.map((res) => {
        res.quadrante_occupied ? opzionati++ : disponibili++;
        return `P${res.AIn_Piano}_${res.AIn_Quadrante.replaceAll(' ', '')}|${
          res.quadrante_occupied ? 'R' : 'A'
        }`;
      });

      const stringSold = appartamentiVenduti.map((av) => {
        venduti++;
        return `P${av.AIn_Piano}_${av.AIn_Quadrante.replaceAll(' ', '')}|S`;
      });

      const stringAltriOpzionati = quadrantiOccupati
        .filter(
          ({ AIn_Piano: pianoApp, AIn_Edificio: edificioApp, AIn_Quadrante: quadranteApp }) =>
            !result.some(
              ({
                AIn_Piano: pianoQuad,
                AIn_Edificio: edificioQuad,
                AIn_Quadrante: quadranteQuad,
              }) =>
                pianoApp === pianoQuad &&
                edificioApp === edificioQuad &&
                quadranteApp === quadranteQuad,
            ),
        )
        .map((res) => {
          altriOpzionati++;
          return `P${res.AIn_Piano}_${res.AIn_Quadrante.replaceAll(' ', '')}|O`;
        });

      stringResult = [...stringResult, ...stringSold, ...stringAltriOpzionati];

      let apartments = [...new Set(stringResult)];
      apartments = apartments.join(',');
      window.emitUIInteraction({ apartment_Create: apartments });
      console.log(
        '🚀 ~ file: FiltersContainer.jsx ~ line 176 ~ React.useEffect ~ stringa_da_inviare',
        apartments,
      );

      store.setAptLegend({
        isVisible: true,
        isVisibleElem: {
          disponibili: disponibili > 0,
          opzionati: opzionati > 0,
          venduti: venduti > 0,
          altriOpzionati: altriOpzionati > 0,
        },
      });
    } else {
      setAvailableApartments([]);
      store.resetAptLegend();
      window.emitUIInteraction({ apartment_Create: '' });
    }
    if (store.currentView.includes('E01')) {
      const genericView = 'E01_Vista01';
      window.emitUIInteraction({ focus: genericView });
      store.setCurrentView(genericView);
    }
    if (store.currentView.includes('E02')) {
      const genericView = 'E02_vista01';
      window.emitUIInteraction({ focus: genericView });
      store.setCurrentView(genericView);
    }
  }, [appartamenti, quadrantiOccupati]);

  const backToLogin = (step) => {
    if (step === 'vendor') store.removeVendorInfo();
    if (step === 'client') store.removeClientInfo();
    window.ws.close();
    window.location.href = '/login';
    return null;
  };

  return (
    <>
      {!matches ? (
        <FiltersDrawer
          close={close}
          toggleMenu={toggleMenu}
          backToLogin={backToLogin}
          resetFilters={resetFilters}
          dataList={dataList}
          filters={filters}
          setFilter={setFilter}
          views={views}
        />
      ) : (
        <FiltersMenu
          backToLogin={backToLogin}
          resetFilters={resetFilters}
          dataList={dataList}
          filters={filters}
          setFilter={setFilter}
          views={views}
        />
      )}
    </>
  );
});

FiltersContainer.propTypes = {
  views: PropTypes.array,
  setOpenDrawer: PropTypes.func,
  setReservedApartments: PropTypes.func,
};

FiltersContainer.defaultProps = {
  views: [],
};

export default FiltersContainer;
