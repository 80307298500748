import { gql } from '@apollo/client';
/// import { pollInterval } from "gqlclient/ClientConstants";

const PROJECT_DATA_TO_RETRIEVE = `
  id
  name
  logo
  vendorLogo
  assetLogin
  hostKey
  assetKey
  feVendorKey
  pdf
  assetsUrl
  displayName
  pageTitles {
    followup
    tuning
  }
  enabledTools
  accountManagerEnabled
  floorPlanningConfig
`;

const GET_PROJECT_INFO = gql`
  query getProjectInfoByHost ($host :String!) {
    getProjectInfoByHost (host :$host) {
      ${PROJECT_DATA_TO_RETRIEVE}
    }
  }`;

const GET_PROJECT_INFO_DEFAULT_OPTIONS = (hostname) => {
  return {
    variables: {
      host: hostname,
    },
  };
};

const LOGIN = gql`
  mutation loginTecma($input: LoginByProjectInput!) {
    loginByProject(input: $input) {
      token {
        accessToken
        refreshToken
        tokenType
        expiresIn
      }
      user {
        id
        firstName
        lastName
        createdOn
        email
      }
    }
  }
`;

const LOGIN_DEFAULT_OPTIONS = (email, password, project_id) => {
  return {
    variables: {
      email: email,
      password: password,
      project_id: project_id,
    },
  };
};

const APPARTMENT_DATA_TO_RETRIEVE = `
  id
  name
  price
  status
  floor
  available
  enabled_web
  enabled_desk
  code
  selectedBy {
    client {
      id
      firstName
      lastName
      email
      avatar
    }
    status
  }
  interestedBy {
    client {
      id
      firstName
      lastName
      email
    }
    status
  }
  updatedOn
  plan {
    id
    name
    SuperficieAppartamento
    SuperficieTotale
    SuperficieGiardino
    SuperficieTerrazzo
    typology {
      id
      name
    }
  }
  building {
    id
    name
  }
  sides {
    id
    name
    pass
    staircase
    building {
      id
      name
    }
  }
`;

const GET_ALL_APPARTMENTS = gql`
  query getAllAppartments ($project_id: ID!) {
    getAllAppartments (project_id: $project_id) {
      ${APPARTMENT_DATA_TO_RETRIEVE}
    }
  }`;

const GET_ALL_APPARTMENTS_DEFAULT_OPTIONS = (project_id) => {
  return {
    variables: {
      project_id,
    },
  };
};

/* // esample with policies 
const GET_ALL_APPARTMENTS_DEFAULT_OPTIONS = (pId) => {
  return {
    variables: {
      project_id: pId,
    },
    pollInterval,
    fetchPolicy: "cache-and-network",
  };
};
*/

const Client = {
  GET_PROJECT_INFO,
  GET_PROJECT_INFO_DEFAULT_OPTIONS,
  LOGIN,
  LOGIN_DEFAULT_OPTIONS,
  GET_ALL_APPARTMENTS,
  GET_ALL_APPARTMENTS_DEFAULT_OPTIONS,
};

export default Client;
